import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { Grid, Divider } from "@material-ui/core";

import "../ClientEmployerDivision/ClientEmployerDivisionTab/AddEditClientEmployerDivisionDialog.web.css";
import ClientEditDialogController, {
  Props
} from "./ClientEditDialogController.web";
import { defaultMaterialTheme } from "../../../dashboard/src/EditEmployerDetailsDialog.web";
import GenericTextField from "../../../../components/src/GenericTextField.web";
import GenericDateField from "../../../../components/src/GenericDateField.web";
import GenericErrorText from "../../../../components/src/GenericErrorText.web";
import GenericDialogFormWrapper from "../../../../components/src/GenericDialogFormWrapper.web";
import GenericAddressComponent from "../../../../components/src/GenericeAddressFields.web";
import GenericCheckBoxField from "../../../../components/src/GenericCheckBoxField.web";
import GenericCloseDialog from "../../../../components/src/GenericCloseDialog.web";
import GenericSuccessDialog from "../../../../components/src/GenericSuccessDialog.web";

const configJSON = require("../config");

export default class ClientEditDialog extends ClientEditDialogController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { editActiveStep, editClientStepList, isUpdateSuccess } = this.state;
    
    const {
      clientBasicDetails,
      clientAddress
    } = this.state.clientDetail;
    const divisionDialogContent = (
      <>
        {editActiveStep === 0 && (
          <Grid container spacing={3} data-test-id="step-1-wrapper">
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="client_name"
                label={configJSON.client_name}
                value={clientBasicDetails.client_name}
                data-test-id="client_name"
                isRequired={true}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="tax_id"
                label={configJSON.taxIDLabel}
                value={clientBasicDetails.tax_id}
                isTaxId
                data-test-id="tax_id"
                isRequired={true}
                inputProps={{
                  maxLength: 10
                }}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label={configJSON.effective_date}
                value={clientBasicDetails.effective_date}
                data-test-id="effective_date"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label={configJSON.termination_date}
                value={clientBasicDetails.termination_date}
                data-test-id="termination_date"
                disabled={true}
              />
            </Grid>
          </Grid>
        )}
        {editActiveStep === 1 && (
          <Grid container spacing={3} data-test-id="step-2-wrapper">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="employer-division-from-header"
            >
              {configJSON.physicalAddress}
            </Grid>
            <GenericAddressComponent
              type="physical"
              addressValues={clientAddress}
              handleAddressChange={this.handleAddressChange}
              countryList={this.state.countryList}
              stateList={this.state.statePhysicalList}
              isValidationDivision={true}
              disabledState={clientAddress.physical_country_id == ""}
            />

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="emp-edit-address-header"
            >
              <div className="div-mailing-address-flex-wrapper">
                <div className="employer-division-from-header">
                  {configJSON.mailingAddress}
                </div>
                <div>
                  <GenericCheckBoxField
                    checked={this.state.isMailingAddressSameAsPhysicalAddress}
                    label="Mailing address same as physical address"
                    onChange={(event: React.ChangeEvent<any>) =>
                      this.handleCheckBox(event)
                    }
                    data-test-id="is_mailing_address_same_as_physical_address"
                  />
                </div>
              </div>
            </Grid>
            <GenericAddressComponent
              type="mailing"
              addressValues={clientAddress}
              handleAddressChange={this.handleAddressChange}
              countryList={this.state.countryList}
              stateList={this.state.stateMailingList}
              disabled={!!this.state.isMailingAddressSameAsPhysicalAddress}
              isValidationDivision={true}
              disabledState={clientAddress.mailing_country_id == ""}
            />

            <div className="dividerWrapper">
              <Divider />
            </div>

            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="client_phone1"
                label={"Phone 1"}
                value={clientAddress.client_phone1}
                onChange={(e) => this.handlContactChange(e, "client_phone1")}
                data-test-id="client_phone1"
                onlyNumber={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>

              <GenericTextField
                name="client_phone2"
                label={"Phone 2"}
                value={clientAddress.client_phone2}
                onChange={(e) => this.handlContactChange(e, "client_phone2")}
                data-test-id="client_phone2"
                onlyNumber={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="client_fax1"
                label={"Fax 1"}
                value={clientAddress.client_fax1}
                onChange={(e) => this.handlContactChange(e, "client_fax1")}
                data-test-id="client_fax1"
                onlyNumber={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="client_fax2"
                label={"Fax 2"}
                value={clientAddress.client_fax2}
                onChange={(e) => this.handlContactChange(e, "client_fax2")}
                data-test-id="client_fax2"
                onlyNumber={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="client_contact1"
                label={"Contact Person 1"}
                value={clientAddress.client_contact1}
                onChange={(e) => this.handlContactChange(e, "client_contact1")}
                data-test-id="client_contact1"
                onlyLetter={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="client_contact2"
                label={"Contact Person 2"}
                value={clientAddress.client_contact2}
                onChange={(e) => this.handlContactChange(e, "client_contact2")}
                data-test-id="client_contact2"
                onlyLetter={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="client_email1"
                label={"Email 1"}
                value={clientAddress.client_email1}
                onChange={(e) => this.handlContactChange(e, "client_email1")}
                data-test-id="client_email1"
                error={this.getErrorMessage('client_email1')}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="client_email2"
                label={"Email 2"}
                value={clientAddress.client_email2}
                onChange={(e) => this.handlContactChange(e, "client_email2")}
                data-test-id="client_email2"
                error={this.getErrorMessage('client_email2')}
              />
            </Grid>
          </Grid >
        )}
        {editActiveStep === 2 && (
          <Grid container spacing={3} data-test-id="step-3-wrapper" />
        )}
        <Grid item xs={12}>
          <GenericErrorText error={this.state.generalError} />
        </Grid>
        {isUpdateSuccess && (
          <GenericSuccessDialog
            data-test-id="success-dialog"
            open={isUpdateSuccess}
            handleDone={this.handleSuccessDialogClose}
            contains={"Update Client Successfully"}
          />
        )}
      </>
    )

    return (
      <ThemeProvider theme={defaultMaterialTheme}>
        <GenericDialogFormWrapper
          open={this.props.isOpen}
          title={"Edit Client"}
          handleClose={this.handleDivisionDialogClose}
          stepList={editClientStepList}
          activeStep={editActiveStep}
          saveAndNext={this.saveAndNext}
          saveAndFinish={this.saveAndFinish}
          formContent={divisionDialogContent}
          disableOnClick={!editClientStepList[0].isCompleted}
          disableSaveAndNext={editActiveStep === 0}
          setActiveStep={this.setActiveStep}
          handlePrevious={() => { this.handleSteps(false) }}
          buttonTitle={"Save Changes"}
        />
        <GenericCloseDialog
          open={this.state.isOpenWarningDialog}
          handleClose={() => this.handleCloseWarningDialog(false)}
          handleOk={() => this.handleCloseWarningDialog(true)}
        />
      </ThemeProvider >
    );
  }
}
