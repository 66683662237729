import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { Grid, Divider } from "@material-ui/core";
import AddEditClientEmployerClassDialogController, {
  Props,
  configJSON
} from "./AddEditClientEmployerClassDialogController";
import "./ClientEmpClass.css";
import GenericCloseDialog from "../../../../components/src/GenericCloseDialog.web";
import { defaultMaterialTheme } from "../../../dashboard/src/EditEmployerDetailsDialog.web";
import GenericDialogFormWrapper from "../../../../components/src/GenericDialogFormWrapper.web";
import GenericTextField from "../../../../components/src/GenericTextField.web";
import GenericDateField from "../../../../components/src/GenericDateField.web";
import GenericSelectField from "../../../../components/src/GenericSelectField.web";
import GenericErrorText from "../../../../components/src/GenericErrorText.web";
import GenericAddressComponent from "../../../../components/src/GenericeAddressFields.web";
import GenericCheckBoxField from "../../../../components/src/GenericCheckBoxField.web";
import GenericAddAgentComponent from "../../../../components/src/GenericAddAgentComponent.web";
import GenericSuccessDialog from "../../../../components/src/GenericSuccessDialog.web";


export default class AddEditClientEmployerClassDialog extends AddEditClientEmployerClassDialogController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      classActiveStep,
      addEditClassStepList,
      mailingStateList,
      countryList,
      physicalStateList,
      mailSameAsPhysical,
      generalError
    } = this.state;
    const { classBasicDetails, classAddressDetails, classOtherDetails } = this.state.classData;
    const { physical_country_id, mailing_country_id } = classAddressDetails

    const classFormContent = (
      <>
        {classActiveStep === 0 && (
          <Grid container spacing={3} data-test-id="step-1-wrapper">
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="class_name"
                label={configJSON.classNameclassLable}
                value={classBasicDetails.class_name}
                onChange={e =>
                  this.handleClassInputItemChange(e, "class_name")
                }
                error={this.getClassErrorMessage("class_name")}
                data-test-id="class_name"
                isRequired={true}
                onlyLetter
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="class_name"
                label={configJSON.classEmployeridLable}
                value={classBasicDetails.class_id}
                onChange={e =>
                  this.handleClassInputItemChange(e, "class_id")
                }
                error={this.getClassErrorMessage("class_id")}
                data-test-id="class_id"
                isRequired={true}
                onlyLetterAndNumber
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.client_name}
                value={classBasicDetails.client_name}
                error={this.getClassErrorMessage("client_name")}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.employer_name}
                value={classBasicDetails.employer_name}
                error={this.getClassErrorMessage("employer_name")}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.employer_id}
                value={classBasicDetails.employer_id}
                error={this.getClassErrorMessage("employer_id")}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label={configJSON.group_name}
                selectionList={this.state.groupList}
                name="group_name"
                onChange={(e) => this.handleClassInputItemChange(e, "group_id")}
                value={classBasicDetails.group_id}
                error={this.getClassErrorMessage("group_id")}
                data-test-id="group_id"
                isRequired={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label={configJSON.division_name}
                selectionList={this.state.divisionList}
                name="division_name"
                onChange={(e) => this.handleClassInputItemChange(e, "division_id")}
                value={classBasicDetails.division_id}
                error={this.getClassErrorMessage("division_id")}
                data-test-id="division_id"
                isRequired={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label={configJSON.location_name}
                selectionList={this.state.locationList}
                name="location_name"
                onChange={(e) => this.handleClassInputItemChange(e, "location_id")}
                value={classBasicDetails.location_id}
                error={this.getClassErrorMessage("location_id")}
                data-test-id="location_id"
                isRequired={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label={configJSON.begin_date}
                value={classBasicDetails.class_effective_date}
                onChange={date =>
                  this.handleClassInputItemChange(date, "class_effective_date")
                }
                error={this.getClassErrorMessage("class_effective_date")}
                data-test-id="class_effective_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label={configJSON.termination_date}
                value={classBasicDetails.class_termination_date}
                onChange={date =>
                  this.handleClassInputItemChange(
                    date,
                    "class_termination_date"
                  )
                }
                error={this.getClassErrorMessage("class_termination_date")}
                data-test-id="class_termination_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label={configJSON.deductible_date}
                value={classBasicDetails.deductible_date}
                onChange={(e) =>
                  this.handleClassInputItemChange(e, "deductible_date")
                }
                error={this.getClassErrorMessage("deductible_date")}
                data-test-id="deductible_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label={configJSON.firstPayrollDayLabel}
                selectionList={configJSON.payrollDayList}
                value={classBasicDetails.payroll_day}
                onChange={(e) =>
                  this.handleClassInputItemChange(e, "payroll_day")
                }
                error={this.getClassErrorMessage("payroll_day")}
                data-test-id="payroll_day"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label={configJSON.firstPayrollMonthLabel}
                selectionList={configJSON.payrollMonthList}
                value={classBasicDetails.payroll_month}
                onChange={(e) =>
                  this.handleClassInputItemChange(e, "payroll_month")
                }
                error={this.getClassErrorMessage("payroll_month")}
                data-test-id="payroll_month"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label={configJSON.payrollCycle1Label}
                selectionList={configJSON.payrollCycleList}
                value={classBasicDetails.first_payroll_cycle}
                onChange={(e) =>
                  this.handleClassInputItemChange(e, "first_payroll_cycle")
                }
                error={this.getClassErrorMessage("first_payroll_cycle")}
                data-test-id="first_payroll_cycle"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label={configJSON.payrollCycle2Label}
                selectionList={configJSON.payrollCycleList}
                value={classBasicDetails.second_payroll_cycle}
                onChange={(e) =>
                  this.handleClassInputItemChange(e, "second_payroll_cycle")
                }
                error={this.getClassErrorMessage("second_payroll_cycle")}
                data-test-id="second_payroll_cycle"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label={configJSON.payrollCycle3Label}
                selectionList={configJSON.payrollCycleList}
                value={classBasicDetails.third_payroll_cycle}
                onChange={(e) =>
                  this.handleClassInputItemChange(e, "third_payroll_cycle")
                }
                error={this.getClassErrorMessage("third_payroll_cycle")}
                data-test-id="third_payroll_cycle"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label={configJSON.payrollCycle4Label}
                selectionList={configJSON.payrollCycleList}
                value={classBasicDetails.fourth_payroll_cycle}
                onChange={(e) =>
                  this.handleClassInputItemChange(e, "fourth_payroll_cycle")
                }
                error={this.getClassErrorMessage("fourth_payroll_cycle")}
                data-test-id="fourth_payroll_cycle"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="tax_id"
                label={configJSON.taxIDLabel}
                value={classBasicDetails.tax_id}
                onChange={e => this.handleClassInputItemChange(e, "tax_id")}
                error={this.getClassErrorMessage("tax_id")}
                isTaxId
                data-test-id="tax_id"
                isRequired={true}
                inputProps={{
                  maxLength: 10
                }}
              />
            </Grid>
          </Grid>
        )}
        {classActiveStep === 1 && (
          <Grid container spacing={3} data-test-id="step-2-wrapper">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="employer-location-from-header"
            >
              {configJSON.physicalAddress}
            </Grid>
            <GenericAddressComponent
              countryError={this.getClassErrorMessage('physical_country')}
              stateError={this.getClassErrorMessage('physical_state')}
              type="physical"
              addressValues={classAddressDetails}
              handleAddressChange={this.classAddressChange}
              countryList={countryList}
              stateList={physicalStateList}
              isValidationDivision={true}
              disabledState={physical_country_id == ""}
            />
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="emp-edit-address-header"
            >
              <div className="div-mailing-address-flex-wrapper">
                <div className="employer-division-from-header">
                  {configJSON.mailingAddress}
                </div>
                <div>
                  <GenericCheckBoxField
                    checked={mailSameAsPhysical}
                    label="Mailing address same as physical address"
                    onChange={(event: React.ChangeEvent<any>) =>
                      this.handleCheckboxMailing(event)
                    }
                    data-test-id="is_mailing_address_same_as_physical_address"
                  />
                </div>
              </div>
            </Grid>
            <GenericAddressComponent
              countryError={this.getClassErrorMessage('mailing_country')}
              stateError={this.getClassErrorMessage('mailing_state')}
              type="mailing"
              addressValues={classAddressDetails}
              handleAddressChange={this.classAddressChange}
              countryList={countryList}
              stateList={mailingStateList}
              disabled={!!mailSameAsPhysical}
              isValidationDivision={true}
              disabledState={mailing_country_id == ""}
            />
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="emp-edit-address-header"
            >
              <div className="div-mailing-address-flex-wrapper">
                <div className="employer-location-from-header">
                  {configJSON.classCheckAddress}
                </div>
                <div>
                  <GenericCheckBoxField
                    checked={
                      this.state.isSameAsPhysical
                    }
                    label={configJSON.classCheckSameAsPhysicalAddress}
                    onChange={(event: React.ChangeEvent<any>) =>
                      this.handleSameAsPhysical(
                        event,
                        "isSameAsPhysical"
                      )
                    }
                    data-test-id="is_class_address_same_as_physical_address"
                  />
                  <GenericCheckBoxField
                    checked={
                      this.state.isSameAsMailing
                    }
                    label={configJSON.classCheckSameAsMailingAddress}
                    onChange={(event: React.ChangeEvent<any>) =>
                      this.handleSameAsMailing(
                        event,
                        "isSameAsMailing"
                      )
                    }
                    data-test-id="is_class_address_same_as_mailing_address"
                  />
                </div>
              </div>
            </Grid>
            <GenericAddressComponent
              countryError={this.getClassErrorMessage('class_check_country')}
              stateError={this.getClassErrorMessage('class_check_state')}
              type="class_check"
              addressValues={classAddressDetails}
              handleAddressChange={this.classAddressChange}
              countryList={this.state.countryList}
              stateList={this.state.classCheckStateList}
              disabled={
                !!this.state.isSameAsPhysical ||
                !!this.state.isSameAsMailing
              }
              isValidationDivision={true}
              disabledState={classAddressDetails.class_check_country_id == ""}
            />

            <div className="dividerWrapper">
              <Divider />
            </div>

            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="class_check_contact1"
                label={"Contact Person 1"}
                value={classAddressDetails.class_check_contact1}
                onChange={(e) => this.classContactChange(e, "class_check_contact1")}
                data-test-id="class_check_contact1"
                onlyLetter={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="class_check_contact2"
                label={"Contact Person 2"}
                value={classAddressDetails.class_check_contact2}
                onChange={(e) => this.classContactChange(e, "class_check_contact2")}
                data-test-id="class_check_contact2"
                onlyLetter={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="class_check_email1"
                label={"Email 1"}
                value={classAddressDetails.class_check_email1}
                onChange={(e) => this.classContactChange(e, "class_check_email1")}
                data-test-id="class_check_email1"
                error={this.getClassErrorMessage('class_check_email1')}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="class_check_email2"
                label={"Email 2"}
                value={classAddressDetails.class_check_email2}
                onChange={(e) => this.classContactChange(e, "class_check_email2")}
                data-test-id="class_check_email2"
                error={this.getClassErrorMessage('class_check_email2')}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="class_check_phone_number1"
                label={"Phone Number 1"}
                value={classAddressDetails.class_check_phone_number1}
                onChange={(e) => this.classContactChange(e, "class_check_phone_number1")}
                data-test-id="class_check_phone_number1"
                onlyNumber={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>

              <GenericTextField
                name="class_check_phone_number2"
                label={"Phone Number 2"}
                value={classAddressDetails.class_check_phone_number2}
                onChange={(e) => this.classContactChange(e, "class_check_phone_number2")}
                data-test-id="class_check_phone_number2"
                onlyNumber={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="class_check_fax1"
                label={"Fax 1"}
                value={classAddressDetails.class_check_fax1}
                onChange={(e) => this.classContactChange(e, "class_check_fax1")}
                data-test-id="class_check_fax1"
                onlyNumber={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="class_check_fax2"
                label={"Fax 2"}
                value={classAddressDetails.class_check_fax2}
                onChange={(e) => this.classContactChange(e, "class_check_fax2")}
                data-test-id="class_check_fax2"
                onlyNumber={true}
              />
            </Grid>
          </Grid>
        )}
        {classActiveStep === 2 && (
          <Grid container spacing={3} data-test-id="step-3-wrapper" />
        )}
        {classActiveStep === 3 && (
          <Grid container spacing={3} data-test-id="step-4-wrapper" >
            <GenericAddAgentComponent
              addAgent={this.addAgent}
              agentList={classOtherDetails.agents}
              agentListError={[]}
              handleInputChange={this.classOtherDetailChange}
              removeAgent={this.removeAgent}
              isValidationDivision={true}
            />
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.cost_containment_fee}
                value={classOtherDetails.cost_containment_fee}
                onChange={(e) =>
                  this.classOtherDetailChange(e, "cost_containment_fee")
                }
                data-test-id="cost_containment_fee"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.admin_fee}
                value={classOtherDetails.admin_fee}
                onChange={(e) =>
                  this.classOtherDetailChange(e, "admin_fee")
                }
                data-test-id="admin_fee"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.ppo_fee}
                value={classOtherDetails.ppo_fee}
                onChange={(e) =>
                  this.classOtherDetailChange(e, "ppo_fee")
                }
                data-test-id="ppo_fee"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.cobra_fee}
                value={classOtherDetails.cobra_fee}
                onChange={(e) =>
                  this.classOtherDetailChange(e, "cobra_fee")
                }
                data-test-id="cobra_fee"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.hipaa_fee}
                value={classOtherDetails.hipaa_fee}
                onChange={(e) =>
                  this.classOtherDetailChange(e, "hipaa_fee")
                }
                data-test-id="hipaa_fee"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.agg_premium_fee}
                value={classOtherDetails.agg_premium_fee}
                onChange={(e) =>
                  this.classOtherDetailChange(e, "agg_premium_fee")
                }
                data-test-id="agg_premium_fee"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.fsa_fee}
                value={classOtherDetails.fsa_fee}
                onChange={(e) =>
                  this.classOtherDetailChange(e, "fsa_fee")
                }
                data-test-id="fsa_fee"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.hra_fee}
                value={classOtherDetails.hra_fee}
                onChange={(e) =>
                  this.classOtherDetailChange(e, "hra_fee")
                }
                data-test-id="hra_fee"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.hsa_fee}
                value={classOtherDetails.hsa_fee}
                onChange={(e) =>
                  this.classOtherDetailChange(e, "hsa_fee")
                }
                data-test-id="hsa_fee"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.fee1}
                value={classOtherDetails.fee1}
                onChange={(e) => this.classOtherDetailChange(e, "fee1")}
                data-test-id="fee1"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.fee2}
                value={classOtherDetails.fee2}
                onChange={(e) => this.classOtherDetailChange(e, "fee2")}
                data-test-id="fee2"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.fee3}
                value={classOtherDetails.fee3}
                onChange={(e) => this.classOtherDetailChange(e, "fee3")}
                data-test-id="fee3"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.fee4}
                value={classOtherDetails.fee4}
                onChange={(e) => this.classOtherDetailChange(e, "fee4")}
                data-test-id="fee4"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.med_ee_spec_premium}
                value={classOtherDetails.med_ee_spec_premium}
                onChange={(e) =>
                  this.classOtherDetailChange(e, "med_ee_spec_premium")
                }
                data-test-id="med_ee_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.den_ee_spec_premium}
                value={classOtherDetails.den_ee_spec_premium}
                onChange={(e) =>
                  this.classOtherDetailChange(e, "den_ee_spec_premium")
                }
                data-test-id="den_ee_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.vis_ee_spec_premium}
                value={classOtherDetails.vis_ee_spec_premium}
                onChange={(e) =>
                  this.classOtherDetailChange(e, "vis_ee_spec_premium")
                }
                data-test-id="vis_ee_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.med_ee_plus_sp_spec_premium}
                value={classOtherDetails.med_ee_plus_sp_spec_premium}
                onChange={(e) =>
                  this.classOtherDetailChange(
                    e,
                    "med_ee_plus_sp_spec_premium"
                  )
                }
                data-test-id="med_ee_plus_sp_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.den_ee_plus_sp_spec_premium}
                value={classOtherDetails.den_ee_plus_sp_spec_premium}
                onChange={(e) =>
                  this.classOtherDetailChange(
                    e,
                    "den_ee_plus_sp_spec_premium"
                  )
                }
                data-test-id="den_ee_plus_sp_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.vis_ee_plus_sp_spec_premium}
                value={classOtherDetails.vis_ee_plus_sp_spec_premium}
                onChange={(e) =>
                  this.classOtherDetailChange(
                    e,
                    "vis_ee_plus_sp_spec_premium"
                  )
                }
                data-test-id="vis_ee_plus_sp_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.med_ee_plus_ch_spec_premium}
                value={classOtherDetails.med_ee_plus_ch_spec_premium}
                onChange={(e) =>
                  this.classOtherDetailChange(
                    e,
                    "med_ee_plus_ch_spec_premium"
                  )
                }
                data-test-id="med_ee_plus_ch_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.den_ee_plus_ch_spec_premium}
                value={classOtherDetails.den_ee_plus_ch_spec_premium}
                onChange={(e) =>
                  this.classOtherDetailChange(
                    e,
                    "den_ee_plus_ch_spec_premium"
                  )
                }
                data-test-id="den_ee_plus_ch_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.vis_ee_plus_ch_spec_premium}
                value={classOtherDetails.vis_ee_plus_ch_spec_premium}
                onChange={(e) =>
                  this.classOtherDetailChange(
                    e,
                    "vis_ee_plus_ch_spec_premium"
                  )
                }
                data-test-id="vis_ee_plus_ch_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.med_ee_plus_chs_spec_premium}
                value={classOtherDetails.med_ee_plus_chs_spec_premium}
                onChange={(e) =>
                  this.classOtherDetailChange(
                    e,
                    "med_ee_plus_chs_spec_premium"
                  )
                }
                data-test-id="med_ee_plus_chs_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.den_ee_plus_chs_spec_premium}
                value={classOtherDetails.den_ee_plus_chs_spec_premium}
                onChange={(e) =>
                  this.classOtherDetailChange(
                    e,
                    "den_ee_plus_chs_spec_premium"
                  )
                }
                data-test-id="den_ee_plus_chs_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.vis_ee_plus_chs_spec_premium}
                value={classOtherDetails.vis_ee_plus_chs_spec_premium}
                onChange={(e) =>
                  this.classOtherDetailChange(
                    e,
                    "vis_ee_plus_chs_spec_premium"
                  )
                }
                data-test-id="vis_ee_plus_chs_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.med_ee_fam_spec_premium}
                value={classOtherDetails.med_ee_fam_spec_premium}
                onChange={(e) =>
                  this.classOtherDetailChange(
                    e,
                    "med_ee_fam_spec_premium"
                  )
                }
                data-test-id="med_ee_fam_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.den_ee_fam_spec_premium}
                value={classOtherDetails.den_ee_fam_spec_premium}
                onChange={(e) =>
                  this.classOtherDetailChange(
                    e,
                    "den_ee_fam_spec_premium"
                  )
                }
                data-test-id="den_ee_fam_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.vis_ee_fam_spec_premium}
                value={classOtherDetails.vis_ee_fam_spec_premium}
                onChange={(e) =>
                  this.classOtherDetailChange(
                    e,
                    "vis_ee_fam_spec_premium"
                  )
                }
                data-test-id="vis_ee_fam_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.specific_deductible}
                value={classOtherDetails.specific_deductible}
                onChange={(e) =>
                  this.classOtherDetailChange(e, "specific_deductible")
                }
                data-test-id="specific_deductible"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.aggregate_deductible}
                value={classOtherDetails.aggregate_deductible}
                onChange={(e) =>
                  this.classOtherDetailChange(e, "aggregate_deductible")
                }
                data-test-id="aggregate_deductible"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.surcharge_percentage}
                value={classOtherDetails.surcharge_percentage}
                onChange={(e) =>
                  this.classOtherDetailChange(e, "surcharge_percentage")
                }
                data-test-id="surcharge_percentage"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} />
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={classOtherDetails.is_active}
                onChange={(e, checked) =>
                  this.classToggleChange(e, checked, "is_active")
                }
                label={configJSON.is_active}
                data-test-id="is_active"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={classOtherDetails.den_flag}
                onChange={(e, checked) =>
                  this.classToggleChange(e, checked, "den_flag")
                }
                label={configJSON.den_flag}
                data-test-id="den_flag"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={classOtherDetails.med_flag}
                onChange={(e, checked) =>
                  this.classToggleChange(e, checked, "med_flag")
                }
                label={configJSON.med_flag}
                data-test-id="med_flag"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={classOtherDetails.std_flag}
                onChange={(e, checked) =>
                  this.classToggleChange(e, checked, "std_flag")
                }
                label={configJSON.std_flag}
                data-test-id="std_flag"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={classOtherDetails.vis_flag}
                onChange={(e, checked) =>
                  this.classToggleChange(e, checked, "vis_flag")
                }
                label={configJSON.vis_flag}
                data-test-id="vis_flag"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={classOtherDetails.life_cover_flag}
                onChange={(e, checked) =>
                  this.classToggleChange(
                    e,
                    checked,
                    "life_cover_flag"
                  )
                }
                label={configJSON.life_cover_flag}
                data-test-id="life_cover_flag"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={classOtherDetails.ltd_flag}
                onChange={(e, checked) =>
                  this.classToggleChange(e, checked, "ltd_flag")
                }
                label={configJSON.ltd_flag}
                data-test-id="ltd_flag"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={classOtherDetails.vol_life_flag}
                onChange={(e, checked) =>
                  this.classToggleChange(e, checked, "vol_life_flag")
                }
                label={configJSON.vol_life_flag}
                data-test-id="vol_life_flag"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={classOtherDetails.add_flag}
                onChange={(e, checked) =>
                  this.classToggleChange(e, checked, "add_flag")
                }
                label={configJSON.add_flag}
                data-test-id="add_flag"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={classOtherDetails.fsa_flag}
                onChange={(e, checked) =>
                  this.classToggleChange(e, checked, "fsa_flag")
                }
                label={configJSON.fsa_flag}
                data-test-id="fsa_flag"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={classOtherDetails.rx_flag}
                onChange={(e, checked) =>
                  this.classToggleChange(e, checked, "rx_flag")
                }
                label={configJSON.rx_flag}
                data-test-id="rx_flag"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={classOtherDetails.hra_flag}
                onChange={(e, checked) =>
                  this.classToggleChange(e, checked, "hra_flag")
                }
                label={configJSON.hra_flag}
                data-test-id="hra_flag"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={classOtherDetails.cancer_flag}
                onChange={(e, checked) =>
                  this.classToggleChange(e, checked, "cancer_flag")
                }
                label={configJSON.cancer_flag}
                data-test-id="cancer_flag"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={classOtherDetails.hsa_flag}
                onChange={(e, checked) =>
                  this.classToggleChange(e, checked, "hsa_flag")
                }
                label={configJSON.hsa_flag}
                data-test-id="hsa_flag"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={classOtherDetails.medical_question_flag}
                onChange={(e, checked) =>
                  this.classToggleChange(
                    e,
                    checked,
                    "medical_question_flag"
                  )
                }
                label={configJSON.medical_question_flag}
                data-test-id="medical_question_flag"
              />
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <GenericErrorText error={generalError} />
        </Grid>
        {this.state.isClassCreated && (
          <GenericSuccessDialog
            data-test-id="success-dialog"
            open={this.state.isClassCreated}
            handleDone={this.handleSuccessDialogClose}
            contains={configJSON.classAddSuccess}
          />
        )}
      </>
    );

    return (
      <ThemeProvider theme={defaultMaterialTheme}>
        <GenericDialogFormWrapper
          open={this.props.isOpenEditClassDialog}
          title={
            this.props.isEdit ? configJSON.editGroup : configJSON.addNewClass
          }
          handleClose={this.handleClassDialogClose}
          stepList={addEditClassStepList}
          activeStep={classActiveStep}
          saveAndNext={this.saveAndNext}
          saveAndFinish={this.saveAndFinish}
          formContent={classFormContent}
          disableOnClick={!addEditClassStepList[0].isCompleted}
          setActiveStep={this.setActiveStep}
          handlePrevious={() => {
            this.handleSteps(false);
          }}
          buttonTitle={this.props.isEdit ? "Save Changes" : "Add Class"}
        />
        <GenericCloseDialog
          open={this.state.isOpenWarningDialog}
          handleClose={() => this.handleCloseWarningDialog(false)}
          handleOk={() => this.handleCloseWarningDialog(true)}
        />
      </ThemeProvider>
    );
  }
}
