import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { CompanyVision, DivisionsVision, Solidarity, moveDown, moveUp } from "./assets";

// Customizable Area Start
import StepOne from "../../../components/src/ClientStepper/ClientEditsSteper/StepOne.web";
import StepTwo from "../../../components/src/ClientStepper/ClientEditsSteper/StepTwo.web";
import StepThree from "../../../components/src/ClientStepper/ClientEditsSteper/StepThree.web";
import { ClientAddressModel, ClientModel, initialClientAddress, initialClientInfo } from "./ClientEdit/ClientModel";
// Customizable Area End

export const configJSON = require("./config");

const steps: any[] = [StepOne, StepTwo, StepThree];


export interface Props {
  id: string;
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  metricsData: any;
  chartOne: any;
  chartTwo: any;
  chartThree: any;
  chartFour: any;

  isClientEditsOpen: boolean;
  isEditModalClose: boolean;

  selectedMonthschart1: any;
  selectedGroups: any
  selectedMonthschart2: any;
  selectedMonthschart3: any;
  selectedMonthschart4: any;
  selecteMetricsMonths: any;

  clientDetail: ClientModel
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ClientDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  token: string = "";
  client_id: string = "";
  requestClientInfo: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedGroups: 'Groups',
      selecteMetricsMonths: '6',
      selectedMonthschart1: '6',
      selectedMonthschart2: '6',
      selectedMonthschart3: '6',
      selectedMonthschart4: '6',
      isClientEditsOpen: false,

      chartFour: configJSON.chartData1,
      chartThree: configJSON.chartData3,
      chartTwo: configJSON.chartData2,
      chartOne: configJSON.chartData4,
      metricsData: [
        {
          title: "Total Employers",
          count: "7460",
          images: CompanyVision,
          flag: moveUp,
          des: "5% increase than last month"
        },
        {
          title: "Total Groups",
          count: "1150",
          images: Solidarity,
          flag: moveUp,
          des: "12% increase than last month"
        },
        {
          title: "Total Divisions",
          count: "1089",
          images: DivisionsVision,
          flag: moveUp,
          des: "10% increase than last month"
        },
        {
          title: "Total Locations",
          count: "1089",
          images: null,
          flag: moveDown,
          des: "5% increase than last month"
        },
        {
          title: "Total Members",
          count: "24000",
          images: CompanyVision,
          flag: moveUp,
          des: "5% increase than last month"
        },
        {
          title: "Total Classes",
          count: "1020",
          images: Solidarity,
          flag: moveUp,
          des: "12% increase than last month"
        },
        {
          title: "Total Claims",
          count: "$3245",
          images: Solidarity,
          flag: moveUp,
          des: "12% increase than last month"
        },
        {
          title: "Total Premiums",
          count: "$1020",
          images: null,
          flag: moveUp,
          des: "12% increase than last month"
        },
      ],

      isEditModalClose: false,

      clientDetail: initialClientInfo

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const response = JSON.parse(loggedInUser);
      this.token = response.token
      this.client_id = response.id
      this.getInfoClient(response.token, response.id)
    }
  }

  getInfoClient(token: string, client_id: string) {
    const header = {
      "Content-Type": configJSON.ContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.requestClientInfo = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getClientInfo}${client_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.requestClientInfo) {
        this.handleResponseInfoClient(responseJson)
      }
    }
  }

  handleResponseInfoClient = (response: any) => {
    if (response && response.data) {
      this.extractData(response.data);
    }
  }

  extractData = (apiData: any) => {
    const client: ClientModel = initialClientInfo

    const data = apiData.attributes

    const objBasicDetails = {
      client_name: data.first_name,
      tax_id: data.tax_id,
      effective_date: data.created_at,
      termination_date: data.updated_at,
      contact_number: data.phone_number,
      email: data.email,
      client_id: apiData.id,
      first_name: data.first_name,
      last_name: data.last_name
    }

    const objAddress: ClientAddressModel = initialClientAddress

    const physical = data.physical_address.data

    if (physical) {
      objAddress.physical_address_line1 = physical.attributes.address_line_1
      objAddress.physical_address_line2 = physical.attributes.address_line_2
      objAddress.physical_city = physical.attributes.city
      objAddress.physical_state_id = physical.attributes.state
      objAddress.physical_zip_code = physical.attributes.zip_code
      objAddress.physical_country_id = physical.attributes.country
    }

    const mailing = data.mailing_address.data

    if (mailing) {
      objAddress.mailing_address_line1 = mailing.attributes.address_line_1
      objAddress.mailing_address_line2 = mailing.attributes.address_line_2
      objAddress.mailing_city = mailing.attributes.city
      objAddress.mailing_state_id = mailing.attributes.state
      objAddress.mailing_zip_code = mailing.attributes.zip_code
      objAddress.mailing_country_id = mailing.attributes.country
    }

    const contacts = data.contacts.data

    if (contacts) {
      contacts.forEach((item: any, index: number) => {
        if (item.attributes) {
          const obj = item.attributes
          if (index === 0) {
            objAddress.client_contact1 = obj.contact
            objAddress.client_email1 = obj.email
            objAddress.client_phone1 = obj.phone_number
            objAddress.client_fax1 = obj.fax
          } else {
            objAddress.client_contact2 = obj.contact
            objAddress.client_email2 = obj.email
            objAddress.client_phone2 = obj.phone_number
            objAddress.client_fax2 = obj.fax
          }
        }
      });
    }

    client.clientBasicDetails = objBasicDetails
    client.clientAddress = objAddress

    this.setState({ clientDetail: client })
  }

  handleMonthsChange = (event: any) => {
    this.setState({ selectedMonthschart1: event.target.value });
  }

  handleMonthsChangechart2 = (event: any) => {
    this.setState({ selectedMonthschart2: event.target.value });
  }

  handleMonthsChangechart3 = (event: any) => {
    this.setState({ selectedMonthschart3: event.target.value });
  }

  handleMonthsChangeChart4 = (event: any) => {
    this.setState({ selectedMonthschart4: event.target.value });
  }

  handleSelectedGroups = (event: any) => {
    this.setState({ selectedGroups: event.target.value })
  }

  handleMetricsMonths = (event: any) => {
    this.setState({ selecteMetricsMonths: event.target.value });
  }

  handleEditClick = () => {
    this.setState({ isClientEditsOpen: true })
  }

  handleCloseClientEdits = () => {
    this.setState({ isClientEditsOpen: false }, () => this.getInfoClient(this.token, this.client_id))
  }

  // Customizable Area End
}
