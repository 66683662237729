import { IBlock } from "../../../../framework/src/IBlock";
import React from "react";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Column } from "../../../../components/src/CustomTable.web";
import { IconButton, Box } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { FilterChild } from "../../../../components/src/CustomFilterPopup.web";
import { IClassDetailModel, initialClassDetailModelData } from "./ClientEmpClassModel";
import { SelectedEmployerInterface } from "../AllRoleLandingPageController.web";
export const images = require("../../../MemberDirectory2/src/assets");


// Customizable Area Start
// Customizable Area End

export const configJSON = require(".././config");

export interface Props {
    id: string;
    navigation: any;
    // Customizable Area Start
    selectedEmployer: SelectedEmployerInterface[]
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    rowData: RowData[];
    filterChildList: FilterChild[];
    tableMenuAnchorEl: HTMLElement | null;
    isEditClass: boolean;
    isOpenEditClassDialog: boolean;
    classDetails: IClassDetailModel;
    isClassDetailSaved: boolean;
    searchValue: string;
    openFilter: boolean;
    token: string;
    // Customizable Area End
}
interface Class {
    key?: string;
    className?: string;
    clientName?: string;
    employerName?: string;
    groupName?: string;
    divisionName?: string;
    locationName?: string;
}

interface RowData {
    id: string;
    className: string;
    clientName: string;
    employerName: string;
    groupName: string;
    divisionName: string;
    locationName: string
}




interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

const ClassList: any[] = [];


export default class ClientEmployerClassController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    tabMenuList = [
        "Groups",
        "Enrollment",
        "Enroll Config",
        "Medical Review",
        "Pay Claims",
        "Recon",
        "Invoice",
        "Payroll Invoice",
        "Case Billing",
        "Documents",
    ];
    requestClassList: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        const ClassName: never[] = []

        const GroupName: never[] = []

        const clientName: never[] = []

        const employerName: never[] = []

        const LocationName: never[] = []

        const DivisionName: never[] = []

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start

            getName(MessageEnum.RestAPIResponceMessage)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            rowData: [],
            tableMenuAnchorEl: null,
            isClassDetailSaved: false,
            isEditClass: false,
            classDetails: initialClassDetailModelData,
            isOpenEditClassDialog: false,
            searchValue: "",
            openFilter: false,
            filterChildList: [
                {
                    key: "ClassName",
                    isOpen: false,
                    searchString: "",
                    viewAll: false,
                    list: ClassName,
                    mainList: ClassName,
                },
                {
                    key: "ClientName",
                    isOpen: false,
                    searchString: "",
                    viewAll: false,
                    list: clientName,
                    mainList: clientName,
                },
                {
                    key: "employerName",
                    isOpen: false,
                    searchString: "",
                    viewAll: false,
                    list: employerName,
                    mainList: employerName,
                },
                {
                    key: "GroupName",
                    isOpen: false,
                    searchString: "",
                    viewAll: false,
                    list: GroupName,
                    mainList: GroupName,
                },
                {
                    key: "DivisionName",
                    isOpen: false,
                    searchString: "",
                    viewAll: false,
                    list: DivisionName,
                    mainList: DivisionName,
                },
                {
                    key: "LocationName",
                    isOpen: false,
                    searchString: "",
                    viewAll: false,
                    list: LocationName,
                    mainList: LocationName,
                },
            ],
            token: ''
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    style: any = {
        headerAlign: "center",
        align: "center",
    }
    // Customizable Area Start
    tableColumns: Column[] = [
        {
            ...this.style,
            id: "className",
            label: "Class Name",
        },
        {
            id: "clientName",
            label: "Client Name",
            ...this.style,
        },
        {
            id: "employerName",
            label: "Employer Name",
            ...this.style,
        },
        {
            id: "groupName",
            label: "Group Name",
            ...this.style,
        },
        {
            id: "divisionName",
            label: "Division Name",
            ...this.style,
        },
        {
            id: "locationName",
            label: "Location Name",
            ...this.style,
        },
        {
            id: "action",
            label: "",
            headerAlign: "left",
            align: "right",
            format: () => {
                return (
                    <div className="grp-table-action-container">
                        <IconButton
                            style={{ padding: 0 }}
                            aria-label="more"
                            aria-controls="long-menu"
                            data-test-id="menu_icon"
                            aria-haspopup="true"
                            onClick={this.handleOpenTableMenu}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </div>
                );
            },
        },
    ];

    async componentDidMount() {
        const loggedInUser = localStorage.getItem("user");
        if (loggedInUser) {
            const { token } = JSON.parse(loggedInUser);
            this.setState({ token })
            this.getAllClassList(token);
        }
    }

    getAllClassList = (token: string) => {
        if (!this.props.selectedEmployer[0]?.id) {
            return;
        }
        const header = {
            "Content-Type": configJSON.ContentType,
            "token": token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.requestClassList = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getListClassEndPoint.replace("{id}", this.props.selectedEmployer[0].id)}?page=1&per_page=100`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.requestClassList) {
                this.getClassListData(responseJson)
            }
        }
    }

    getClassListData = (response: any) => {
        if (response && response.data && response.data.length > 0) {
            this.extractTableData(response.data);
        }
    }

    extractTableData = (apiData: any) => {
        const extractedData: RowData[] = [];
        apiData.forEach((item: any) => {
            const id = item.id;
            const className = item.attributes.class_name || "-"
            const clientName = item.attributes.client_name || "-"
            const locationName = item.attributes.location.location_name || "-";
            const divisionName = item.attributes.division.division_name || "-";
            const groupName = item.attributes.group.group_id || "-";
            const employerName = item.attributes.employer.employer_name || "-";
            const extractedItem = {
                id,
                className,
                clientName,
                employerName,
                groupName,
                divisionName,
                locationName
            }
            extractedData.push(extractedItem);
        });

        this.setState({
            rowData: extractedData
        })
    }

    toggleEditClassDialog = (open: boolean, isEdit: boolean) => {
        this.setState({
            ...this.state,
            isEditClass: isEdit,
            tableMenuAnchorEl: null,
            isOpenEditClassDialog: open,
        });
    };

    handleCloseTableMenu = () => {
        this.setState({ ...this.state, tableMenuAnchorEl: null });
    };

    handleOpenTableMenu = (event: any) => {
        this.setState({ ...this.state, tableMenuAnchorEl: event.currentTarget });
    };

    onSearchInputChange = (e: any) => {
        const searchKey = e.target.value;
        this.setState({ searchValue: searchKey })
    };

    handleClassFilterClick = () => {
        this.setState({ openFilter: !this.state.openFilter });
    };

    handleClassDialogClose = () => {
        this.toggleEditClassDialog(false, false);
        this.getAllClassList(this.state.token)
    }
    // Customizable Area End
}
