import React from "react";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { Column } from "../../../../components/src/CustomTable.web";
import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { IGroupAddressDetailModel, IGroupDetailModel, IGroupOtherDetailModel, initialGroupAddressDetailModelData, initialGroupDetailModelData, initialGroupOtherDetailModelData } from "./ClientEmpGroupModel";
import { FilterChild } from "../../../../components/src/CustomFilterPopup.web";
import { SelectedEmployerInterface } from "../AllRoleLandingPageController.web";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
import { IAgentModel } from "../../../../components/src/GenericAddAgentComponent.web";

export const configJSON = require("../config");
interface IGroupRowData {
  id: string;
  groupNo: string;
  groupName: string;
  clientName: string;
  employerName: string;
  city: string;
  state: string;
  zip: string;
  effective_date: string;
  termination_date: string;
  contact_name: string;
  contact_number: string;
}
export interface Props {
  id: string;
  navigation: any;
  selectedEmployer: SelectedEmployerInterface[]
}

interface S {
  groupSearchText: string;
  groupRowItems: IGroupRowData[];
  groupTableMenuAnchorEl: HTMLElement | null;
  isOpenEditGroupsDialog: boolean;

  isEditGroup: boolean;
  groupDetails: IGroupDetailModel;
  openGroupFilter: boolean;
  groupFilterChildList: FilterChild[];
  token: string;
  actionId: string;
  showDialogSuccess: boolean;
}

interface SS { }

export default class ClientEmpGroupsLandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  groupListColumns: Column[] = [
    { id: "groupNo", label: "Group No", },
    { id: "groupName", label: "Group Name", },
    { id: "clientName", label: "Client Name", },
    { id: "employerName", label: "Employer Name" },
    { id: "city", label: "City", },
    { id: "state", label: "State" },
    { id: "zip", label: "Zip" },
    { id: "effective_date", label: "Effective Date", },
    { id: "termination_date", label: "Termination Date", },
    { id: "contact_name", label: "Contact Name", },
    { id: "contact_number", label: "Contact Number", },
    {
      id: "action",
      label: "",
      headerAlign: "left",
      align: "right",
      format: (groupRow: IGroupRowData) => {
        return (
          <div className="grp-table-action-container">
            <IconButton
              className="menu-btn"
              data-test-id="group-table-menu"
              onClick={this.handleGroupsOpenTableMenu}
            >
              <MoreVertIcon fontSize="large" />
            </IconButton>
          </div>
        );
      },
    },
  ];
  groupTabMenuList = [
    "Plan",
    "Enrollment",
    "Enroll Config",
    "Medical Review",
    "Pay Claims",
    "Recon",
    "Invoice",
    "Payroll Invoice",
    "Case Billing",
    "Documents",
  ];
  requestGroupList: string = "";
  requestDeleteGroup: string = "";
  rawDataList: any[] = [];
  groupList: IGroupRowData[] = []

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    const groupNameList: never[] = []

    const clientNameList: never[] = []

    const employerNameList: never[] = []

    const cityList: never[] = []

    const stateList: never[] = []

    const zipList: never[] = []

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      groupSearchText: "",
      groupRowItems: [],
      groupTableMenuAnchorEl: null,
      isEditGroup: false,

      isOpenEditGroupsDialog: false,
      groupDetails: initialGroupDetailModelData,
      openGroupFilter: false,
      groupFilterChildList: [
        {
          key: "Group Name",
          isOpen: true,
          searchString: "",
          viewAll: false,
          list: groupNameList,
          mainList: groupNameList,
        },
        {
          key: "Client Name",
          isOpen: false,
          searchString: "",
          viewAll: false,
          list: clientNameList,
          mainList: clientNameList,
        },
        {
          key: "Employer Name",
          isOpen: false,
          searchString: "",
          viewAll: false,
          list: employerNameList,
          mainList: employerNameList,
        },
        {
          key: "City",
          isOpen: false,
          searchString: "",
          viewAll: false,
          list: cityList,
          mainList: cityList,
        },
        {
          key: "State",
          isOpen: false,
          searchString: "",
          viewAll: false,
          list: stateList,
          mainList: stateList,
        },
        {
          key: "Zip",
          isOpen: false,
          searchString: "",
          viewAll: false,
          list: zipList,
          mainList: zipList,
        },
      ],
      token: '',
      actionId: '',
      showDialogSuccess: false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const { token } = JSON.parse(loggedInUser);
      this.setState({ token: token })
      this.fetchGroupsList(token);
    }
  }

  async receive(from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.requestGroupList) {
        this.receiveGroupList(responseJson)
      }
      if (apiRequestCallId === this.requestDeleteGroup) {
        this.handleDeleteGroup(responseJson)
      }
    }
  }

  fetchGroupsList = (token: string) => {
    if (this.props.selectedEmployer.length === 0) {
      return;
    }
    const header = {
      "Content-Type": configJSON.ContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.requestGroupList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getGroupNewAPiEndPoint.replace("{id}", this.props.selectedEmployer[0].id)}?page=0&per_page=200`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  receiveGroupList = (response: any) => {
    if (response && response.data && response.data.length > 0) {
      this.extractTableData(response.data);
    }
  }

  extractTableData = (apiData: any) => {
    const extractedData: IGroupRowData[] = [];
    this.rawDataList = apiData
    apiData.forEach((item: any) => {
      const id = item.id;
      const groupNo = item.attributes.group_id || "-";
      const groupName = item.attributes.group_name || "-";
      const clientName = item.attributes.client_name || "-";
      const employerName = item.attributes.employer_name || "-";

      const effective_date = item.attributes.group_being_date || "-";
      const termination_date = item.attributes.group_termination_date || "-";

      const physical_address = item.attributes.physical_address.data
      const contact = item.attributes.contact.data
      let city = "-"
      let state = "-"
      let zip = "-"
      let contact_name = "-"
      let contact_number = "-"
      if (physical_address) {
        city = physical_address?.attributes.city || "-";
        state = physical_address?.attributes.state || "-";
        zip = physical_address?.attributes.zip_code || "-";
      }

      if (contact && contact.length !== 0) {
        contact_name = contact[0].attributes.contact || "-"
        contact_number = contact[0].attributes.phone_number || "-"
      }

      const extractedItem = {
        id,
        groupNo,
        groupName,
        clientName,
        employerName,
        city,
        state,
        zip,
        effective_date,
        termination_date,
        contact_name,
        contact_number
      }
      extractedData.push(extractedItem);
    });

    this.groupList = extractedData
    this.setState({ groupRowItems: extractedData })
  }

  handleGroupsOpenTableMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      groupTableMenuAnchorEl: event?.currentTarget,
    });
  };

  handleGroupsCloseTableMenu = () => {
    this.setState({
      groupTableMenuAnchorEl: null,
      groupDetails: initialGroupDetailModelData,
      actionId: ''
    });
  };

  handleGroupsTableMenuClick = (id: string) => {
    if (id === "1") {
      this.toggleEditGroupDialog(true, true);
    }
    if (id === "2") {
      this.callAPIDeleteGroup()
    }
  };

  onSearchGroupChange = (e: React.ChangeEvent<any>) => {
    const searchGrpParam = e.target.value;
    this.setState({ groupSearchText: searchGrpParam }, () =>
      this.handleGroupFilterOrSearchOnChange(this.state.groupFilterChildList)
    )
  };

  handleSuccessDialogClose = () => {
    this.rawDataList = this.rawDataList.filter(item => item.id !== this.state.actionId)
    this.groupList = this.groupList.filter(item => item.id !== this.state.actionId)

    this.setState({
      groupRowItems: this.groupList,
      actionId: '',
      showDialogSuccess: false
    })
  }

  toggleEditGroupDialog = (open: boolean, isEdit: boolean) => {
    this.setState({
      ...this.state,
      isOpenEditGroupsDialog: open,
      groupTableMenuAnchorEl: null,
      isEditGroup: isEdit
    });
  };

  toggleGroupFilter = () => {
    this.setState({ openGroupFilter: !this.state.openGroupFilter });
  }

  handleGroupFilterOrSearchOnChange = (newState: FilterChild[]) => {
    this.setState({ groupFilterChildList: newState });

  };

  handleGroupDialogClose = () => {
    this.toggleEditGroupDialog(false, false);
    this.fetchGroupsList(this.state.token)
  }

  callAPIDeleteGroup = () => {
    this.setState({
      groupTableMenuAnchorEl: null
    })
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.state.token,
    }

    const requestMessageAddress = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.requestDeleteGroup = requestMessageAddress.messageId

    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleleGroupEndPoint.replace("{id}", this.props.selectedEmployer[0].id)}/${this.state.actionId}`
    )

    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );

    runEngine.sendMessage(requestMessageAddress.id, requestMessageAddress);
  }

  handleDeleteGroup(response: any) {
    if (response) {
      this.setState({ showDialogSuccess: true });
    }
  }

  convertBasicDetails(attributes: any) {
    return {
      group_name: attributes.group_name,
      group_id: attributes.group_id,
      client_name: attributes.client_name,
      employer_name: attributes.employer_name,
      employer_number: attributes.employer_id,
      group_being_date: attributes.group_being_date,
      group_termination_date: attributes.group_termination_date,
      group_deductible_date: attributes.group_deductible_date,
      payroll_day: attributes.payroll_day,
      payroll_month: attributes.payroll_month,
      payroll_cycle1: attributes.payroll_cycle1,
      payroll_cycle2: attributes.payroll_cycle2,
      payroll_cycle3: attributes.payroll_cycle3,
      payroll_cycle4: attributes.payroll_cycle4,
      tax_id: attributes.tax_id,
    }
  }

  convertPropertiesForValue(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key]
        if (typeof value === 'string') {
          obj[key] = (value.toLowerCase() === 'true' || value.toLowerCase() === 'false') ? (value.toLowerCase() === 'true') : parseFloat(value) || 0
        }
      }
    }
    return obj
  }

  convertPhysicalAddress(attributes: any, addressDetails: IGroupAddressDetailModel) {
    const physical = attributes.physical_address.data
    if (physical) {
      addressDetails = {
        ...addressDetails,
        physical_address_line1: physical.attributes.address_line_1,
        physical_address_line2: physical.attributes.address_line_2,
        physical_city: physical.attributes.city,
        physical_zip_code: physical.attributes.zip_code,
        physical_country_id: physical.attributes.country,
        physical_state_id: physical.attributes.state
      }
    }

    return addressDetails
  }

  convertMailingAddress(attributes: any, addressDetails: IGroupAddressDetailModel) {
    const mailing = attributes.mailing_address.data

    if (mailing) {
      addressDetails = {
        ...addressDetails,
        mailing_address_line1: mailing.attributes.address_line_1,
        mailing_address_line2: mailing.attributes.address_line_2,
        mailing_city: mailing.attributes.city,
        mailing_zip_code: mailing.attributes.zip_code,
        mailing_country_id: mailing.attributes.country,
        mailing_state_id: mailing.attributes.state
      }
    }

    return addressDetails
  }

  convertGrCheckAddress(attributes: any, addressDetails: IGroupAddressDetailModel) {
    const gr_check = attributes.group_check_address.data

    if (gr_check) {
      addressDetails = {
        ...addressDetails,
        group_check_address_line1: gr_check.attributes.address_line_1,
        group_check_address_line2: gr_check.attributes.address_line_2,
        group_check_city: gr_check.attributes.city,
        group_check_zip_code: gr_check.attributes.zip_code,
        group_check_country_id: gr_check.attributes.country,
        group_check_state_id: gr_check.attributes.state
      }
    }

    return addressDetails
  }

  convertModel(data: any) {
    const attributes = data.attributes

    const basicDetails = this.convertBasicDetails(attributes)

    let addressDetails: IGroupAddressDetailModel = { ...initialGroupAddressDetailModelData }

    addressDetails = this.convertPhysicalAddress(attributes, addressDetails)

    addressDetails = this.convertMailingAddress(attributes, addressDetails)

    addressDetails = this.convertGrCheckAddress(attributes, addressDetails)

    const contact = attributes.contact.data
    if (contact && contact.length !== 0) {
      contact.forEach((item: any, index: number) => {
        if (index === 0) {
          addressDetails = {
            ...addressDetails,
            group_check_group_contact1: item.attributes.contact,
            group_check_group_email1: item.attributes.email,
            group_check_group_phone_number1: item.attributes.phone_number,
            group_check_group_billing_contact: item.attributes.billing_contact || addressDetails.group_check_group_billing_contact,
            group_check_group_enroll_status: item.attributes.enroll_status || addressDetails.group_check_group_enroll_status,
          }
        }
        if (index === 1) {
          addressDetails = {
            ...addressDetails,
            group_check_group_contact2: item.attributes.contact,
            group_check_group_email2: item.attributes.email,
            group_check_group_phone_number2: item.attributes.phone_number,
            group_check_group_billing_contact: item.attributes.billing_contact || addressDetails.group_check_group_billing_contact,
            group_check_group_enroll_status: item.attributes.enroll_status || addressDetails.group_check_group_enroll_status,
          }
        }
      })
    }

    let otherDetails: IGroupOtherDetailModel = initialGroupOtherDetailModelData
    const agents = attributes.agents.data
    if (agents && agents?.length !== 0) {
      const dataAgents: Array<IAgentModel> = []
      agents.forEach((item: any, index: number) => {
        dataAgents.push({
          agent_name: item.attributes.agent_name,
          agent_rate: item.attributes.agent_rate,
          id: index + 1
        })
      })
      otherDetails = {
        ...otherDetails,
        agents: dataAgents
      }
    }

    if (attributes.other_details.data?.attributes) {
      const result = this.convertPropertiesForValue(attributes.other_details.data.attributes)

      otherDetails = {
        ...otherDetails,
        ...result,
      }
    }

    const dataDetails: IGroupDetailModel = {
      apiGroupId: data.id,
      groupBasicDetails: basicDetails,
      groupAddressDetails: addressDetails,
      groupOtherDetails: otherDetails,
    }
    return dataDetails
  }

  handleActionClick = (id: string) => {
    this.setState({
      actionId: id,
      groupDetails: this.convertModel(this.rawDataList.find(item => item.id === id))
    })
  }
}
