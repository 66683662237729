import React from "react";
import { Button, Grid } from "@material-ui/core";
import ClientEmployerPlanPageController, {
  Props
} from "./ClientEmployerPlanPageController.web";
import ClientEmployerAddPlanDialog from "./ClientEmployerAddPlanDialog.web";
import GenericSearch from "../../../../components/src/GenericSearch.web";
import { filterIcon, searchIcon } from "../assets";

export default class ClientEmployerPlanPage extends ClientEmployerPlanPageController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { isAddPlanEmployerDialogOpen, planData } = this.state;

    return (
      <>
        <Grid item xs={12} className="search-bar-and-add-btn">
          <GenericSearch
            searchIcon={searchIcon}
            placeholder="Search for Group"
            value={""}
            onChange={() => {}}
            data-test-id="emp-plan-search-input"
          />
          <div className="add-plan-btn-div">
            <div className="filter-grp-btn-container">
              <Button data-test-id="filter-btn" className="filter-icon">
                <img src={filterIcon} height={48} alt="Filter Icon" />
              </Button>
            </div>
            <Button
              className="add-plan-btn"
              data-test-id="add-plan-btn"
              onClick={() => this.toggleAddPlanEmployerDialog(true)}
            >
              + Add Plan
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} className="hr mt-30" />
        {isAddPlanEmployerDialogOpen && (
          <ClientEmployerAddPlanDialog
            isOpen={isAddPlanEmployerDialogOpen}
            clientDetails={this.props.clientDetails}
            employerList={this.props.employerList}
            planData={planData}
            token={this.props.token}
            handleSaveAndFinish={this.handleSaveAndFinish}
            handleClose={() => this.toggleAddPlanEmployerDialog(false)}
          />
        )}
      </>
    );
  }
}
