import React, { Component } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { IMedicalModal, IPlanDetailModel } from "../ClientPlanModel";
import GenericDateField from "../../../../../components/src/GenericDateField.web";
import GenericTextField from "../../../../../components/src/GenericTextField.web";
import GenericCheckBoxField from "../../../../../components/src/GenericCheckBoxField.web";

interface Props {
  medicalData: IMedicalModal;
  handleInputItemChange(
    inputValue: string | number | boolean,
    group: keyof IPlanDetailModel["planData"],
    param: string
  ): void;
  handleFileUpload(
    files: FileList | null,
    group: keyof IPlanDetailModel["planData"],
    param: string
  ): void;
  handleFieldError(param: string): string | undefined;
}

export class EmployerAddPlanMedical extends Component<Props> {
  render() {
    return (
      <Grid
        container
        spacing={3}
        data-test-id="plan-step-2-wrapper"
        className="plan-step-2-wrapper"
      >
        <Grid item xs={12} sm={12} md={6}>
          <GenericDateField
            label="Effective Date"
            value={this.props.medicalData.effective_date}
            onChange={e =>
              this.props.handleInputItemChange(
                e.target.value,
                "medical",
                "effective_date"
              )
            }
            error={this.props.handleFieldError("effective_date")}
            data-test-id="medical_effective_date"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <GenericDateField
            label="Termination Date"
            value={this.props.medicalData.termination_date}
            onChange={e =>
              this.props.handleInputItemChange(
                e.target.value,
                "medical",
                "termination_date"
              )
            }
            error={this.props.handleFieldError("termination_date")}
            data-test-id="medical_termination_date"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <GenericTextField
            label="Plan Year Doller Limit"
            value={this.props.medicalData.doller_costlimit}
            onChange={e =>
              this.props.handleInputItemChange(
                e.target.value,
                "medical",
                "doller_costlimit"
              )
            }
            data-test-id="p-doller-limit"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <div className="input-lable">
            Scheduled Of Benefit Documents
          </div>
          <div>
            <input
              type="file"
              id="plan-Benefit-documents-upload-input"
              style={{ display: "none" }}
              data-test-id="plan-Benefit-documents-upload-input"
              onChange={e =>
                this.props.handleFileUpload(
                  e.target.files,
                  "medical",
                  "benefit_documents"
                )
              }
              name="benefit_documents"
            />
            <label htmlFor="plan-Benefit-documents-upload-input" className="plan-step-1-wrapper">
              <Button
                variant="outlined"
                component="span"
                color="primary"
                fullWidth
                className="img-upload-btn"
              >
                Choose a File
              </Button>
            </label>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <div className="input-lable">
            Enrollment Documents
          </div>
          <div>
            <input
              type="file"
              id="enrollment-plan-documents-input"
              style={{ display: "none" }}
              data-test-id="enrollment-plan-documents"
              onChange={e =>
                this.props.handleFileUpload(
                  e.target.files,
                  "medical",
                  "enrollment_document"
                )
              }
              name="enrollment_plan_documents"
            />
            <label htmlFor="enrollment-plan-documents-input" className="plan-step-1-wrapper">
              <Button
                variant="outlined"
                component="span"
                color="primary"
                fullWidth
                className="img-upload-btn"
              >
                Choose a File
              </Button>
            </label>
          </div>
        </Grid>
        <Grid container spacing={3} item xs={12} style={{ marginTop: 5 }}>
          <Grid item xs={12} sm={12}>
            <Typography style={webStyle.title}>
              Employee Monthly Cost
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="EE Cost"
              value={this.props.medicalData.ee_cost}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "ee_cost"
                )
              }
              data-test-id="ee_cost"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="EE Spouse Cost"
              value={this.props.medicalData.ee_spouse_cost}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "ee_spouse_cost"
                )
              }
              data-test-id="ee_spouse_cost"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="EE Child Cost"
              value={this.props.medicalData.ee_child_cost}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "ee_child_cost"
                )
              }
              data-test-id="ee_child_cost"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="EE More Than One Child Cost"
              value={this.props.medicalData.ee_more_than_one_child_cost}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "ee_more_than_one_child_cost"
                )
              }
              data-test-id="ee_more_than_one_child_cost"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="Family Cost"
              value={this.props.medicalData.ee_family_cost}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "ee_family_cost"
                )
              }
              data-test-id="ee_family_cost"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} item xs={12} style={{ marginTop: 5 }}>
          <Grid item xs={12} sm={12}>
            <Typography style={webStyle.title}>
              Employer Monthly Cost
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="ER Cost"
              value={this.props.medicalData.er_cost}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "er_cost"
                )
              }
              data-test-id="er_cost"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="ER Spouse Cost"
              value={this.props.medicalData.er_spouse_cost}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "er_spouse_cost"
                )
              }
              data-test-id="er_spouse_cost"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="ER Child Cost"
              value={this.props.medicalData.er_child_cost}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "er_child_cost"
                )
              }
              data-test-id="er_child_cost"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="ER More Than One Child Cost"
              value={this.props.medicalData.er_more_than_one_child_cost}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "er_more_than_one_child_cost"
                )
              }
              data-test-id="er_more_than_one_child_cost"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="Family Cost"
              value={this.props.medicalData.er_family_cost}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "er_family_cost"
                )
              }
              data-test-id="er_family_cost"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} item xs={12} style={{ marginTop: 5 }}>
          <Grid item xs={12} sm={12}>
            <Typography style={webStyle.title}>In Network</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="Single Deductible"
              value={this.props.medicalData.in_network_single_deductible}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "in_network_single_deductible"
                )
              }
              data-test-id="in_network_single_deductible"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="Family Deductible"
              value={this.props.medicalData.in_network_family_deductible}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "in_network_family_deductible"
                )
              }
              data-test-id="in_network_family_deductible"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="Single Out of Pocket"
              value={this.props.medicalData.in_network_single_out_of_pocket}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "in_network_single_out_of_pocket"
                )
              }
              data-test-id="in_network_single_out_of_pocket"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="Family Out of Pocket"
              value={this.props.medicalData.in_network_family_out_of_pocket}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "in_network_family_out_of_pocket"
                )
              }
              data-test-id="in_network_family_out_of_pocket"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="Single Coinsurance"
              value={this.props.medicalData.in_network_single_coinsurance}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "in_network_single_coinsurance"
                )
              }
              data-test-id="in_network_single_coinsurance"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="Family Coinsurance"
              value={this.props.medicalData.in_network_family_coinsurance}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "in_network_family_coinsurance"
                )
              }
              data-test-id="in_network_family_coinsurance"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="PCP Copay"
              value={this.props.medicalData.in_network_pcp_copay}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "in_network_pcp_copay"
                )
              }
              data-test-id="in_network_pcp_copay"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="Specialist Copay"
              value={this.props.medicalData.in_network_specialist_copay}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "in_network_specialist_copay"
                )
              }
              data-test-id="in_network_specialist_copay"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="Urgent Care Copay"
              value={this.props.medicalData.in_network_urgent_care_copay}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "in_network_urgent_care_copay"
                )
              }
              data-test-id="in_network_urgent_care_copay"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="ER Visit Copay"
              value={this.props.medicalData.in_network_er_visit_copay}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "in_network_er_visit_copay"
                )
              }
              data-test-id="in_network_er_visit_copay"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="Emergecy Room Copay"
              value={this.props.medicalData.in_network_emergecy_room_copay}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "in_network_emergecy_room_copay"
                )
              }
              data-test-id="in_network_emergecy_room_copay"
            />
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={this.props.medicalData.in_network_ded_applies_to_oop}
                label="Ded Applies to OOP"
                data-test-id="in_network_ded_applies_to_oop"
                onChange={e =>
                  this.props.handleInputItemChange(
                    e.target.checked,
                    "medical",
                    "in_network_ded_applies_to_oop"
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={this.props.medicalData.in_network_copay_applies_to_oop}
                label="Copay Applies to OOP"
                data-test-id="in_network_copay_applies_to_oop"
                onChange={e =>
                  this.props.handleInputItemChange(
                    e.target.checked,
                    "medical",
                    "in_network_copay_applies_to_oop"
                  )
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} item xs={12} style={{ marginTop: 5 }}>
          <Grid item xs={12} sm={12}>
            <Typography style={webStyle.title}>Out Network</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="Single Deductible"
              value={this.props.medicalData.out_network_single_deductible}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "out_network_single_deductible"
                )
              }
              data-test-id="out_network_single_deductible"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="Family Deductible"
              value={this.props.medicalData.out_network_family_deductible}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "out_network_family_deductible"
                )
              }
              data-test-id="out_network_family_deductible"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="Single Out of Pocket"
              value={this.props.medicalData.out_network_single_out_of_pocket}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "out_network_single_out_of_pocket"
                )
              }
              data-test-id="out_network_single_out_of_pocket"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="Family Out of Pocket"
              value={this.props.medicalData.out_network_family_out_of_pocket}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "out_network_family_out_of_pocket"
                )
              }
              data-test-id="out_network_family_out_of_pocket"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="Single Coinsurance"
              value={this.props.medicalData.out_network_single_coinsurance}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "out_network_single_coinsurance"
                )
              }
              data-test-id="out_network_single_coinsurance"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="Family Coinsurance"
              value={this.props.medicalData.out_network_family_coinsurance}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "out_network_family_coinsurance"
                )
              }
              data-test-id="out_network_family_coinsurance"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="PCP Copay"
              value={this.props.medicalData.out_network_pcp_copay}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "out_network_pcp_copay"
                )
              }
              data-test-id="out_network_pcp_copay"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="Specialist Copay"
              value={this.props.medicalData.out_network_specialist_copay}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "out_network_specialist_copay"
                )
              }
              data-test-id="out_network_specialist_copay"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="Urgent Care Copay"
              value={this.props.medicalData.out_network_urgent_care_copay}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "out_network_urgent_care_copay"
                )
              }
              data-test-id="out_network_urgent_care_copay"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="ER Visit Copay"
              value={this.props.medicalData.out_network_er_visit_copay}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "out_network_er_visit_copay"
                )
              }
              data-test-id="out_network_er_visit_copay"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="In Patient Copay"
              value={this.props.medicalData.out_network_in_patient_copay}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "out_network_in_patient_copay"
                )
              }
              data-test-id="out_network_in_patient_copay"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="Lab Copay"
              value={this.props.medicalData.out_network_lab_copay}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "out_network_lab_copay"
                )
              }
              data-test-id="out_network_lab_copay"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <GenericTextField
              label="Emergecy Room Copay"
              value={this.props.medicalData.out_network_emergecy_room_copay}
              onChange={e =>
                this.props.handleInputItemChange(
                  e.target.value,
                  "medical",
                  "out_network_emergecy_room_copay"
                )
              }
              data-test-id="out_network_emergecy_room_copay"
            />
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={this.props.medicalData.out_network_ded_applies_to_oop}
                label="Ded Applies to OOP"
                data-test-id="out_network_ded_applies_to_oop"
                onChange={e =>
                  this.props.handleInputItemChange(
                    e.target.checked,
                    "medical",
                    "out_network_ded_applies_to_oop"
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={
                  this.props.medicalData.out_network_copay_applies_to_oop
                }
                label="Copay Applies to OOP"
                data-test-id="out_network_copay_applies_to_oop"
                onChange={e =>
                  this.props.handleInputItemChange(
                    e.target.checked,
                    "medical",
                    "out_network_copay_applies_to_oop"
                  )
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const webStyle: any = {
  title: {
    fontFamily: "Montserrat",
    letterSpacing: 0,
    fontSize: 24,
    fontWeight: 600,
    color: "#4166F8",
    borderBottom: "2px solid #4166F8",
    width: "max-content"
  }
};

export default EmployerAddPlanMedical;
