import React from "react";
import { ClientAddressModel, ClientBasicDetailsModel, ClientModel } from './ClientModel'
import { StepList } from "../../../../components/src/CustomVerticalFormStepper.web";
import { IDropdownModel } from "../ClientEmployerDivision/ClientEmployerDivisionModel";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";

export const configJSON = require("./../config");

export interface Props {
  isOpen: boolean;
  handleClose: any;
  clientData: ClientModel
}

interface S {
  editSuccess: boolean;
  isUpdateSuccess: boolean;
  editActiveStep: number;

  isOpenWarningDialog: boolean;
  editClientStepList: StepList[];
  clientDetail: ClientModel;
  fieldErrors: { [key: string]: string[] };
  generalError: string;

  groupList: IDropdownModel[];
  countryList: IDropdownModel[];
  statePhysicalList: IDropdownModel[];
  stateMailingList: IDropdownModel[];

  isMailingAddressSameAsPhysicalAddress: boolean;
}

interface SS { }

type InputType = HTMLInputElement | HTMLTextAreaElement | { value: unknown };

export default class ClientEditDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  token: string = "";
  clien_name: string = "";
  currentStateType: string = "";
  filledInitData: boolean = false;

  getCountryCallId: string = "";
  getStateCallId: string = "";
  requestUpdateClientCallId: string = ""
  requestPhysicalStateCallId: string = ""
  requestMailingStateCallId: string = ""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      editSuccess: false,
      isUpdateSuccess: false,
      editActiveStep: 0,
      editClientStepList: [
        {
          key: 1,
          title: "Client Basic Details",
          description: "Type and Select details",
          isCompleted: false,
        },
        {
          key: 2,
          title: "Address & Contact",
          description: "Type and Select details",
          isCompleted: false,
        },
        {
          key: 3,
          title: "Upload Logo / Supporting Document",
          description: "Type and Select details",
          isCompleted: false,
        },
      ],
      isOpenWarningDialog: false,
      clientDetail: this.props.clientData,
      fieldErrors: {},
      generalError: "",

      groupList: [],
      countryList: [],
      statePhysicalList: [],
      stateMailingList: [],

      isMailingAddressSameAsPhysicalAddress: false
    };
  }

  async componentDidMount() {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const response = JSON.parse(loggedInUser);
      this.token = response.token;
    }
    this.getCountryList()
  }

  handleSteps = (next: boolean) => {
    const activeStep = next ? this.state.editActiveStep + 1 : this.state.editActiveStep - 1;
    const length = this.state.editClientStepList.length;
    if (length > activeStep) {
      this.setState({
        editActiveStep: activeStep,
        editClientStepList: this.state.editClientStepList.map(
          (list, index) => {
            if (index === this.state.editActiveStep) {
              return {
                ...list,
                isCompleted: true,
              };
            }
            return list;
          }
        ),
      });
    }
  }

  removeProperty = (propKey: keyof ClientBasicDetailsModel | keyof ClientAddressModel, { [propKey]: propValue, ...rest }) => rest

  handleAddressChange = (
    e: React.ChangeEvent<InputType>,
    param: string
  ) => {
    const eventValue = e.target.value;

    let fieldName = ""
    if (param.includes("country")) {
      this.currentStateType = param
      this.getStateList(eventValue + "")

      switch (param) {
        case 'physical_country_id':
          fieldName = 'physical_state_id'
          break;
        case 'mailing_country_id':
          fieldName = 'mailing_state_id';
          break;
      }
      this.setState({
        clientDetail: {
          ...this.state.clientDetail,
          clientAddress: {
            ...this.state.clientDetail.clientAddress,
          }
        }
      })
    }
    if (fieldName) {
      this.setState({
        ...this.state,
        clientDetail: {
          ...this.state.clientDetail,
          clientAddress: {
            ...this.state.clientDetail.clientAddress,
            [param]: eventValue,
            [fieldName]: -1
          },
        },
      });
    } else {
      this.setState({
        ...this.state,
        clientDetail: {
          ...this.state.clientDetail,
          clientAddress: {
            ...this.state.clientDetail.clientAddress,
            [param]: eventValue,
          },
        },
      });
    }
  }

  handlContactChange = (
    e: React.ChangeEvent<InputType>,
    param: any
  ) => {
    const regexEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/

    const eventValue = e.target.value;

    if (["client_email1", "client_email2"].includes(param)) {
      const val: any = eventValue

      const isValid = regexEmail.test(val)

      if (isValid || !eventValue) {
        this.setState({
          fieldErrors: this.removeProperty(param, this.state.fieldErrors)
        })
      } else {
        this.setState({
          fieldErrors: {
            ...this.state.fieldErrors,
            [param]: ["Email invalid"]
          }
        })
      }
    }

    this.setState({
      clientDetail: {
        ...this.state.clientDetail,
        clientAddress: {
          ...this.state.clientDetail.clientAddress,
          [param]: eventValue,
        },
      },
    });
  };

  handleCheckBox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    const {
      physical_address_line1,
      physical_address_line2,
      physical_city,
      physical_country_id,
      physical_state_id,
      physical_zip_code,
    } = this.state.clientDetail.clientAddress;

    const {
      statePhysicalList,
    } = this.state

    this.setState({
      isMailingAddressSameAsPhysicalAddress: isChecked,
      stateMailingList: isChecked ? statePhysicalList : [],
      clientDetail: {
        ...this.state.clientDetail,
        clientAddress: {
          ...this.state.clientDetail.clientAddress,
          mailing_address_line1: isChecked ? physical_address_line1 : '',
          mailing_address_line2: isChecked ? physical_address_line2 : '',
          mailing_city: isChecked ? physical_city : '',
          mailing_country_id: isChecked ? physical_country_id : '',
          mailing_state_id: isChecked ? physical_state_id : '',
          mailing_zip_code: isChecked ? physical_zip_code : '',
        },
      },
    });
  };

  getErrorMessage = (fieldName: string) => {
    const { fieldErrors } = this.state;
    if (fieldErrors[fieldName]) {
      return fieldErrors[fieldName][0];
    }
    return "";
  };

  handleNext = (mustCheckStep: boolean) => {
    if (mustCheckStep && this.state.editActiveStep === 2) {
      return this.setState({
        isUpdateSuccess: true
      })
    }
    this.handleSteps(true);
  };

  saveAndNext = () => {
    this.handleNext(false);
  }

  isEmpty = (object: any) => {
    for (let key in object) {
      if (object.hasOwnProperty(key)) {
        return false
      }
    }
    return true
  }

  checkErrors = () => {
    return new Promise((resolve) => {

      if (!this.isEmpty(this.state.fieldErrors) || this.state.generalError !== "") {
        return resolve(true)
      }

      resolve(false)
    })
  }

  removeErrors = () => {
    return new Promise((resolve) => {
      this.setState({
        generalError: ''
      }, () => resolve(true))
    })
  }

  saveAndFinish = async () => {
    await this.removeErrors()
    const stillError = await this.checkErrors()

    if (stillError) {
      return;
    }

    this.updateClientAPI()
  }

  createBodyFunc = () => {
    const { countryList, stateMailingList, statePhysicalList } = this.state
    const { clientAddress, clientBasicDetails } = this.state.clientDetail
    const {
      physical_state_id,
      physical_country_id,
      mailing_state_id,
      mailing_country_id,
    } = clientAddress

    const mailingCountryName: string = countryList.find((item: IDropdownModel) => (item.id + "") === mailing_country_id)?.name || ""

    const physicalCountryName: string = countryList.find((item: IDropdownModel) => (item.id + "") === physical_country_id)?.name || ""

    const physicalStateName: string = statePhysicalList.find((item: IDropdownModel) => (item.id + "") === physical_state_id)?.name || ""

    const mailingStateName: string = stateMailingList.find((item: IDropdownModel) => (item.id + "") === mailing_state_id)?.name || ""

    const body: any = {
      account: {
        first_name: clientBasicDetails.first_name,
        last_name: clientBasicDetails.last_name,
        mailing_address_attributes: {
          address_line_1: clientAddress.mailing_address_line1,
          address_line_2: clientAddress.mailing_address_line2,
          city: clientAddress.mailing_city,
          zip_code: clientAddress.mailing_zip_code,
          state: mailingStateName,
          country: mailingCountryName
        },
        physical_address_attributes: {
          address_line_1: clientAddress.physical_address_line1,
          address_line_2: clientAddress.physical_address_line2,
          city: clientAddress.physical_city,
          zip_code: clientAddress.physical_zip_code,
          state: physicalStateName,
          country: physicalCountryName,
        },
        contacts_attributes: [
          {
            contact: clientAddress.client_contact1,
            email: clientAddress.client_email1,
            phone_number: clientAddress.client_phone1,
            fax: clientAddress.client_fax1,
          },
          {
            contact: clientAddress.client_contact2,
            email: clientAddress.client_email2,
            phone_number: clientAddress.client_phone2,
            fax: clientAddress.client_fax2,
          }
        ],
      }
    }

    return body
  }

  updateClientAPI = () => {
    this.setState({
      generalError: "",
      fieldErrors: {}
    });

    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.requestUpdateClientCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getClientInfo}${this.state.clientDetail.clientBasicDetails.client_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.createBodyFunc())
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCloseWarningDialog = (isAgreeToLooseData: boolean) => {
    if (isAgreeToLooseData) {
      this.props.handleClose(this.state.clientDetail);
    }
    this.setState({ ...this.state, isOpenWarningDialog: false });
  };

  handleDivisionDialogClose = () => {
    this.setState({ ...this.state, isOpenWarningDialog: true });
  }

  handleSuccessDialogClose = () => {
    this.setInitialState()
    this.props.handleClose(this.state.clientDetail);
  }

  setInitialState = () => {
    this.setState({
      ...this.state,
      editSuccess: false,
      isUpdateSuccess: false,
      editActiveStep: 0,
      isOpenWarningDialog: false,
      clientDetail: this.props.clientData,
      fieldErrors: {},
      generalError: "",

      groupList: [],
      countryList: [],
      statePhysicalList: [],
      stateMailingList: [],

      isMailingAddressSameAsPhysicalAddress: false
    });
  };

  setActiveStep = (step: number) => {
    this.setState({ editActiveStep: step });
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    switch (apiRequestCallId) {
      case this.getCountryCallId:
        this.receiveCountryList(message);
        break;
      case this.getStateCallId:
        this.receiveStateList(message);
        break;
      case this.requestUpdateClientCallId:
        this.receiveUpdateInfo(message)
        break;
      case this.requestPhysicalStateCallId:
        this.receiveSpecifyStateList(message, 'physical_country_id')
        break;
      case this.requestMailingStateCallId:
        this.receiveSpecifyStateList(message, 'mailing_country_id')
        break;
    }
  }


  getCountryList = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCountryCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllCountryAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStateList = (idCountry: string, typeState?: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    switch (typeState) {
      case 'physical_country_id':
        this.requestPhysicalStateCallId = requestMessage.messageId;
        break;
      case 'mailing_country_id':
        this.requestMailingStateCallId = requestMessage.messageId;
        break;
      default:
        this.getStateCallId = requestMessage.messageId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStateFromCountryAPiEndPoint}${idCountry}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  receiveSpecifyStateList = (message: Message, typeState?: string) => {
    const { clientDetail } = this.state
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const array = responseJson.states.map((item: any) => ({ id: item.StateCode, name: item.StateName }))

    let type: string = ""
    let value: string = ""
    let param: string = ""
    switch (typeState) {
      case 'physical_country_id':
        type = 'statePhysicalList'
        value = array.find((item: IDropdownModel) => item.name == clientDetail.clientAddress.physical_state_id)?.id
        param = 'physical_state_id'
        break;
      case 'mailing_country_id':
        type = 'stateMailingList'
        value = array.find((item: IDropdownModel) => item.name == clientDetail.clientAddress.mailing_state_id)?.id
        param = 'mailing_state_id'
        break;
    }

    if (responseJson && responseJson.states && responseJson.states.length > 0) {
      this.setState({
        ...this.state,
        [type]: array,
        clientDetail: {
          ...clientDetail,
          clientAddress: {
            ...clientDetail.clientAddress,
            [param]: value
          }
        }
      });
    } else {
      this.setState({ ...this.state, [type]: [] });
    }
  };

  receiveCountryList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.countries && responseJson.countries.length > 0) {
      this.setState({ countryList: responseJson.countries.map((item: any) => ({ id: item.countryCode, name: item.CountryName })) },
        () => this.updateField());
    }
  };

  receiveStateList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let type: string = ""

    switch (this.currentStateType) {
      case 'physical_country_id':
        type = 'statePhysicalList'
        break;
      case 'mailing_country_id':
        type = 'stateMailingList'
        break;
    }

    if (responseJson && responseJson.states && responseJson.states.length > 0 && type) {
      this.setState({ ...this.state, [type]: responseJson.states.map((item: any) => ({ id: item.StateCode, name: item.StateName })) });
    }
  };

  receiveUpdateInfo = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson.data) {
      return this.handleNext(true)
    }
    return this.handleError(responseJson)
  }

  updateField = () => {
    const { clientDetail } = this.state
    const { countryList } = this.state
    if (this.filledInitData) {
      return;
    }
    this.filledInitData = true;
    let physical_country_id: string = clientDetail.clientAddress.physical_country_id
    let mailing_country_id: string = clientDetail.clientAddress.mailing_country_id

    if (physical_country_id) {
      physical_country_id = countryList.find(item => item.name === physical_country_id)?.id || ''
      this.getStateList(physical_country_id, 'physical_country_id')
    }
    if (mailing_country_id) {
      mailing_country_id = countryList.find(item => item.name === mailing_country_id)?.id || ''
      this.getStateList(mailing_country_id, 'mailing_country_id')
    }

    this.setState({
      clientDetail: {
        ...clientDetail,
        clientAddress: {
          ...clientDetail.clientAddress,
          physical_country_id,
          mailing_country_id,
        }
      }
    })
  }

  handleError = (res: any) => {
    if (res.errors) {
      if (res.errors && Array.isArray(res.errors)) {
        if (typeof (res.errors[0]) === 'string') {
          return this.setState({
            generalError: res.errors[0],
          });
        }

        if (res.errors[0]?.token) {
          return this.setState({
            generalError: res.errors[0].token,
          });
        }
      }

      if (typeof res.errors === 'object') {
        return this.setState({
          fieldErrors: res.errors,
        });
      }
    }
  }
}