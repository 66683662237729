import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { StepList } from "../../../../components/src/CustomVerticalFormStepper.web";
import {
  initialplanInfoModelData,
  IPlanDetailModel,
  initialMedicalModelData
} from "./ClientPlanModel";
import { ClassData, ClassItem, SelectionList } from "../ClientMember/ClientMemberModel";
import { Message } from "../../../../framework/src/Message";
import { DivisionApiResponse, GroupApiResponse } from "./ClientEmployerModel";
import { toast } from "react-toastify";

export const configJSON = require("../config");

export interface Props {
  isOpen: boolean;
  handleClose: any;
  planData: IPlanDetailModel["planData"];
  handleSaveAndFinish: (planData: IPlanDetailModel["planData"]) => void;
  clientDetails: { client_name: string, client_id: string, accountId: string };
  employerList: SelectionList[];
  token: string;
}

interface S {
  planActiveStep: number;
  planStepList: StepList[];
  planData: IPlanDetailModel["planData"];
  warningDialog: boolean;
  employerList: SelectionList[];
  groupList: SelectionList[];
  divisionList: SelectionList[];
  classList: SelectionList[];
  locationList: SelectionList[];
  stopLossList: SelectionList[];
  ApiErrors: { [key: string]: string[] };
}

interface SS {
  id: any;
}

export default class ClientEmployerAddPlanDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  fetchGroupListId: string = "";
  fetchDivisionListid: string = "";
  fetchClassListid: string = "";
  locationListAPIId: string = "";
  createPlanInfoPostId: string = "";
  createPlanMedicalId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      planActiveStep: 0,
      planStepList: [
        {
          key: 1,
          title: "Plan Info",
          description: "Type and Selet details",
          isCompleted: false
        },
        {
          key: 2,
          title: "Medical",
          description: "Type and Selet details",
          isCompleted: false
        },
        {
          key: 3,
          title: "Dental",
          description: "Type and Selet details",
          isCompleted: false
        },
        {
          key: 4,
          title: "Vision",
          description: "Type and Selet details",
          isCompleted: false
        },
        {
          key: 5,
          title: "RX",
          description: "Type and Selet details",
          isCompleted: false
        },
        {
          key: 6,
          title: "Group Life",
          description: "Type and Selet details",
          isCompleted: false
        },
        {
          key: 7,
          title: "FSA",
          description: "Type and Selet details",
          isCompleted: false
        },
        {
          key: 8,
          title: "HRA",
          description: "Type and Selet details",
          isCompleted: false
        },
        {
          key: 9,
          title: "HSA",
          description: "Type and Selet details",
          isCompleted: false
        },
        {
          key: 10,
          title: "401K",
          description: "Type and Selet details",
          isCompleted: false
        },
        {
          key: 11,
          title: "STD",
          description: "Type and Selet details",
          isCompleted: false
        },
        {
          key: 12,
          title: "LTD",
          description: "Type and Selet details",
          isCompleted: false
        },
        {
          key: 13,
          title: "Vol Life",
          description: "Type and Selet details",
          isCompleted: false
        },
        {
          key: 14,
          title: "Critical illness/ Hospital",
          description: "Type and Selet details",
          isCompleted: false
        },
        {
          key: 15,
          title: "Accident",
          description: "Type and Selet details",
          isCompleted: false
        },
        {
          key: 16,
          title: "Cancer",
          description: "Type and Selet details",
          isCompleted: false
        },
        {
          key: 17,
          title: "Additional",
          description: "Type and Selet details",
          isCompleted: false
        },
        {
          key: 18,
          title: "Cost Containments",
          description: "Type and Selet details",
          isCompleted: false
        },
        {
          key: 19,
          title: "Check Info",
          description: "Type and Selet details",
          isCompleted: false
        },
        {
          key: 20,
          title: "PPO info",
          description: "Type and Selet details",
          isCompleted: false
        },
        {
          key: 21,
          title: "Proc. Limit",
          description: "Type and Selet details",
          isCompleted: false
        },
        {
          key: 22,
          title: "Save Plan",
          description: "Type and Selet details",
          isCompleted: false
        }
      ],
      planData: {
        planInfo: initialplanInfoModelData,
        medical: initialMedicalModelData
      },
      warningDialog: false,
      employerList: [{ name: "Employer", id: 1 }],
      groupList: [{ name: "Group", id: 1 }],
      divisionList: [{ name: "Division", id: 1 }],
      classList: [{ name: "Class", id: 1 }],
      locationList: [{ name: "Location", id: 1 }],
      stopLossList: [{ name: "Stop", id: 1 }],
      ApiErrors: {}
    };
  }

  async componentDidMount() {
    this.setState({
      employerList: this.props.employerList,
      planData: {
        ...this.state.planData,
        planInfo: {
          ...this.state.planData.planInfo,
          client_name: this.props.clientDetails.client_name
        }
      }
    });
    this.fetchLocations(this.props.token);

  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.fetchGroupListId:
        this.handleResponseGroupData(responseJson)
        break;
      case this.fetchDivisionListid:
        this.handleDivisionData(responseJson)
        break;
      case this.fetchClassListid:
        this.handleClassData(responseJson)
        break;
      case this.locationListAPIId:
        this.handleResponseLocationData(responseJson)
        break;
      case this.createPlanInfoPostId:
        this.handleResponseCreatePlanInfo(responseJson)
        break;
      case this.createPlanMedicalId:
        this.handleResponseCreateMedical(responseJson)
        break;
      default:
        break;
    }
  }

  handleResponseCreateMedical = (response: { [key: string]: string[]; }) => {
    if (response.data) {
      this.props.handleSaveAndFinish(this.state.planData);
      this.handleNext();
    } else if (response.error) {
      toast.error(response.error);
    } else if (response instanceof Object) {
      this.setState({ ApiErrors: { ...this.state.ApiErrors, ...response } })
    }
  }

  handleResponseCreatePlanInfo = (response: { [key: string]: string[]; }) => {
    if (response.data) {
      this.props.handleSaveAndFinish(this.state.planData);
      this.handleNext();
    } else if (response.error) {
      toast.error(response.error);
    } else if (response instanceof Object) {
      this.setState({ ApiErrors: { ...this.state.ApiErrors, ...response } })
    }
  }

  handleResponseGroupData = (responseJson: GroupApiResponse) => {
    const groupApisuccess = responseJson.data ? responseJson.data.length : 0;
    if (groupApisuccess) {
      const GroupData = responseJson.data.map(
        (i: {
          attributes: { group_id: string; group_name: string };
          id: string;
        }) => ({
          id: i.id,
          empId: i.attributes.group_id,
          name: i.attributes.group_name
        })
      );
      this.setState({ groupList: GroupData });
    }
  };

  handleDivisionData = (responseJson: DivisionApiResponse) => {
    const divisionApiSuccessfull = responseJson.data ? responseJson.data.length : 0;
    if (divisionApiSuccessfull) {
      const divisionData = responseJson.data.map(
        (i: {
          attributes: { division_id: string; division_name: string };
          id: string;
        }) => ({
          id: i.id,
          empId: i.attributes.division_id,
          name: i.attributes.division_name
        })
      );
      this.setState({ divisionList: divisionData });
    }
  }

  handleClassData = (responseJson: ClassData) => {
    const classApisuccessfull = responseJson.data ? responseJson.data.length : 0;
    if (classApisuccessfull) {
      const classData = responseJson.data.map((i: ClassItem) => ({
        id: i.id,
        empId: i.attributes.class_id,
        name: i.attributes.class_name
      }));
      this.setState({ classList: classData });
    }
  }

  handleResponseLocationData = (response: { location_name: string, id: string }[]) => {
    if (response.length) {
      const locationData = response.map((i: { location_name: string, id: string }) => ({ id: i.id, name: i.location_name }));
      this.setState({ locationList: locationData })
    }
  }

  setActiveStep = (step: number) => {
    this.setState({ planActiveStep: step });
  };

  handleNext = (isFinish?: boolean) => {
    if (isFinish) {
      this.props.handleClose();
    } else {
      this.handleSteps(true);
    }
  };

  handleSteps = (next: boolean) => {
    const activeStep = next
      ? this.state.planActiveStep + 1
      : this.state.planActiveStep - 1;
    const length = this.state.planStepList.length;
    if (length > activeStep) {
      this.setState({
        planActiveStep: activeStep,
        planStepList: this.state.planStepList.map((list, index) => {
          if (index === this.state.planActiveStep) {
            return {
              ...list,
              isCompleted: true
            };
          }
          return list;
        })
      });
    }
  };

  handleFileUpload = (
    files: FileList | null,
    group: keyof IPlanDetailModel["planData"],
    param: string
  ) => {
    console.log("enrollment_document",files?files[0]:"",group,param)
    if (files) {
      this.setState({
        ...this.state,
        planData: {
          ...this.state.planData,
          [group]: {
            ...this.state.planData[group],
            [param]: files[0]
          }
        }
      });
    }
  };

  handleInputItemChange = (
    inputValue: string | number | boolean,
    group: keyof IPlanDetailModel["planData"],
    param: string
  ) => {
    let value = inputValue;
    const { effective_date, termination_date } = this.state.planData.medical;
    const stateEff = new Date(effective_date);
    const stateTer = new Date(termination_date);
    if (param === "effective_date" && termination_date && typeof value === 'string') {
      const diffDays = stateTer.getDate() - new Date(value).getDate();
      value = this.handleTermDateDiff(diffDays, effective_date, value);
    } else if (param === "termination_date" && effective_date && typeof value === 'string') {
      const diffDays = new Date(value).getDate() - stateEff.getDate();
      value = this.handleTermDateDiff(diffDays, termination_date, value);
    }
    if (param === "employer_name" && typeof inputValue === "string") {
      this.fetchGroupList(inputValue);
      this.fetchDivisionList(inputValue);
      this.fetchClassList(inputValue);
    }
    this.setState({
      ...this.state,
      planData: {
        ...this.state.planData,
        [group]: {
          ...this.state.planData[group],
          [param]: value
        }
      }
    });
  };

  fetchGroupList = (id: string) => {
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.props.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchGroupListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createGroupAPIEndPoint}Emp${id}/groups`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchDivisionList = (id: string) => {
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.props.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchDivisionListid = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.divisionsListGetApiEndPoint}?employer_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchClassList = (id: string) => {
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.props.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchClassListid = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createGroupAPIEndPoint}/${id}/class_basic_details`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchLocations = (token: string) => {
    const header = {
      "Content-Type": configJSON.ContentType,
      "token": token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.locationListAPIId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getLocationsAPiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCloseConfirm = () => {
    if (
      JSON.stringify(this.props.planData) !==
      JSON.stringify(this.state.planData)
    ) {
      this.setState({ warningDialog: true });
    } else {
      this.props.handleClose();
    }
  };

  handleWarningClose = (isOk: boolean) => {
    if (isOk) {
      this.props.handleClose();
    } else {
      this.setState({ warningDialog: false });
    }
  };

  saveAndNext = () => {
    this.handleNext();
  };

  saveAndFinish = () => {
    const acStep = this.state.planActiveStep;
    const dataPlan = this.state.planData;
    if (acStep === 0) {
      this.handlePlanInfoSubmit(dataPlan);
    } else if (acStep === 1) {
      this.handleMedicalSubmit(dataPlan);
    } else if (acStep === 21) {
      this.props.handleSaveAndFinish(this.state.planData);
      this.handleNext(true);
    } else {
      this.props.handleSaveAndFinish(this.state.planData);
      this.handleNext();
    }
  };

  handlePlanInfoSubmit = (plan: IPlanDetailModel["planData"]) => {
    let errors = {};
    const { employer_name, plan_name, plan_description, is_enrollment_plan, group_name, division_name, location_name, class_name, plan_documents, stop_loss_carrier } = plan.planInfo;
    if (!employer_name) {
      errors = { ...errors, "employer": ["Require field"] }
    }
    if (!plan_name) {
      errors = { ...errors, "name": ["Require field"] }
    }
    this.setState({ ApiErrors: errors });
    if (Object.keys(errors).length) {
      return
    }
    let formData = new FormData();
    formData.append('plan[name]', plan_name);
    formData.append('plan[account_id]', this.props.clientDetails.accountId);
    formData.append('plan[edi_plan_no]', this.props.clientDetails.accountId);
    formData.append('plan[description]', plan_description);
    formData.append('plan[is_enrollment_plan]', is_enrollment_plan.toString());
    formData.append('plan[employer_id]', employer_name);
    formData.append('plan[group_id]', group_name);
    formData.append('plan[division_id]', division_name);
    formData.append('plan[location_id]', location_name);
    formData.append('plan[class_basic_details_id]', class_name);
    formData.append('plan[summary_plan_document]', plan_documents);
    formData.append('plan[stop_loss_carrier_id]', stop_loss_carrier);
    this.createPlanPlanInfoStep(formData);
  };

  createPlanPlanInfoStep = (data: FormData) => {
    const header = {
      token: this.props.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createPlanInfoPostId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.createNewPlanPlanInfoStep}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), data);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleMedicalSubmit = (plan: IPlanDetailModel["planData"]) => {
    let errors = {};
    const { effective_date, termination_date, ee_child_cost, ee_cost, ee_family_cost, ee_more_than_one_child_cost, doller_costlimit, ee_spouse_cost, er_child_cost, er_cost, er_family_cost, er_more_than_one_child_cost, er_spouse_cost, in_network_copay_applies_to_oop, in_network_ded_applies_to_oop, in_network_emergecy_room_copay, in_network_er_visit_copay, in_network_family_coinsurance, in_network_family_deductible, in_network_family_out_of_pocket, in_network_pcp_copay, in_network_single_coinsurance, in_network_single_deductible, in_network_single_out_of_pocket, in_network_specialist_copay, in_network_urgent_care_copay, out_network_copay_applies_to_oop, out_network_ded_applies_to_oop, out_network_emergecy_room_copay, out_network_er_visit_copay, out_network_family_coinsurance, out_network_family_deductible, out_network_family_out_of_pocket, out_network_in_patient_copay, out_network_lab_copay, out_network_pcp_copay, out_network_single_coinsurance, out_network_single_deductible, out_network_single_out_of_pocket, out_network_specialist_copay, out_network_urgent_care_copay,benefit_documents,enrollment_document } = plan.medical;
    if (!plan.medical.effective_date) {
      errors = { ...errors, "effective_date": ["Require field"] }
    }
    if (!plan.medical.termination_date) {
      errors = { ...errors, "termination_date": ["Require field"] }
    }
    this.setState({ ApiErrors: errors });
    if (Object.keys(errors).length) {
      return
    }
    console.log(plan.medical, "plan.medical")
    let formData = new FormData();
    formData.append('medical_plan[effective_date]', effective_date);
    formData.append('medical_plan[termination_date]', termination_date);
    formData.append('medical_plan[plan_year_dollar_limit]', doller_costlimit);
    formData.append('medical_plan[ee_cost]', ee_cost);
    formData.append('medical_plan[ee_spouse_cost]', ee_spouse_cost);
    formData.append('medical_plan[ee_child_cost]', ee_child_cost);
    formData.append('medical_plan[ee_more_than_one_child_cost]', ee_more_than_one_child_cost);
    formData.append('medical_plan[ee_family_cost]', ee_family_cost);
    formData.append('medical_plan[er_cost]', er_cost);
    formData.append('medical_plan[er_spouse_cost]', er_spouse_cost);
    formData.append('medical_plan[er_child_cost]', er_child_cost);
    formData.append('medical_plan[er_more_than_one_child_cost]', er_more_than_one_child_cost);
    formData.append('medical_plan[er_family_cost]', er_family_cost);
    formData.append('medical_plan[in_single_deductible]', in_network_single_deductible);
    formData.append('medical_plan[in_family_deductible]', in_network_family_deductible);
    formData.append('medical_plan[in_single_out_of_pocket]', in_network_single_out_of_pocket);
    formData.append('medical_plan[in_family_out_of_pocket]', in_network_family_out_of_pocket);
    formData.append('medical_plan[in_single_coinsurance]', in_network_single_coinsurance);
    formData.append('medical_plan[in_family_coinsurance]', in_network_family_coinsurance);
    formData.append('medical_plan[in_pcp_copay]', in_network_pcp_copay);
    formData.append('medical_plan[in_specialist_copay]', in_network_specialist_copay);
    formData.append('medical_plan[in_urget_care_copay]', in_network_urgent_care_copay);
    formData.append('medical_plan[in_er_visit_copa]', in_network_er_visit_copay);
    formData.append('medical_plan[in_emergency_room_copay]', in_network_emergecy_room_copay);
    formData.append('medical_plan[in_ded_applies_to_oop]', in_network_ded_applies_to_oop.toString());
    formData.append('medical_plan[in_copay_applies_to_oop]', in_network_copay_applies_to_oop.toString());
    formData.append('medical_plan[out_single_deductible]', out_network_single_deductible);
    formData.append('medical_plan[out_family_deductible]', out_network_family_deductible);
    formData.append('medical_plan[out_single_out_of_pocket]', out_network_single_out_of_pocket);
    formData.append('medical_plan[out_family_out_of_pocket]', out_network_family_out_of_pocket);
    formData.append('medical_plan[out_single_coinsurance]', out_network_single_coinsurance);
    formData.append('medical_plan[out_family_coinsurance]', out_network_family_coinsurance);
    formData.append('medical_plan[out_pcp_copay]', out_network_pcp_copay);
    formData.append('medical_plan[out_specialist_copay]', out_network_specialist_copay);
    formData.append('medical_plan[out_urget_care_copay]', out_network_urgent_care_copay);
    formData.append('medical_plan[out_er_visit_copay]', out_network_er_visit_copay);
    formData.append('medical_plan[out_in_patient_copay]', out_network_in_patient_copay);
    formData.append('medical_plan[out_lap_copay]', out_network_lab_copay);
    formData.append('medical_plan[out_emergency_room_copay]', out_network_emergecy_room_copay);
    formData.append('medical_plan[out_ded_applies_to_oop]', out_network_ded_applies_to_oop?"1":"0");
    formData.append('medical_plan[out_copay_applies_to_oop]', out_network_copay_applies_to_oop?"1":"0");
    formData.append('medical_plan[plan_id]',"1" );
    enrollment_document && formData.append('medical_plan[enrollment_document]', enrollment_document);
    benefit_documents && formData.append('medical_plan[schedule_of_benifit_document]', benefit_documents);
    this.createPlanMedicalStep(formData);
  };

  createPlanMedicalStep = (data:FormData) =>{
    const header = {
      token: this.props.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createPlanMedicalId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.createPlanMedicalStep}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), data);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleTermDateDiff = (diff: number, date: string, value: string) => {
    return diff < 0 ? date : value;
  };

  handleErrors = (param: string) => {
    const { ApiErrors } = this.state;
    if (ApiErrors[param]) {
      return ApiErrors[param][0];
    }
  }
}
