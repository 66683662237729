Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";

exports.myDetails = "My Details";
exports.myBenefits = "My Benefits";
exports.myClaims = "My Claims";
exports.myDocuments = "My Documents";
exports.myEnrollments = "My Enrollments";
exports.myNotifications = "My Notifications";
exports.findHealthcare = "Find a Healthcare Provider";
exports.findPharmacy = "Find a Pharmacy";
exports.help = "Support/Help";
exports.userName = "Vikas Member";
exports.userEmail = "johnm@dummy.com";
exports.applicationJsonContentType = "application/json";
exports.httpGetMethod = "GET";
exports.getCountryAPiEndPoint = "bx_block_custom_form/country";
exports.getAllCountryAPiEndPoint = "bx_block_custom_form/country/get_all_countries";
exports.getStateAPiEndPoint = "bx_block_custom_form/state"
exports.getStateFromCountryAPiEndPoint = "bx_block_custom_form/country/get_all_states?country_code="

exports.memberFormFields = [

  {
    name: "member_dob",
    label: "Date of Birth",
    required: true,
  },
  {
    name: "member_lastName",
    label: "Last Name",
    required: true,
    lengthLimitation: true,
  },
  {
    name: "member_gender",
    label: "Gender",
    required: true,
  },
  {
    label: "First Name",
    name: "member_firstName",
    required: true,
    lengthLimitation: true,
  },
  {
    name: "member_mobileNumber",
    label: "Mobile Number",
    required: true,
    pattern: new RegExp(/^\d{10}$/),
    error: "Invalid mobile number",
  },
  {
    name: "member_age",
    label: "Age",
    required: true,
  },
  {
    name: "member_memberNumber",
    label: "Member Number",
    required: true,
  },
  {
    name: "member_ssn",
    label: "SSN Name",
    required: true,
  },
  {
    label: "Other Id",
    name: "member_otherId",
    required: true,
  },
  {
    name: "member_otherDesc",
    label: "Other Desc",
    required: true,
  },
  {
    name: "member_email",
    label: "Email",
    error: "Invalid Email Address",
    pattern: new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/),
    required: true,
  },
  {
    name: "member_physicalAddressLine1",
    required: true,
    label: "Address",
  },
  {
    name: "member_physicalCity",
    label: "City",
    required: true,
  },
  {
    label: "State",
    required: true,
    name: "member_physicalState",
  },
  {
    name: "member_physicalZipcode",
    label: "Zipcode",
    required: true,
    pattern: new RegExp(/^\d{5}$/),
    error: "Invalid zipcode",
  },
  {
    name: "member_physicalCountry",
    required: true,
    label: "Country",
  },
  {
    label: "Address",
    required: true,
    name: "member_mailingAddressLine1",
  },
  {
    required: true,
    name: "member_mailingCity",
    label: "City",
  },
  {
    name: "member_mailingState",
    label: "State",
    required: true,
  },
  {
    name: "member_mailingZipcode",
    label: "Zipcode",
    pattern: new RegExp(/^\d{5}$/),
    error: "Invalid zipcode",
    required: true,
  },
  {
    name: "member_mailingCountry",
    label: "Country",
    required: true,
  },

];
exports.countriesList = ["United States", "UK", "India", "France", "Russia", "China"];
exports.statesList = ['Alaska', 'Florida', 'Georgia', 'Hawaii', 'Mississippi', 'Missouri', 'Montana', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'Tennessee', 'Texas', 'Virginia', 'Washington',];
exports.dependentInfoTableColumn = ["First Name", "Last Name", "Relationship", "Physical Address", "DOB", "Gender", "Email Address", " "];
exports.beneficiaryInfoTableColumn = ["First Name", "Last Name", "Relationship", "Physical Address", "DOB", "Contact Number", "Percentage", " "];
exports.dependentFormFields = [
  {
    name: "dependent_firstName",
    label: "First Name",
    required: true,
    lengthLimitation: true,
  },
  {
    required: true,
    name: "dependent_lastName",
    lengthLimitation: true,
    label: "Last Name",
  },
  {
    name: "dependent_dob",
    label: "Date of Birth",
    required: true,
  },

  {
    name: "dependent_physicalCity",
    label: "City",
    required: true,
  },
  {
    name: "dependent_physicalAddressLine1",
    required: true,
    label: "Address",
  },
  {
    name: "dependent_physicalState",
    label: "State",
    required: true,
  },
  {
    label: "Email",
    required: true,
    name: "dependent_email",
    error: "Invalid Email Address",
    pattern: new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/),
  },
  {
    name: "dependent_physicalZipcode",
    label: "Zipcode",
    required: true,
    pattern: new RegExp(/^\d{5}$/),
    error: "Invalid zipcode",
  },
  {
    name: "dependent_physicalCountry",
    required: true,
    label: "Country",
  },
  {
    name: "dependent_mailingAddressLine1",
    label: "Address",
    required: true,
  },
  {
    name: "dependent_ssn",
    label: "SSN",
    required: true,
  },
  {
    required: true,
    name: "dependent_mailingCity",
    label: "City",
  },
  {
    name: "dependent_mailingState",
    label: "State",
    required: true,
  },

  {
    name: "dependent_mailingCountry",
    label: "Country",
    required: true,
  },
  {
    pattern: new RegExp(/^\d{5}$/),
    error: "Invalid zipcode",
    name: "dependent_mailingZipcode",
    label: "Zipcode",
    required: true,
  },
  {
    name: "dependent_gender",
    label: "Gender",
    required: true,
  },
  {
    name: "dependent_contactNumber",
    label: "Contact Number",
    required: true,
    pattern: new RegExp(/^\d{10}$/),
    error: "Invalid contact number",
  },
  {
    name: "dependent_relationship",
    label: "Relationship",
    required: true,
  },
]
exports.beneficiaryFormFields = [
  {
    name: "beneficiary_firstName",
    label: "First Name",
    lengthLimitation: true,
    required: true,
  },
  {
    name: "beneficiary_lastName",
    lengthLimitation: true,
    label: "Last Name",
    required: true,
  },
  {
    name: "beneficiary_dob",
    label: "Date of Birth",
    required: true,
  },
  {
    name: "beneficiary_physicalAddressLine1",
    required: true,
    label: "Address",
  },
  {
    name: "beneficiary_physicalCity",
    label: "City",
    required: true,
  },
  {
    name: "beneficiary_physicalState",
    label: "State",
    required: true,
  },
  {
    name: "beneficiary_email",
    label: "Email",
    required: true,
    pattern: new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/),
    error: "Invalid Email Address",

  },
  {
    name: "beneficiary_physicalZipcode",
    pattern: new RegExp(/^\d{5}$/),
    error: "Invalid zipcode",
    label: "Zipcode",
    required: true,
  },
  {
    name: "beneficiary_physicalCountry",
    required: true,
    label: "Country",
  },
  {
    label: "Address",
    name: "beneficiary_mailingAddressLine1",
    required: true,
  },
  {
    name: "beneficiary_percentage",
    label: "Percentage",
    required: true,
  },
  {
    label: "City",
    required: true,
    name: "beneficiary_mailingCity",
  },
  {
    name: "beneficiary_mailingState",
    label: "State",
    required: true,
  },
  {
    required: true,
    pattern: new RegExp(/^\d{5}$/),
    error: "Invalid zipcode",
    name: "beneficiary_mailingZipcode",
    label: "Zipcode",
  },
  {
    name: "beneficiary_mailingCountry",
    label: "Country",
    required: true,
  },
  {
    name: "beneficiary_gender",
    label: "Gender",
    required: true,
  },
  {
    name: "beneficiary_beneficiary",
    label: "Beneficiary",
    required: true,
  },
  {
    name: "beneficiary_contactNumber",
    label: "Contact Number",
    pattern: new RegExp(/^\d{10}$/),
    error: "Invalid contact number",
    required: true,
  },
  {
    name: "beneficiary_relationship",
    label: "Relationship",
    required: true,
  },

]
exports.editProfileFormFields = [
  {
    name: "member_profile_pic",
    label: "Profile Image",
    required: true,
  },
]

exports.chartData1 = {
  series: [
    {
      name: "Premium",
      group: "series1",
      type: 'column',
      data: [5000,
        20000,
        10000,
        15000,
        30000,
        1000,
        20000,
        5000,
        15000,
        25000],
    },
    {
      name: "Claims",
      type: "line",
      data: [
        5000,
        20000,
        10000,
        15000,
        30000,
        1000,
        20000,
        5000,
        15000,
        25000
      ]
    }
  ],
  options: {
    chart: {
      stacked: false,
      type: "line",
      toolbar: {
        show: false
      }
    },
    stroke: {
      show: true,
      colors: ["transparent", "#3BB964"],
      width: [12, 3]
    },
    colors: ["#895bf1", "#3BB964"],
    markers: {
      colors: "#fff",
      size: 5,
      strokeColors: "#3BB964",
    },
    labels: [
      "Week 1",
      "",
      "Week 2",
      "",
      "Week 3",
      "",
      "Week 4",
      "",
      "Week 5"
    ],
    plotOptions: {
      bar: {
        borderRadius: 12,
        columnWidth: "90%",
        borderRadiusApplication: "end"
      }
    },
    xaxis: {
      type: "category",
      axisBorder: {
        show: false,
      },
      labels: {
        offsetX: 10,
        style: {
          colors: "#B1B6CD",
          fontSize: '14px',
          fontFamily: 'Poppins',
          fontWeight: 400,
        },
      }
    },
    yaxis: {
      show: true,
      labels: {
        style: {
          colors: "#B1B6CD",
          fontSize: '12px',
          fontWeight: 400,
          cssClass: 'apexcharts-yaxis-label',
        },
        formatter: (val) => {
          return val === 0 ? val : (val / 1000 + 'K')
        }
      }
    },
    tooltip: {
      shared: false,
      intersect: true,
      x: {
        show: false
      }
    },
    legend: {
      show: true,
      horizontalAlign: 'center',
      labels: {
        colors: "#B1B6CD",
        useSeriesColors: false
      },
    },
    grid: {
      show: true,
      strokeDashArray: 0,
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        },

      }
    }
  }

}


exports.chartData2 = {
  series: [{
    name: 'Employees',
    data: [44000, 55000, 57000, 56000, 61000, 58000, 0]
  }, {
    name: 'Groups',
    data: [76000, 85000, 101000, 98000, 87000, 105000, 91000]
  }, {
    name: 'Divisions',
    data: [35000, 41000, 36000, 26000, 45000, 48000, 52000]
  },
  {
    name: 'Locations',
    data: [76000, 85000, 101000, 98000, 87000, 105000, 0]
  },
  ],
  options: {
    colors: ["#938EF8", "#FEB760", "#E30A08", "#61DB2C"],
    chart: {
      toolbar: {
        show: false,
      },
      type: 'bar',
      height: 350
    },
    grid: {
      borderColor: 'rgba(217, 225, 231, 0.40)',
    },
    plotOptions: {
      bar: {
        endingShape: 'rounded',
        horizontal: false,
        columnWidth: '60%',
        borderRadiusApplication: 'end',
        borderRadius: 4,
        borderRadiusWhenStacked: 'last',
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 6,
      colors: ['transparent']
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
      labels: {
        style: {
          fontFamily: 'Poppins',
          colors: "#B1B6CD",
          cssClass: 'apexcharts-xaxis-label',
          fontSize: '14px',
          fontWeight: 400,
        },
      }
    },
    yaxis: {
      show: true,
      labels: {
        style: {
          fontSize: '12px',
          colors: "#B1B6CD",
          cssClass: 'apexcharts-yaxis-label',
          fontWeight: 400,
        },
        formatter: (val) => {
          return val === 0 ? val : (val / 1000 + 'K')

        }
      }
    },
    legend: {
      labels: {
        useSeriesColors: false,
        colors: "#B1B6CD",
      },
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      enabled: true,
    }
  },
}


exports.chartData3 = {
  series: [
    {
      name: "Claim Received",
      data: [76000, 85000, 101000, 98000, 87000, 75000]
    }, {
      name: "Claims Processed",
      data: [36000, 45000, 71000, 58000, 57000, 0]
    }
  ],
  options: {
    colors: ["#1B9B93", "#FFB75D"],
    chart: {
      toolbar: {
        show: false,
      },
      type: 'bar',
      height: 350
    },
    grid: {
      borderColor: 'rgba(217, 225, 231, 0.40)',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '40%',
        endingShape: 'rounded',
        borderRadius: 10,
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last',
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 0,
      colors: ['transparent']
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      labels: {
        style: {
          fontWeight: 400,
          colors: "#B1B6CD",
          fontFamily: 'Poppins',
          cssClass: 'apexcharts-xaxis-label',
          fontSize: '14px',
        },
      }
    },
    yaxis: {
      show: true,
      labels: {
        style: {
          fontSize: '12px',
          colors: "#B1B6CD",
          cssClass: 'apexcharts-yaxis-label',
          fontWeight: 400,
        },
        formatter: (val) => {
          return val === 0 ? val : (val / 1000 + 'K')
        }
      }
    },
    legend: {
      horizontalAlign: 'right',
      labels: {
        colors: "#B1B6CD",
        useSeriesColors: false
      },
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      enabled: true,
    }
  },
}

exports.chartData4 = {
  options: {
    colors: ["#4166F8", "#FFCC5D", "#FF6666"],
    borderRadius: 50,
    chart: {
      toolbar: {
        show: false,
      },
      id: "basic-bar"
    },
    grid: {
      borderColor: 'rgba(217, 225, 231, 0.40)',
    },
    plotOptions: {
      bar: {
        columnWidth: '25%',
        horizontal: false,
        borderRadius: "4",

      }
    },
    dataLabels: {
      enabled: false,

    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
      labels: {
        style: {
          fontFamily: 'Poppins',
          colors: "#B1B6CD",
          fontSize: '14px',
          cssClass: 'apexcharts-xaxis-label',
          fontWeight: 400,
        },
      }

    },
    yaxis: {
      show: true,
      labels: {
        style: {
          fontWeight: 400,
          colors: "#B1B6CD",
          cssClass: 'apexcharts-yaxis-label',
          fontSize: '12px',
        },
        formatter: (val) => {
          return val === 0 ? val : (val / 1000 + 'K')

        }
      }
    },
    legend: {
      labels: {
        useSeriesColors: false,
        colors: "#B1B6CD",
      },
    }
  },
  series: [
    {
      name: "Active Member",
      data: [30000, 40000, 45000, 50000, 49000, 60000]
    },
    {
      name: "Ready to Inactive",
      data: [10000, 30000, 35000, 30000, 29000, 50000]
    },
    {
      name: "Inactive Members",
      data: [10000, 30000, 35000, 30000, 29000, 30000]
    }
  ]
}

// Cleint Employer Class
exports.getListClassEndPoint = "bx_block_custom_form/employers/{id}/class_basic_details"
exports.createClassAPIEndPoint = "bx_block_custom_form/employers/{id}/class_basic_details";
exports.createClassAddressAPIEndPoint = "bx_block_custom_form/class_addresses";
exports.createClassOtherDetailAPIEndPoint = "bx_block_custom_form/class_other_details";
exports.classCheckAddress ="Class Check Address"
exports.classAddSuccess="Class added sucessfully"
exports.classCheckSameAsPhysicalAddress = "Class Check address same as physical address"
exports.classCheckSameAsMailingAddress = "Class Check address same as mailing address"
exports.locationAddSuccess = "Location added sucessfully"
exports.locationEditSuccess = "Location edited successfully"
// Client Employer Division
exports.applicationJsonContentType = "application/json";
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.httpDeleteMethod = "DELETE";

exports.divisionsListGetApiEndPoint = "bx_block_custom_form/divisions/get_emp_divisions"
exports.createDivisionAPiEndPoint = "bx_block_custom_form/divisions";
exports.createDivisionAddressEndPoint = "bx_block_custom_form/division_addresses";
exports.createDivisionOtherDetailEndPoint = "bx_block_custom_form/division_other_details"
exports.getGroupAPiEndPoint = "bx_block_custom_form/employers/{id}/groups";
exports.getGroupNewAPiEndPoint = "bx_block_custom_form/employers/{id}/groups/get_all_groups";
exports.getLocationAPiEndPoint = "bx_block_location/locations/get_locations"
exports.getDivisonApiEndPoint = "bx_block_custom_form/divisions/get_emp_divisions"
exports.getCountryAPiEndPoint = "bx_block_custom_form/country";
exports.createEMPAddressEndpoint = "bx_block_custom_form/employers/";
exports.getStateAPiEndPoint = "bx_block_custom_form/state";
exports.getLocationsAPiEndPoint = "bx_block_location/locations/get_locations";
exports.postOtherDetailsAPIEndPoint = "bx_block_custom_form/employer_other_details";
exports.createGeneralErrors = 'There was a validation error'
exports.tokenExpired = "Token has expired"

exports.divisionAddSuccess="Division added sucessfully"
exports.divisionEditSuccess="Division edited sucessfully"
exports.divisionHeading = "Employer: Samantha Gilbert";
exports.division = "Division";
exports.addDivision = "+ Add Division";
exports.searchForDivision = "Search for Division";
exports.addNewDivision = "Add New Division";
exports.editDivision = "Edit Division";
exports.saveAndNext = "Save & Next";
exports.saveAndFinish = "Save & Finish";
exports.employer_id = "Employer ID";
exports.employer_name = "Employer Name";
exports.client_name = "Client Name";
exports.division_id = "Division ID";
exports.division_name = "Division Name";
exports.group_name = "Group Name";
exports.effective_date = "Effective Date"
exports.begin_date = "Begin Date";
exports.termination_date = "Termination Date";
exports.deductible_date = "Deductible Date";
exports.tax_id = "Tax ID";
exports.physicalAddress = "Physical Address";
exports.mailingAddress = "Mailing Address";
exports.divisionCheckAddress = "Division Check Address";
exports.divisionSameAsPhysicalAddress = "Division check address same as physical address"
exports.divisionSameAsMailingAddress = "Division check address same as mailing address"
exports.cost_containment_fee = "Cost Containment Fee";
exports.admin_fee = "Admin Fee";
exports.ppo_fee = "PPO Fee";
exports.cobra_fee = "Cobra Fee";
exports.hipaa_fee = "HIPAA Fee";
exports.agg_premium_fee = "Agg Premium Fee";
exports.fsa_fee = "FSA Fee";
exports.hra_fee = "HRA Fee";
exports.hsa_fee = "HSA Fee";
exports.fee1 = "Fee 1";
exports.fee2 = "Fee 2";
exports.fee3 = "Fee 3";
exports.fee4 = "Fee 4";
exports.med_ee_spec_premium = "Med EE Spec Premium";
exports.den_ee_spec_premium = "Den EE Spec Premium";
exports.vis_ee_spec_premium = "Vis EE Spec Premium";
exports.med_ee_plus_sp_spec_premium = "Med EE + SP Spec Premium";
exports.den_ee_plus_sp_spec_premium = "Den EE + SP Spec Premium";
exports.vis_ee_plus_sp_spec_premium = "Vis EE + SP Spec Premium";
exports.med_ee_plus_ch_spec_premium = "Med EE + CH Spec Premium";
exports.den_ee_plus_ch_spec_premium = "Den EE + CH Spec Premium";
exports.vis_ee_plus_ch_spec_premium = "Vis EE + CH Spec Premium";
exports.med_ee_plus_chs_spec_premium = "Med EE + CHS Spec Premium";
exports.den_ee_plus_chs_spec_premium = "Den EE + CHS Spec Premium";
exports.vis_ee_plus_chs_spec_premium = "Vis EE + CHS Spec Premium";
exports.med_ee_fam_spec_premium = "Med EE FAM Spec Premium";
exports.den_ee_fam_spec_premium = "Den EE FAM Spec Premium";
exports.vis_ee_fam_spec_premium = "Vis EE FAM Spec Premium";
exports.specific_deductible = "Specific Deductible";
exports.aggregate_deductible = "Aggregate Deductible";
exports.surcharge_percentage = "Surcharge %";
exports.is_active = "Is Active";
exports.den_flag = "Den Flag";
exports.med_flag = "Med Flag";
exports.std_flag = "STD Flag";
exports.vis_flag = "Vis Flag";
exports.life_cover_flag = "Life Cover Flag";
exports.ltd_flag = "LTD Flag";
exports.vol_life_flag = "Vol Life Flag";
exports.add_flag = "Add Flag";
exports.fsa_flag = "FSA Flag";
exports.rx_flag = "RX Flag";
exports.hra_flag = "HRA Flag";
exports.cancer_flag = "Cancer Flag";
exports.hsa_flag = "HSA Flag";
exports.medical_question_flag = "Medical Question Flag";

// Client Employer Location
exports.getListLocationEndPoint = "bx_block_location/locations/get_emp_locations"
exports.createUpdateLocationEndpoint="bx_block_location/locations"
exports.locationHeading = "Employer: Samantha Gilbert";
exports.location = "Location";
exports.addLocation = "+ Add Location";
exports.searchForLocation = "Search for Location";
exports.addNewLocation = "Add New Location";
exports.editLocation = "Edit Location";
exports.location_name = "Location name";
exports.location_id = "Location ID";
exports.locationCheckAddress = "Location Check Address";
exports.locationSameAsPhysicalAddress = "Location check address same as physical address"
exports.locationSameAsMailingAddress = "Location check address same as mailing address"
exports.locationAddSuccess="Location added sucessfully"


// Client Member 
exports.memberHeading = "All Members";
exports.member = "Members";
exports.addMember = "+ Add Member";
exports.searchForMember = "Search for Member";
exports.membersListGetApiEndPoint = "bx_block_custom_form/member_details"
exports.getAllMembersListAPIEndPoint = "bx_block_custom_form/member_details/get_member_details"

// Client Group
exports.addNewGroup = "Add New Group";
exports.addNewClass = "Add New Class";
exports.editGroup = "Edit Group";
exports.groupNameLabel = "Group Name";
exports.classNameLable = "Client Name"
exports.classEmployerLable = "Employer Name"
exports.classEmployeridLable = "Class ID"
exports.classNameclassLable = "Class Name"
exports.classEffectivedateLable = "Class Effective Date"
exports.classTerminationdateLable = "Class Termination Date"
exports.classGroupnameLable= "Group Name"
exports.classDivisonnameLable = "Divison Name"
exports.classLocationnameLable = "Location Name"
exports.groupIDLabel = "Group ID";
exports.clientNameLabel = "Client Name";
exports.employerNameLabel = "Employer Name";
exports.employerNumberLabel = "Employer Number";
exports.groupBeginDateLabel = "Group Begin Date";
exports.groupTerminationDateLabel = "Group Termination Date";
exports.groupDeductibleDateLabel = "Group Deductible Date";
exports.firstPayrollDayLabel = "First Payroll Day";
exports.firstPayrollMonthLabel = "First Payroll Month";
exports.payrollCycle1Label = "Payroll Cycle 1 (Default)";
exports.payrollCycle2Label = "Payroll Cycle 2";
exports.payrollCycle3Label = "Payroll Cycle 3";
exports.payrollCycle4Label = "Payroll Cycle 4";
exports.taxIDLabel = "Tax ID";
exports.getMonthlyPayrollAPIEndPoint =
  "bx_block_custom_form/payroll_day_month/month";
exports.getDailyPayrollAPIEndPoint =
  "bx_block_custom_form/payroll_day_month/day";
exports.createGroupAPIEndPoint = "bx_block_custom_form/employers/";
exports.createGroupAPIEndPointBasicDetails = "bx_block_custom_form/employers/{id}/groups";
exports.createGroupAddressEndPoint = "bx_block_custom_form/employers/{id}/groups/update_group_addresses";
exports.createGroupOtherDetailEndPoint = "bx_block_custom_form/employers/{id}/groups";
exports.groupAddSuccess="Group added successfully"
exports.groupEditSuccess="Group edited successfully"

// client Employer
exports.employer = "Employers"
exports.employersListGetApiEndPoint = "bx_block_custom_form/employers"
exports.getAllEmployersAPIEndPoint = "bx_block_custom_form/employers/get_employers"
exports.createEmployerAPiEndPoint = "bx_block_custom_form/employers";
exports.createNewPlanPlanInfoStep = "bx_block_custom_form/plans";
exports.createPlanMedicalStep = "bx_block_custom_form/medical_plans";
exports.payrollDayList = [
  { id: "sunday", name: "Sunday" },
  { id: "monday", name: "Monday" },
  { id: "tuesday", name: "Tuesday" },
  { id: "wednesday", name: "Wednesday" },
  { id: "thursday", name: "Thursday" },
  { id: "friday", name: "Friday" },
  { id: "saturday", name: "Saturday" }
];
exports.payrollMonthList = [
  { id: "january", name: "January" },
  { id: "february", name: "February" },
  { id: "march", name: "March" },
  { id: "april", name: "April" },
  { id: "may", name: "May" },
  { id: "june", name: "June" },
  { id: "july", name: "July" },
  { id: "august", name: "August" },
  { id: "september", name: "September" },
  { id: "october", name: "October" },
  { id: "november", name: "November" },
  { id: "december", name: "December" }
];
exports.payrollCycleList = [
  { id: "weekly", name: "Weekly" },
  { id: "bi_weekly", name: "Bi Weekly" },
  { id: "semi_monthly", name: "Semi Monthly" },
  { id: "monthly", name: "Monthly" }
];
exports.group_admin_fee = "Group Admin Fee";
exports.save_changes = "Save Changes";
exports.add_member = "Add Member";
exports.group_ppo_fee = "Group PPO Fee";
exports.group_cobra_fee = "Group Cobra Fee";
exports.group_hipaa_fee = "Group HIPAA Fee";
exports.group_agg_premium_fee = "Group Agg Premium Fee";
exports.group_fsa_fee = "Group FSA Fee";
exports.group_hra_fee = "Group HRA Fee";
exports.group_hsa_fee = "Group HSA Fee";
exports.group_fee1 = "Group Fee 1";
exports.group_fee2 = "Group Fee 2";
exports.group_fee3 = "Group Fee 3";
exports.group_fee4 = "Group Fee 4";
exports.employer_waiting_period = "Groups Waiting Period";
exports.group_disability_flag = "Group Disability Flag";
exports.group_den_flag = "Group Den Flag";
exports.group_med_flag = "Group Med Flag";
exports.group_std_flag = "Group STD Flag";
exports.group_vis_flag = "Group Vis Flag";
exports.group_life_cover_flag = "Group Life Cover Flag";
exports.group_ltd_flag = "Group LTD Flag";
exports.group_vol_life_flag = "Group Vol Life Flag";
exports.group_add_flag = "Group Add Flag";
exports.group_fsa_flag = "Group FSA Flag";
exports.group_rx_flag = "Group RX Flag";
exports.group_hra_flag = "Group HRA Flag";
exports.group_cancer_flag = "Group Cancer Flag";
exports.group_hsa_flag = "Group HSA Flag";
exports.group_medical_question_flag = "Group Medical Question Flag";
exports.group_401k_flag = "Group 401k Flag";
exports.group_other_flag2 = "Group Other Flag 2";
exports.firstOfTheMonth = "Is First of the month";
exports.employerAddedSuccessfull  = "Employer added Sucessfully";
exports.employerEditedSuccessfull  = "Employer Edited Sucessfully";
exports.employerBasicDetailSaved  = "Employer Basic Details saved Sucessfully";
exports.emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

exports.getClientInfo = "account_block/accounts/"
exports.deleleGroupEndPoint = "bx_block_custom_form/employers/{id}/groups"
exports.deleteLocationEndpoint = "bx_block_custom_form/member_details"
