import React from "react";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import { StepList } from "../../../../../components/src/CustomVerticalFormStepper.web";
import { SelectedEmployerInterface } from "../../AllRoleLandingPageController.web";
import { IDropdownModel, LocationAddressAndContactModel, LocationBasicDetailsModel, LocationModel, initialLocationData } from "../ClientEmployerLocationModel";
import { Message } from "../../../../../framework/src/Message";
import MessageEnum, { getName } from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";
import moment from "moment";
import { IAgentModel } from "../../../../../components/src/GenericAddAgentComponent.web";
import { IDivisionOtherDetailModel, initialAgentModelData } from "../../ClientEmployerDivision/ClientEmployerDivisionModel";

export const configJSON = require("./../../config");
export interface Props {
  isOpen: boolean;
  isEdit: boolean;
  handleClose: any;
  selectedEmployer: SelectedEmployerInterface[],
  locationDetails: LocationModel,
}

interface S {
  isLocationCreated: boolean
  locationActiveStep: number;
  locationStepList: StepList[];
  apiLocationId: string;
  locationData: LocationModel;
  fieldErrors: { [key: string]: string[] };
  generalError: string;

  divisionList: IDropdownModel[];
  groupList: IDropdownModel[];
  countryList: IDropdownModel[];
  physicalStateList: IDropdownModel[];
  mailingStateList: IDropdownModel[];
  locCheckStateList: IDropdownModel[];

  isOpenWarningDialog: boolean;
  isLocationCheckAddressSameAsMailingAddress: boolean;
  isLocationCheckAddressSameAsPhysicalAddress: boolean;
  isMailingAddressSameAsPhysicalAddress: boolean;

  isCompleteBasicDetail: boolean;
}

interface SS { }

type InputType = HTMLInputElement | HTMLTextAreaElement | { value: unknown };


export default class AddEditLocationDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  token: string = ""
  client_name: string = ""
  currentStateType: string = "";
  getCountryCallId: string = "";
  getStateCallId: string = "";
  getGroupCallId: string = "";
  getDivisionCallId: string = "";
  requestCreateLocationCallId: string = "";
  requestUpdateLocationCallId: string = "";
  requiredLocationFieldStep1: { name: keyof LocationBasicDetailsModel, message: string }[] = [
    {
      name: 'location_name',
      message: 'Location name is required'
    },
    {
      name: 'location_id',
      message: 'Location id is required'
    },
    {
      name: 'group_id',
      message: 'Group name is required'
    },
    {
      name: 'division_id',
      message: 'Division name is required'
    },
    {
      name: 'tax_id',
      message: 'Tax ID is required'
    }
  ]

  requestPhysicalStateCallId: string = "";
  requestMailingStateCallId: string = "";
  requestLocCheckStateCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      isLocationCreated: false,
      locationActiveStep: 0,
      locationStepList: [
        {
          key: 1,
          title: "Location Basic Details",
          description: "Type and Select details",
          isCompleted: false
        },
        {
          key: 2,
          title: "Address & Contact",
          description: "Type and Select details",
          isCompleted: false
        },
        {
          key: 3,
          title: "Upload Logo / Supporting Document",
          description: "Type and Select details",
          isCompleted: false
        },
        {
          key: 4,
          title: "Other Details",
          description: "Type and Select details",
          isCompleted: false
        }
      ],
      apiLocationId: '',
      locationData: {
        ...initialLocationData,
      },
      fieldErrors: {},
      generalError: '',

      divisionList: [],
      groupList: [],
      countryList: [],
      physicalStateList: [],
      mailingStateList: [],
      locCheckStateList: [],

      isOpenWarningDialog: false,
      isMailingAddressSameAsPhysicalAddress: false,
      isLocationCheckAddressSameAsMailingAddress: false,
      isLocationCheckAddressSameAsPhysicalAddress: false,

      isCompleteBasicDetail: false
    };
  }

  async componentDidMount() {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const response = JSON.parse(loggedInUser);
      this.token = response.token;
      this.client_name = response.client_name
      if (!this.props.isEdit) {
        this.updateClient(response.client_name)
      }
    }
    this.getGroupDopdownList();
    this.getDivisionList()
    this.getCountryList()
  }

  updateClient(client_name: string) {
    this.setState({
      locationData: {
        ...this.state.locationData,
        locationBasicDetails: {
          ...this.state.locationData.locationBasicDetails,
          employer_name: this.props.selectedEmployer[0].employer_name,
          employer_id: this.props.selectedEmployer[0].id,
          client_name,
        }
      }
    })
  }

  setActiveStep = (step: number) => {
    this.setState({ locationActiveStep: step });
  };

  removePropertyObj = (propKey: keyof LocationBasicDetailsModel | keyof LocationAddressAndContactModel | keyof IDivisionOtherDetailModel, { [propKey]: propValue, ...rest }) => rest

  handleLocationInputChange = (
    e: React.ChangeEvent<InputType>,
    param: keyof LocationBasicDetailsModel
  ) => {
    const index = this.requiredLocationFieldStep1.findIndex(item => item.name === param)

    if (index !== -1) {
      if (!e.target.value) {
        this.setState({
          fieldErrors: {
            ...this.state.fieldErrors,
            [param]: [this.requiredLocationFieldStep1[index].message]
          }
        })
      } else {
        this.setState({
          fieldErrors: this.removePropertyObj(param, this.state.fieldErrors)
        })
      }
    }

    this.setState({
      locationData: {
        ...this.state.locationData,
        locationBasicDetails: {
          ...this.state.locationData.locationBasicDetails,
          [param]: e.target.value,
        },
      },
    });
  };

  handleLocationAddressItemChange = (e: React.ChangeEvent<InputType>, param: string) => {
    const eventValue = e.target.value;

    let fieldName = ""
    if (param.includes("country")) {
      this.currentStateType = param
      this.getStateList(eventValue + "")

      switch (param) {
        case 'physical_country_id':
          fieldName = 'physical_state_id'
          break;
        case 'mailing_country_id':
          fieldName = 'mailing_state_id';
          break;
        case 'location_check_country_id':
          fieldName = 'location_check_state_id';
          break;

      }
      this.setState({
        locationData: {
          ...this.state.locationData,
          locationAddressDetails: {
            ...this.state.locationData.locationAddressDetails,
          }
        }
      })
    }
    if (fieldName) {
      this.setState({
        ...this.state,
        locationData: {
          ...this.state.locationData,
          locationAddressDetails: {
            ...this.state.locationData.locationAddressDetails,
            [param]: eventValue,
            [fieldName]: -1
          },
        },
      });
    } else {
      this.setState({
        ...this.state,
        locationData: {
          ...this.state.locationData,
          locationAddressDetails: {
            ...this.state.locationData.locationAddressDetails,
            [param]: eventValue,
          },
        },
      });
    }
  }

  handleLocationContactItemChange = (
    e: React.ChangeEvent<InputType>,
    param: any
  ) => {
    const regexEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/

    const eventValue = e.target.value;

    if (["location_check_email1", "location_check_email2"].includes(param)) {
      const val: any = eventValue

      const isValid = regexEmail.test(val)

      if (isValid || !eventValue) {
        this.setState({
          fieldErrors: this.removePropertyObj(param, this.state.fieldErrors)
        })
      } else {
        this.setState({
          fieldErrors: {
            ...this.state.fieldErrors,
            [param]: ["Email invalid"]
          }
        })
      }
    }

    this.setState({
      locationData: {
        ...this.state.locationData,
        locationAddressDetails: {
          ...this.state.locationData.locationAddressDetails,
          [param]: eventValue,
        },
      },
    });
  };

  handleMailingAddressCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {

    const isChecked = event.target.checked;
    const {
      physical_address_line1,
      physical_address_line2,
      physical_city,
      physical_country_id,
      physical_state_id,
      physical_zip_code,
    } = this.state.locationData.locationAddressDetails;

    const { physicalStateList } = this.state

    this.setState({
      mailingStateList: isChecked ? physicalStateList : [],
      isMailingAddressSameAsPhysicalAddress: isChecked,
      locationData: {
        ...this.state.locationData,
        locationAddressDetails: {
          ...this.state.locationData.locationAddressDetails,
          mailing_address_line1: isChecked ? physical_address_line1 : '',
          mailing_address_line2: isChecked ? physical_address_line2 : '',
          mailing_city: isChecked ? physical_city : '',
          mailing_country_id: isChecked ? physical_country_id : '',
          mailing_state_id: isChecked ? physical_state_id : '',
          mailing_zip_code: isChecked ? physical_zip_code : '',
        },
      },
    })
  };

  handleLocCheckSameAsPhysical = (event: React.ChangeEvent<HTMLInputElement>, param: string) => {
    const {
      physicalStateList,
    } = this.state
    const {
      physical_address_line1,
      physical_address_line2,
      physical_country_id,
      physical_state_id,
      physical_zip_code,
      physical_city
    } = this.state.locationData.locationAddressDetails;
    const isChecked = event.target.checked;

    this.setState({
      ...this.state,
      [param]: isChecked,
      locCheckStateList: isChecked ? physicalStateList : [],
      isLocationCheckAddressSameAsMailingAddress: false,
      locationData: {
        ...this.state.locationData,
        locationAddressDetails: {
          ...this.state.locationData.locationAddressDetails,
          location_check_address_line1: isChecked ? physical_address_line1 : '',
          location_check_address_line2: isChecked ? physical_address_line2 : '',
          location_check_city: isChecked ? physical_city : '',
          location_check_country_id: isChecked ? physical_country_id : '',
          location_check_state_id: isChecked ? physical_state_id : '',
          location_check_zip_code: isChecked ? physical_zip_code : '',
        },
      },
    });
  }

  handleLocCheckSameAsMailing = (event: React.ChangeEvent<HTMLInputElement>, param: string) => {
    const {
      mailingStateList
    } = this.state
    const {
      mailing_address_line1,
      mailing_address_line2,
      mailing_city,
      mailing_country_id,
      mailing_state_id,
      mailing_zip_code,
    } = this.state.locationData.locationAddressDetails;
    const isChecked = event.target.checked;

    this.setState({
      ...this.state,
      [param]: isChecked,
      locCheckStateList: isChecked ? mailingStateList : [],
      isLocationCheckAddressSameAsPhysicalAddress: false,
      locationData: {
        ...this.state.locationData,
        locationAddressDetails: {
          ...this.state.locationData.locationAddressDetails,
          location_check_address_line1: isChecked ? mailing_address_line1 : '',
          location_check_address_line2: isChecked ? mailing_address_line2 : '',
          location_check_city: isChecked ? mailing_city : '',
          location_check_country_id: isChecked ? mailing_country_id : '',
          location_check_state_id: isChecked ? mailing_state_id : '',
          location_check_zip_code: isChecked ? mailing_zip_code : '',
        },
      },
    });
  }

  handleOtherDetailsInputChange = (
    e: React.ChangeEvent<InputType>,
    param: string,
    agentId?: number
  ) => {
    if (agentId !== undefined) {
      this.setState({
        ...this.state,
        locationData: {
          ...this.state.locationData,
          locationOtherDetails: {
            ...this.state.locationData.locationOtherDetails,
            agents_attributes: this.state.locationData.locationOtherDetails.agents_attributes.map(
              (list, index) => {
                if (index === agentId) {
                  return {
                    ...list,
                    [param]: e.target.value,
                  };
                }
                return list;
              }
            ),
          },
        },
      });
    } else {
      this.setState({
        ...this.state,
        locationData: {
          ...this.state.locationData,
          locationOtherDetails: {
            ...this.state.locationData.locationOtherDetails,
            [param]: e.target.value,
          },
        },
      });
    }
  };

  setAgentList = (
    newAgentList: IAgentModel[],
  ) => {
    this.setState({
      ...this.state,
      locationData: {
        ...this.state.locationData,
        locationOtherDetails: {
          ...this.state.locationData.locationOtherDetails,
          agents_attributes: newAgentList,
        },
      },
    });
  };

  addAgent = () => {
    const newAgentList = [
      ...this.state.locationData.locationOtherDetails.agents_attributes,
    ];
    newAgentList.push(initialAgentModelData);

    this.setAgentList(newAgentList);
  };

  removeAgent = (index: number) => {
    const newAgentList = this.state.locationData.locationOtherDetails.agents_attributes.filter(
      (_, i) => i !== index
    );

    this.setAgentList(newAgentList);
  };

  toggleGroupOtherDetailsField = (
    checked: boolean,
    param: string
  ) => {
    this.setState({
      ...this.state,
      locationData: {
        ...this.state.locationData,
        locationOtherDetails: {
          ...this.state.locationData.locationOtherDetails,
          [param]: checked,
        },
      },
    });
  };

  getLocationErrorMessage = (fieldName: string) => {
    const { fieldErrors } = this.state;
    if (fieldErrors[fieldName]) {
      return fieldErrors[fieldName][0];
    }
    return "";
  }

  handleNext = (mustCheckStep: boolean) => {
    if (mustCheckStep && this.state.locationActiveStep === 3) {
      return this.setState({
        isLocationCreated: true
      })
    }
    this.handleSteps(true);
  };

  handleSteps = (next: boolean) => {
    const activeStep = next ? this.state.locationActiveStep + 1 : this.state.locationActiveStep - 1;
    const length = this.state.locationStepList.length;
    if (length > activeStep) {
      this.setState({
        locationActiveStep: activeStep,
        locationStepList: this.state.locationStepList.map(
          (list, index) => {
            if (index === this.state.locationActiveStep) {
              return {
                ...list,
                isCompleted: true,
              };
            }
            return list;
          }
        ),
      });
    }
  }

  isEmpty = (object: any) => {
    for (let key in object) {
      if (object.hasOwnProperty(key)) {
        return false
      }
    }
    return true
  }

  checkErrors = () => {
    return new Promise((resolve) => {

      if (!this.isEmpty(this.state.fieldErrors) || this.state.generalError !== "") {
        return resolve(true)
      }

      const { locationBasicDetails } = this.state.locationData

      const { termination_date, effective_date, location_id, location_name, division_id, group_id, tax_id } = locationBasicDetails

      const errors: { [key: string]: string[] } = {}

      if (!location_id) {
        errors.location_id = ["Location id is required"]
      }
      if (!location_name) {
        errors.location_name = ["Location name is required"]
      }
      if (!division_id) {
        errors.division_id = ["Division name is required"]
      }
      if (!group_id) {
        errors.group_id = ["Group name is required"]
      }
      if (!tax_id) {
        errors.tax_id = ["Tax ID is required"]
      }

      if (!this.isEmpty(errors)) {
        this.setState({
          fieldErrors: errors
        })
        return resolve(true)
      }

      if (termination_date && effective_date) {
        const termi_date = moment(termination_date, "YYYY-MM-DD")
        const beg_date = moment(effective_date, "YYYY-MM-DD")

        if (termi_date.isBefore(beg_date)) {
          this.setState({
            generalError: `Termination date can't before the begin date`
          })
          return resolve(true)
        }
      }

      resolve(false)
    })
  }

  removeErrors = () => {
    return new Promise((resolve) => {
      this.setState({
        generalError: ''
      }, () => resolve(true))
    })
  }

  saveAndNext = () => {
    this.handleNext(false);
  };

  saveAndFinish = async () => {
    await this.removeErrors()
    const stillError = await this.checkErrors()
    if (stillError) {
      return;
    }

    if (this.state.apiLocationId) {
      this.updateLocation()
    } else {
      this.createLocation()
    }
  };

  getBodyParams = () => {
    const { countryList, physicalStateList, mailingStateList, locCheckStateList } = this.state
    const { locationBasicDetails, locationAddressDetails, locationOtherDetails } = this.state.locationData

    const { agents_attributes } = locationOtherDetails

    const location_other_detail_attributes = this.removePropertyObj('agents_attributes', locationOtherDetails)

    let agents: { agent_name: string, agent_rate: string }[] = []
    agents_attributes.forEach((item: IAgentModel) => {
      if (item.agent_name && item.agent_rate) {
        agents = agents.concat({
          agent_name: item.agent_name,
          agent_rate: item.agent_rate
        })
      }
    });

    const {
      physical_address_line1,
      physical_address_line2,
      physical_city,
      physical_state_id,
      physical_country_id,
      physical_zip_code,
      mailing_address_line1,
      mailing_address_line2,
      mailing_city,
      mailing_state_id,
      mailing_country_id,
      mailing_zip_code,
      location_check_address_line1,
      location_check_address_line2,
      location_check_city,
      location_check_state_id,
      location_check_country_id,
      location_check_zip_code,
      location_check_contact1,
      location_check_contact2,
      location_check_email1,
      location_check_email2,
      location_check_phone_number1,
      location_check_phone_number2,
      location_check_fax1,
      location_check_fax2
    } = locationAddressDetails

    const mailingCountryName = countryList.find((item: IDropdownModel) => item.id === mailing_country_id)?.name

    const physicalCountryName = countryList.find((item: IDropdownModel) => item.id === physical_country_id)?.name

    const physicalStateName = physicalStateList.find((item: IDropdownModel) => item.id === physical_state_id)?.name

    const mailingStateName = mailingStateList.find((item: IDropdownModel) => item.id === mailing_state_id)?.name

    const locCheckCountryName = countryList.find((item: IDropdownModel) => item.id === location_check_country_id)?.name

    const locCheckStateName = locCheckStateList.find((item: IDropdownModel) => item.id === location_check_state_id)?.name

    const httpBody: any = {
      data: {
        attributes: {
          ...locationBasicDetails,
          physical_address_attributes: {
            address_line_1: physical_address_line1,
            address_line_2: physical_address_line2,
            city: physical_city,
            zip_code: physical_zip_code,
            country: physicalCountryName,
            state: physicalStateName
          },
          mailing_address_attributes: {
            address_line_1: mailing_address_line1,
            address_line_2: mailing_address_line2,
            city: mailing_city,
            zip_code: mailing_zip_code,
            country: mailingCountryName,
            state: mailingStateName
          },
          contacts_attributes: [
            {
              contact: location_check_contact1,
              email: location_check_email1,
              phone_number: location_check_phone_number1,
              fax: location_check_fax1
            },
            {
              contact: location_check_contact2,
              email: location_check_email2,
              phone_number: location_check_phone_number2,
              fax: location_check_fax2
            }
          ],
          location_other_detail_attributes,
          agents_attributes: agents
        }
      }
    };

    if (this.state.locationActiveStep === 1) {
      httpBody.data.attributes.location_check_address_attributes = {
        address_line_1: location_check_address_line1,
        address_line_2: location_check_address_line2,
        city: location_check_city,
        zip_code: location_check_zip_code,
        country: locCheckCountryName,
        state: locCheckStateName
      }
    }

    return httpBody
  }

  createLocation = () => {
    this.setState({
      generalError: "",
      fieldErrors: {},
    });

    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.requestCreateLocationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createUpdateLocationEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.getBodyParams())
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateLocation = () => {
    this.setState({
      generalError: "",
      fieldErrors: {},
    });

    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.requestUpdateLocationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createUpdateLocationEndpoint}/${this.state.apiLocationId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.getBodyParams())
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCountryList = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCountryCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllCountryAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStateList = (idCountry: string, typeState?: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    switch (typeState) {
      case 'physical_country_id':
        this.requestPhysicalStateCallId = requestMessage.messageId;
        break;
      case 'mailing_country_id':
        this.requestMailingStateCallId = requestMessage.messageId;
        break;
      case 'location_check_country_id':
        this.requestLocCheckStateCallId = requestMessage.messageId;
        break;
      default:
        this.getStateCallId = requestMessage.messageId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStateFromCountryAPiEndPoint}${idCountry}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getDivisionList = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.token,
    };

    this.getDivisionCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.divisionsListGetApiEndPoint}?employer_id=${this.props.selectedEmployer[0].id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getGroupDopdownList = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGroupCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGroupAPiEndPoint.replace("{id}", this.props.selectedEmployer[0].emp_id)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.getGroupCallId:
        this.receiveGroupList(responseJson)
        break;
      case this.getDivisionCallId:
        this.receiveDivisionList(responseJson)
        break;
      case this.requestCreateLocationCallId:
        this.receiveCreateLocation(responseJson)
        break;
      case this.requestUpdateLocationCallId:
        this.receiveUpdateLocation(responseJson)
        break;
      case this.getCountryCallId:
        this.receiveCountryList(responseJson)
        break;
      case this.getStateCallId:
        this.receiveStateList(responseJson)
        break;
      case this.requestPhysicalStateCallId:
        this.receiveSpecifyState(responseJson, 'physical_country_id')
        break;
      case this.requestMailingStateCallId:
        this.receiveSpecifyState(responseJson, 'mailing_country_id')
        break;
      case this.requestLocCheckStateCallId:
        this.receiveSpecifyState(responseJson, 'location_check_country_id')
        break;
    }
  }

  receiveCountryList = (responseJson: any) => {
    if (responseJson && responseJson.countries && responseJson.countries.length > 0) {
      this.setState({
        ...this.state,
        countryList: responseJson.countries.map((item: any) => ({ id: item.countryCode, name: item.CountryName }))
      }, () => this.updateEditLoc());
    }
  };

  updateEditLoc() {
    const { isEdit, locationDetails } = this.props
    const { countryList } = this.state
    if (!isEdit) {
      return;
    }
    let physical_country_id: string = locationDetails.locationAddressDetails.physical_country_id
    let mailing_country_id: string = locationDetails.locationAddressDetails.mailing_country_id
    let location_check_country_id: string = locationDetails.locationAddressDetails.location_check_country_id

    if (physical_country_id) {
      physical_country_id = countryList.find(item => item.name === physical_country_id)?.id || ''
      this.getStateList(physical_country_id, 'physical_country_id')
    }
    if (mailing_country_id) {
      mailing_country_id = countryList.find(item => item.name === mailing_country_id)?.id || ''
      this.getStateList(mailing_country_id, 'mailing_country_id')
    }
    if (location_check_country_id) {
      location_check_country_id = countryList.find(item => item.name === location_check_country_id)?.id || ''
      this.getStateList(location_check_country_id, 'location_check_country_id')
    }

    this.setState({
      apiLocationId: locationDetails.apiLocationId,
      locationData: {
        ...locationDetails,
        locationBasicDetails: {
          ...locationDetails.locationBasicDetails,
          employer_name: this.props.selectedEmployer[0].employer_name,
          employer_id: this.props.selectedEmployer[0].id,
          client_name: this.client_name,
        },
        locationAddressDetails: {
          ...locationDetails.locationAddressDetails,
          physical_country_id,
          mailing_country_id,
          location_check_country_id
        }
      }
    })
  }

  receiveStateList = (responseJson: any) => {
    let type: string = ""
    switch (this.currentStateType) {
      case 'physical_country_id':
        type = 'physicalStateList'
        break;
      case 'mailing_country_id':
        type = 'mailingStateList'
        break;
      case 'location_check_country_id':
        type = 'locCheckStateList'
        break;
    }

    if (responseJson && responseJson.states && responseJson.states.length > 0 && type) {
      this.setState({ ...this.state, [type]: responseJson.states.map((item: any) => ({ id: item.StateCode, name: item.StateName })) });
    }
  };

  receiveCreateLocation = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({ apiLocationId: responseJson.data.id, isCompleteBasicDetail: !this.props.isEdit })
      return this.handleNext(true)
    }
    return this.handleErrorApi(responseJson)
  }

  receiveUpdateLocation = (responseJson: any) => {
    if (responseJson.data) {
      return this.handleNext(true)
    }
    return this.handleErrorApi(responseJson)
  }

  receiveGroupList = (responseJson: any) => {
    if (responseJson && responseJson.data && responseJson.data.length > 0) {
      this.setState({
        groupList: responseJson.data.map((list: any) => ({
          id: list.id,
          name: list.attributes.group_name,
        })),
      });
    }
  };

  receiveDivisionList = (responseJson: any) => {
    if (responseJson && responseJson.data && responseJson.data.length > 0) {
      this.setState({
        ...this.state,
        divisionList: responseJson.data.map((list: any) => ({
          id: list.id,
          name: list.attributes.division_name,
        })),
      });
    }
  }

  handleErrorApi = (responseJson: any) => {
    if (responseJson.errors) {
      if (responseJson.errors && Array.isArray(responseJson.errors)) {
        if (responseJson.errors[0]?.token) {
          return this.setState({
            generalError: responseJson.errors[0].token,
          });
        }
        if (typeof (responseJson.errors[0]) === 'string') {
          return this.setState({
            generalError: responseJson.errors[0],
          });
        }
      }

      if (typeof responseJson.errors === 'object') {
        return this.setState({
          fieldErrors: responseJson.errors,
        });
      }
    }
  }

  handleCloseWarningDialog = (isAgreeToLooseData: boolean) => {
    if (isAgreeToLooseData) {
      this.props.handleClose();
    }
    this.setState({ ...this.state, isOpenWarningDialog: false });
  };

  setToInitialState = () => {
    this.setState({
      ...this.state,
      locationActiveStep: 0,
      isLocationCreated: false,
      locationData: {
        ...initialLocationData,
      },

      isOpenWarningDialog: false,

      divisionList: [],
      groupList: [],
      countryList: [],
      physicalStateList: [],
      mailingStateList: [],
      locCheckStateList: [],
      isCompleteBasicDetail: false
    });
  };

  handleSuccessDialogClose = () => {
    this.setToInitialState()
    this.props.handleClose();
  }

  receiveSpecifyState = (responseJson: any, typeState?: string) => {
    const { locationDetails } = this.props
    const { locationData } = this.state

    const array = responseJson.states.map((item: any) => ({ id: item.StateCode, name: item.StateName }))
    let type: string = ""
    let value: string = ""
    let param: string = ""
    switch (typeState) {
      case 'physical_country_id':
        type = 'physicalStateList'
        value = array.find((item: IDropdownModel) => item.name == locationDetails.locationAddressDetails.physical_state_id)?.id
        param = 'physical_state_id'
        break;
      case 'mailing_country_id':
        type = 'mailingStateList'
        value = array.find((item: IDropdownModel) => item.name == locationDetails.locationAddressDetails.mailing_state_id)?.id
        param = 'mailing_state_id'
        break;
      case 'location_check_country_id':
        type = 'locCheckStateList'
        value = array.find((item: IDropdownModel) => item.name == locationDetails.locationAddressDetails.location_check_state_id)?.id
        param = 'location_check_state_id'
        break;
    }

    if (responseJson && responseJson.states && responseJson.states.length > 0) {
      this.setState({
        ...this.state,
        [type]: array,
        locationData: {
          ...locationData,
          locationAddressDetails: {
            ...locationData.locationAddressDetails,
            [param]: value
          }
        }
      });
    } else {
      this.setState({ ...this.state, [type]: [] });
    }
  };

  handleLocationDialogClose = () => {
    this.setState({ ...this.state, isOpenWarningDialog: true });
  };
}
