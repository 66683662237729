import React from "react";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { Column } from "../../../../components/src/CustomTable.web";
import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
export const configJSON = require("../config");
export const images = require("../assets");
import { FilterChild } from "../../../../components/src/CustomFilterPopup.web";
import { SelectedEmployerInterface } from "../AllRoleLandingPageController.web";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
import { LocationModel, initialLocationData } from "./ClientEmployerLocationModel";
import { IAgentModel } from "../../../../components/src/GenericAddAgentComponent.web";

export interface Props {
    navigation: any;
    id: string;
    selectedEmployer: SelectedEmployerInterface[]
}
interface S {
    token: string;
    rowData: RowData[];
    filterChildList: FilterChild[];
    locationTableMenuAnchorEl: HTMLElement | null;
    isEditLocation: boolean;
    isOpenEditLocationDialog: boolean;
    searchValue: string;
    openFilter: boolean;
    isAddNewLocation: boolean;
    showDialogSuccess: boolean;
    actionId: string;
    locationDetails: LocationModel;
}

interface RowData {
    id: string;
    location_id: string;
    location_name: string;
    group_name: string;
    division_name: string;
    employer_name: string;
    address_line_1: string;
    effective_date: string;
    termination_date: string;
}
interface SS { }


export default class ClientEmployerLocationController extends BlockComponent<
    Props,
    S,
    SS
> {
    tabMenuList = [
        "Groups",
        "Enrollment",
        "Enroll Config",
        "Medical Review",
        "Pay Claims",
        "Recon",
        "Invoice",
        "Payroll Invoice",
        "Case Billing",
        "Documents",
    ];

    tableColumns: Column[] = [
        {
            id: "location_id",
            label: "Location Number",
            headerAlign: "left",
            align: "left",
        },
        {
            id: "location_name",
            label: "Location Name",
            headerAlign: "left",
            align: "left",
        },
        {
            headerAlign: "left",
            align: "left",
            id: "group_name",
            label: "Group Name",
        },
        {
            label: "Division Name",
            headerAlign: "left",
            id: "division_name",
            align: "left",
        },
        {
            id: "employer_name",
            headerAlign: "left",
            align: "left",
            label: "Employer Name",
        },
        {
            id: "address_line_1",
            headerAlign: "left",
            align: "left",
            label: "Address line 1",
        },
        {
            id: "effective_date",
            headerAlign: "left",
            align: "left",
            label: "Effective date",
        },
        {
            id: "termination_date",
            headerAlign: "left",
            align: "left",
            label: "Termination date",
        },
        {
            id: "action",
            label: "",
            headerAlign: "left",
            align: "right",
            format: (row: RowData) => {
                return (
                    <div className="division-table-action-container">
                        <IconButton className="menu-btn" onClick={this.handleLocationOpenTableMenu}>
                            <MoreVertIcon fontSize="large" />
                        </IconButton>
                    </div>
                );
            },
        },
    ];

    requestLocationsListApiId: string = ""
    requestDeleteLocation: string = ""
    rawDataList: any[] = [];
    locationList: RowData[] = [];

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            token: "",
            isEditLocation: false,
            rowData: [],
            locationTableMenuAnchorEl: null,
            isOpenEditLocationDialog: false,
            searchValue: "",
            openFilter: false,
            filterChildList: [],
            isAddNewLocation: false,
            showDialogSuccess: false,
            actionId: '',
            locationDetails: initialLocationData
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        const loggedInUser = localStorage.getItem("user");
        if (loggedInUser) {
            const { token } = JSON.parse(loggedInUser);
            this.setState({ token })
            this.fetchAllLocationsList(token);
        }
    }

    fetchAllLocationsList = (token: string) => {
        if (this.props.selectedEmployer.length === 0) {
            return;
        }
        const header = {
            "Content-Type": configJSON.ContentType,
            "token": token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.requestLocationsListApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getListLocationEndPoint}?employer_id=${this.props.selectedEmployer[0].id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.requestLocationsListApiId) {
                this.getLocationListData(responseJson)
            }

            if (apiRequestCallId === this.requestDeleteLocation) {
                this.handleDeleteLocation(responseJson)
            }
        }
    }

    getLocationListData = (response: any) => {
        if (response && response.data && response.data.length > 0) {
            this.extractTableData(response.data);
        }
    }

    extractTableData = (apiData: any) => {
        const extractedData: RowData[] = [];
        this.rawDataList = apiData
        apiData.forEach((item: any) => {
            const id = item.id;
            const location_id = item.attributes.location_id || "-";
            const location_name = item.attributes.location_name || "-";
            const division_name = item.attributes.division_name || "-";
            const group_name = item.attributes.group_id || "-";
            const employer_name = item.attributes.employer_name || "-";

            const effective_date = item.attributes.effective_date || "-";
            const termination_date = item.attributes.termination_date || "-";

            const address = item.attributes.physical_address.data;
            let address_line_1 = "-"
            if (address) {
                address_line_1 = address?.attributes.address_line_1 || "-";
            }

            const extractedItem = {
                id,
                location_id,
                location_name,
                group_name,
                division_name,
                employer_name,
                address_line_1,
                effective_date,
                termination_date,
            }
            extractedData.push(extractedItem);
        });

        this.locationList = extractedData
        this.setState({
            rowData: extractedData
        })
    }

    handleLocationOpenTableMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ locationTableMenuAnchorEl: event.currentTarget });
    };

    toggleEditLocationDialog = (open: boolean, isEdit: boolean) => {
        this.setState({
            ...this.state,
            isEditLocation: isEdit,
            locationTableMenuAnchorEl: null,
            isOpenEditLocationDialog: open,
        });
    };

    handleLocationCloseTableMenu = () => {
        this.setState({
            locationTableMenuAnchorEl: null,
            locationDetails: { ...initialLocationData },
            actionId: ''
        });
    };

    handlAddLocationButton = () => {
        this.setState({ isAddNewLocation: true })
    }

    onSearchInputChange = (e: any) => {
        const searchKey = e.target.value;
        this.setState({ searchValue: searchKey });
    };

    handleLocationFilterClick = () => {
        this.setState({ openFilter: !this.state.openFilter });
    };

    handleLocationFilterOrSearchOnChange = (newState: FilterChild[]) => {
    };

    handleLocationDialogClose = () => {
        this.toggleEditLocationDialog(false, false);
        this.fetchAllLocationsList(this.state.token)
    }

    handleLocationTableMenuClick = (id: string) => {
        if (id === "1") {
            this.toggleEditLocationDialog(true, true);
        }
        if (id === "2") {
            this.callAPIDeleteLocation()
        }
    };

    callAPIDeleteLocation() {
        this.setState({
            locationTableMenuAnchorEl: null
        })
        const header = {
            "Content-Type": configJSON.applicationJsonContentType,
            token: this.state.token,
        }

        const requestMessageAddress = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.requestDeleteLocation = requestMessageAddress.messageId

        requestMessageAddress.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageAddress.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.createDivisionAPiEndPoint}/${this.state.actionId}`
        )

        requestMessageAddress.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpDeleteMethod
        );

        runEngine.sendMessage(requestMessageAddress.id, requestMessageAddress);
    }

    handleDeleteLocation(response: any) {
        if (response) {
            this.setState({ showDialogSuccess: true });
        }
    }

    handleSuccessDialogClose = () => {
        this.rawDataList = this.rawDataList.filter(item => item.id !== this.state.actionId)
        this.locationList = this.locationList.filter(item => item.id !== this.state.actionId)

        this.setState({
            rowData: this.locationList,
            actionId: '',
            showDialogSuccess: false
        })
    }

    convertProperties(obj: any) {
        try {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const value = obj[key]
                    if (typeof value === 'string') {
                        obj[key] = (value.toLowerCase() === 'true' || value.toLowerCase() === 'false') ? (value.toLowerCase() === 'true') : parseFloat(value) || 0
                    }
                }
            }
        } catch (error) {
        }
        return obj
    }

    convertBasicDetails(attributes: any) {
        return {
            location_name: attributes.location_name,
            location_id: attributes.location_id,
            client_name: attributes.client_name,
            employer_name: attributes.employer_name,
            employer_id: attributes.employer_id,
            group_id: attributes.group_id,
            division_id: attributes.division_id,
            effective_date: attributes.effective_date,
            termination_date: attributes.termination_date,
            deductible_date: attributes.deductible_date,
            payroll_day: attributes.payroll_day,
            payroll_month: attributes.payroll_month,
            first_payroll_cycle: attributes.first_payroll_cycle,
            second_payroll_cycle: attributes.second_payroll_cycle,
            third_payroll_cycle: attributes.fourth_payroll_cycle,
            fourth_payroll_cycle: attributes.fourth_payroll_cycle,
            tax_id: attributes.tax_id,
        }
    }

    convertModelData(data: any) {
        const initData: LocationModel = { ...initialLocationData }

        const attributes = data.attributes

        const basicDetails = this.convertBasicDetails(attributes)

        initData.locationBasicDetails = basicDetails

        const physAddress = attributes.physical_address.data?.attributes

        if (physAddress) {
            initData.locationAddressDetails = {
                ...initData.locationAddressDetails,
                physical_address_line1: physAddress.address_line_1,
                physical_address_line2: physAddress.address_line_2,
                physical_city: physAddress.city,
                physical_state_id: physAddress.state,
                physical_zip_code: physAddress.zip_code,
                physical_country_id: physAddress.country
            }
        }

        const mailAddress = attributes.mailing_address.data?.attributes

        if (mailAddress) {
            initData.locationAddressDetails = {
                ...initData.locationAddressDetails,
                mailing_address_line1: mailAddress.address_line_1,
                mailing_address_line2: mailAddress.address_line_2,
                mailing_city: mailAddress.city,
                mailing_state_id: mailAddress.state,
                mailing_zip_code: mailAddress.zip_code,
                mailing_country_id: mailAddress.country
            }
        }

        const locCheckAddress = attributes.location_check_address.data?.attributes

        if (locCheckAddress) {
            initData.locationAddressDetails = {
                ...initData.locationAddressDetails,
                location_check_address_line1: locCheckAddress.address_line_1,
                location_check_address_line2: locCheckAddress.address_line_2,
                location_check_city: locCheckAddress.city,
                location_check_state_id: locCheckAddress.state,
                location_check_zip_code: locCheckAddress.zip_code,
                location_check_country_id: locCheckAddress.country
            }
        }

        const contact = attributes.contact.data

        if (contact && contact.length !== 0) {
            contact.forEach((item: any, index: number) => {
                if (index === 0) {
                    initData.locationAddressDetails.location_check_contact1 = item.attributes.contact
                    initData.locationAddressDetails.location_check_email1 = item.attributes.email
                    initData.locationAddressDetails.location_check_fax1 = item.attributes.fax
                    initData.locationAddressDetails.location_check_phone_number1 = item.attributes.phone_number
                } else {
                    initData.locationAddressDetails.location_check_contact2 = item.attributes.contact
                    initData.locationAddressDetails.location_check_email2 = item.attributes.email
                    initData.locationAddressDetails.location_check_fax2 = item.attributes.fax
                    initData.locationAddressDetails.location_check_phone_number2 = item.attributes.phone_number
                }
            })
        }

        const locOtherDetail = attributes.location_other_detail.data?.attributes

        const agentData = attributes.agents.data

        if (agentData && agentData.length !== 0) {
            const dataAgents: Array<IAgentModel> = []
            agentData.forEach((item: any, index: number) => {
                dataAgents.push({
                    agent_name: item.attributes.agent_name,
                    agent_rate: item.attributes.agent_rate,
                    id: index + 1
                })
            })

            initData.locationOtherDetails = {
                ...initData.locationOtherDetails,
                agents_attributes: dataAgents
            }
        }

        initData.locationOtherDetails = {
            ...initData.locationOtherDetails,
            ...this.convertProperties(locOtherDetail)
        }

        initData.apiLocationId = data.id

        return initData
    }

    handleActionClick = (id: string) => {
        this.setState({
            actionId: id,
            locationDetails: this.convertModelData(this.rawDataList.find(item => item.id === id))
        })
    }
}
