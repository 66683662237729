import { IAgentModel } from "../../../../components/src/GenericAddAgentComponent.web";

export interface IEmployerBasicDetailModel {
  client_name: string;
  employer_name: string;
  emp_id: string;
  effective_date: Date | string;
  termination_date: Date | string;
  is_active: boolean;
  payroll_day: string;
  payroll_month: string;
  payroll_cycle1: string;
  payroll_cycle2: string;
  payroll_cycle3: string;
  payroll_cycle4: string;
  tax_id: string;
  id?: string;
}

export interface IEmployerAddressDetailModel {
  physical_address_line1: string;
  physical_address_line2: string;
  physical_city: string;
  physical_zip_code: string;
  physical_state_id: string;
  physical_country_id: string;
  mailing_address_line1: string;
  mailing_address_line2: string;
  mailing_city: string;
  mailing_zip_code: string;
  mailing_state_id: string;
  mailing_country_id: string;
  mailing_contact1: string;
  mailing_contact2: string;
  mailing_email1: string;
  mailing_email2: string;
  mailing_phone_number1: string;
  mailing_phone_number2: string;
  mailing_fax1: string;
  mailing_fax2: string;
}

export interface IEmployerLogoAndDocModel {
  emp_logo: File | string;
  emp_doc: File | string;
}

export interface IEmployerOtherDetailModel {
  agentsDetails: IAgentModel[];
  employer_hipaa_fee: string;
  employer_agg_premium_fee: string;
  employer_fsa_fee: string;
  cost_containment_fee: string;
  employer_admin_fee: string;
  employer_ppo_fee: string;
  employer_cobra_fee: string;
  employer_hra_fee: string;
  employer_hsa_fee: string;
  employer_fee1: string;
  employer_fee2: string;
  employer_fee3: string;
  employer_fee4: string;
  med_ee_spec_premium: string;
  vis_ee_plus_sp_spec_premium: string;
  med_ee_plus_ch_spec_premium: string;
  den_ee_plus_ch_spec_premium: string;
  vis_ee_plus_ch_spec_premium: string;
  den_ee_spec_premium: string;
  vis_ee_spec_premium: string;
  med_ee_plus_sp_spec_premium: string;
  den_ee_plus_sp_spec_premium: string;
  med_ee_plus_chs_spec_premium: string;
  den_ee_plus_chs_spec_premium: string;
  vis_ee_plus_chs_spec_premium: string;
  med_ee_fam_spec_premium: string;
  surcharge_percentage: string;
  employer_waiting_period: string;
  den_ee_fam_spec_premium: string;
  vis_ee_fam_spec_premium: string;
  specific_deductible: string;
  aggregate_deductible: string;
}

export interface IEmployerDetailModel {
  employerBasicDetails: IEmployerBasicDetailModel;
  employerAddressDetails: IEmployerAddressDetailModel;
  employerFileDetails: IEmployerLogoAndDocModel;
  employerOtherDetails: IEmployerOtherDetailModel;
  id?:string;
}

export interface IDropdownModel {
  id: number;
  name: string;
}

export const initialEmployerBasicDetailModelData: IEmployerBasicDetailModel = {
  client_name: "",
  employer_name: "",
  emp_id: "",
  effective_date: "",
  termination_date: "",
  is_active: false,
  payroll_day: "",
  payroll_month: "",
  payroll_cycle1: "",
  payroll_cycle2: "",
  payroll_cycle3: "",
  payroll_cycle4: "",
  tax_id: ""
};

export const initialEmployerAddressDetailModelData: IEmployerAddressDetailModel = {
  physical_address_line1: "",
  physical_address_line2: "",
  physical_city: "",
  physical_zip_code: "",
  physical_state_id: "",
  physical_country_id: "",
  mailing_address_line1: "",
  mailing_address_line2: "",
  mailing_city: "",
  mailing_zip_code: "",
  mailing_state_id: "",
  mailing_country_id: "",
  mailing_contact1: "",
  mailing_contact2: "",
  mailing_email1: "",
  mailing_email2: "",
  mailing_phone_number1: "",
  mailing_phone_number2: "",
  mailing_fax1: "",
  mailing_fax2: ""
};

export const initialEmployerFileModelData: IEmployerLogoAndDocModel = {
  emp_logo: "",
  emp_doc: ""
};

export const initialEMPAgentData: IAgentModel = {
  agent_name: "",
  agent_rate: "",
  id: 1
};

export const initialEmployerOtherDetailModelData: IEmployerOtherDetailModel = {
  agentsDetails: [initialEMPAgentData],
  employer_hra_fee: "",
  employer_hsa_fee: "",
  employer_fee1: "",
  employer_fee2: "",
  employer_fee3: "",
  cost_containment_fee: "",
  employer_admin_fee: "",
  employer_ppo_fee: "",
  employer_cobra_fee: "",
  employer_hipaa_fee: "",
  employer_agg_premium_fee: "",
  employer_fsa_fee: "",
  employer_fee4: "",
  med_ee_spec_premium: "",
  den_ee_spec_premium: "",
  vis_ee_spec_premium: "",
  med_ee_plus_sp_spec_premium: "",
  den_ee_plus_sp_spec_premium: "",
  vis_ee_plus_sp_spec_premium: "",
  med_ee_plus_ch_spec_premium: "",
  den_ee_plus_ch_spec_premium: "",
  vis_ee_plus_ch_spec_premium: "",
  vis_ee_fam_spec_premium: "",
  specific_deductible: "",
  aggregate_deductible: "",
  surcharge_percentage: "",
  employer_waiting_period: "",
  med_ee_plus_chs_spec_premium: "",
  den_ee_plus_chs_spec_premium: "",
  vis_ee_plus_chs_spec_premium: "",
  med_ee_fam_spec_premium: "",
  den_ee_fam_spec_premium: ""
};

export const initialEmployerDetailModelData: IEmployerDetailModel = {
  employerBasicDetails: initialEmployerBasicDetailModelData,
  employerAddressDetails: initialEmployerAddressDetailModelData,
  employerFileDetails: initialEmployerFileModelData,
  employerOtherDetails: initialEmployerOtherDetailModelData
};

export interface ApiDataItem {
  id: any;
  attributes: {
    emp_id: any;
    client_name: any;
    employer_name: any;
    effective_date: any;
    termination_date: any;
    is_active: any;
    payroll_day: any;
    payroll_month: any;
    payroll_cycle1: any;
    payroll_cycle2: any;
    payroll_cycle3: any;
    payroll_cycle4: any;
    tax_id: any;
    physical_address: { data: { attributes: any } };
    mailing_address: { data: { attributes: any } };
    contact: { data: { attributes: any }[] };
    other_details: { data: { attributes: any } };
    agents: { data: { attributes: any }[] };
  };
}

export interface AddressDataInterface {
  address_line_1: string;
  address_line_2: string;
  city: string;
  zip_code: string;
  state: { id: string };
  country: { id: string };
}

export interface ContactData {
  contact: any;
  phone_number: any;
}

export interface AgentData {
  attributes: any;
}

export interface Contact {
  id: string;
  type: string;
  attributes: {
      contact: string;
      email: string;
      phone_number: string;
      fax: string;
  };
}

export interface Agent {
  id: string;
  type: string;
  attributes: {
      agent_name: string;
      agent_rate: string;
  };
}

export interface State {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface Country {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface Address {
  id: string;
  type: string;
  attributes: {
      id: number;
      address_line_1: string;
      address_line_2: string;
      city: string;
      state: State;
      zip_code: string;
      country: Country;
  };
}

export interface EmployerOtherDetail {
  id: string;
  type: string;
  attributes: {
      cost_containment_fee: number;
      employer_admin_fee: number;
  };
}

export interface Data {
  id: string;
  type: string;
  attributes: {
      employer_name: string;
      emp_id: string;
      client_name: string;
      effective_date: string;
      termination_date: string;
      is_active: boolean;
      payroll_day: string;
      payroll_month: string;
      payroll_cycle1: string;
      payroll_cycle2: string;
      payroll_cycle3: string;
      payroll_cycle4: string;
      tax_id: string;
  };
  contact?: {
      data: Contact[];
  };
  agents?: {
      data: Agent[];
  };
  physical_address?: {
      data: Address;
  };
  other_details?: {
      data: EmployerOtherDetail;
  };
  mailing_address?: {
      data: Address;
  };
}

export interface EmployerData {
  data?: Data;
}

export const basicDetailsAPIData = {
  employer: {
    employer_name: "",
    client_name: "",
    emp_id: "",
    effective_date: "",
    termination_date: "",
    is_active: false,
    payroll_day: "",
    payroll_month: "",
    payroll_cycle1: "",
    payroll_cycle2: "",
    payroll_cycle3: "",
    payroll_cycle4: "",
    tax_id: "",
    contacts_attributes: [
      {
        contact: "",
        email: "",
        phone_number: "",
        fax: ""
      },
      {
        contact: "",
        email: "",
        phone_number: "",
        fax: ""
      }
    ],
    agents_attributes: [
      {
        agent_name: "",
        agent_rate: ""
      }
    ],
    physical_address_attributes: {
      address_line_1: "",
      address_line_2: "",
      city: "",
      zip_code: "",
      country_id: "",
      state_id: ""
    },
    mailing_address_attributes: {
      address_line_1: "",
      address_line_2: "",
      city: "",
      zip_code: "",
      country_id: "",
      state_id: ""
    },
    employer_other_detail_attributes: {
      cost_containment_fee: "",
      employer_admin_fee: "",
      employer_ppo_fee: "",
      employer_cobra_fee: "",
      employer_hipaa_fee: "",
      employer_agg_premium_fee: "",
      employer_fsa_fee: "",
      employer_hra_fee: "",
      employer_hsa_fee: "",
      employer_fee1: "",
      employer_fee2: "",
      employer_fee3: "",
      employer_fee4: "",
      med_ee_spec_premium: "",
      den_ee_spec_premium: "",
      vis_ee_spec_premium: "",
      med_ee_plus_sp_spec_premium: "",
      den_ee_plus_sp_spec_premium: "",
      vis_ee_plus_sp_spec_premium: "",
      med_ee_plus_ch_spec_premium: "",
      den_ee_plus_ch_spec_premium: "",
      vis_ee_plus_ch_spec_premium: "",
      med_ee_plus_chs_spec_premium: "",
      den_ee_plus_chs_spec_premium: "",
      vis_ee_plus_chs_spec_premium: "",
      med_ee_fam_spec_premium: "",
      den_ee_fam_spec_premium: "",
      vis_ee_fam_spec_premium: "",
      specific_deductible: "",
      aggregate_deductible: "",
      surcharge_percentage: "",
      employer_waiting_period: ""
    }
  }
}
export interface BasicDetailsContactAttributes {
  contact: string;
  email: string;
  phone_number: string;
  fax: string;
  id?:string;
}

// Define the BasicDetailsAgentAttributes interface
export interface BasicDetailsAgentAttributes {
  agent_name: string;
  agent_rate: string;
  id?:string;
}

// Define the BasicDetailsAddressAttributes interface
export interface BasicDetailsAddressAttributes {
  address_line_1: string;
  address_line_2: string;
  city: string;
  zip_code: string;
  country_id?: string;
  country?: string;
  state_id?: string;
  state?: string;
  id?:string;
}

// Define the BasicDetailsEmployerOtherDetailAttributes interface
export interface BasicDetailsEmployerOtherDetailAttributes {
  id?:string;
  cost_containment_fee: string;
  employer_admin_fee: string;
  employer_ppo_fee: string;
  employer_cobra_fee: string;
  employer_hipaa_fee: string;
  employer_agg_premium_fee: string;
  employer_fsa_fee: string;
  employer_hra_fee: string;
  employer_hsa_fee: string;
  employer_fee1: string;
  employer_fee2: string;
  employer_fee3: string;
  employer_fee4: string;
  med_ee_spec_premium: string;
  den_ee_spec_premium: string;
  vis_ee_spec_premium: string;
  med_ee_plus_sp_spec_premium: string;
  den_ee_plus_sp_spec_premium: string;
  vis_ee_plus_sp_spec_premium: string;
  med_ee_plus_ch_spec_premium: string;
  den_ee_plus_ch_spec_premium: string;
  vis_ee_plus_ch_spec_premium: string;
  med_ee_plus_chs_spec_premium: string;
  den_ee_plus_chs_spec_premium: string;
  vis_ee_plus_chs_spec_premium: string;
  med_ee_fam_spec_premium: string;
  den_ee_fam_spec_premium: string;
  vis_ee_fam_spec_premium: string;
  specific_deductible: string;
  aggregate_deductible: string;
  surcharge_percentage: string;
  employer_waiting_period: string;
}

// Define the BasicDetailsEmployerData interface
export interface BasicDetailsEmployerData {
  employer_name: string;
  client_name: string;
  emp_id: string;
  effective_date: string;
  termination_date: string;
  is_active: boolean;
  payroll_day: string;
  payroll_month: string;
  payroll_cycle1: string;
  payroll_cycle2: string;
  payroll_cycle3: string;
  payroll_cycle4: string;
  tax_id: string;
  contacts_attributes: BasicDetailsContactAttributes[];
  agents_attributes: BasicDetailsAgentAttributes[];
  physical_address_attributes: BasicDetailsAddressAttributes;
  mailing_address_attributes: BasicDetailsAddressAttributes;
  employer_other_detail_attributes: BasicDetailsEmployerOtherDetailAttributes;
}

// Define the BasicDetailsJSONData interface
export interface BasicDetailsJSONData {
  employer: BasicDetailsEmployerData;
}

export interface EmployerListingDetail {
  attributes: {
    contact: {
      contact_name:string;
      contact_number:string;
    };
    effective_date: string;
    employer_name: string;
    id: number;
    is_active: boolean;
    physical_address: {
      address_line_1:string;
      city:string;
      state:string;
      zip:string;
    };
    termination_date: string;
  };
  id: string; // Assuming this should be a string
  type: string;
}

export interface DivisionApiResponse {
  data: {
    attributes: {
      division_id: string;
      division_name: string;
    };
    id: string;
  }[];
}

export interface GroupApiResponse {
  data: {
    attributes: {
      group_id: string;
      group_name: string;
    };
    id: string;
  }[];
}