

export interface ClientBasicDetailsModel {
  client_name: string;
  termination_date: Date | string;
  effective_date: Date | string;
  tax_id: string;
  contact_number:string;
  email:string;
  client_id:string;
  first_name:string;
  last_name:string;
}

export interface ClientAddressModel {
  physical_address_line1: string;
  physical_address_line2: string;
  physical_city: string;
  physical_zip_code: string;
  physical_state_id: string;
  physical_country_id: string;
  mailing_address_line1: string;
  mailing_address_line2: string;
  mailing_city: string;
  mailing_zip_code: string;
  mailing_state_id: string;
  mailing_country_id: string;
  client_contact1: string;
  client_email1: string;
  client_phone1: string;
  client_fax1: string;
  client_contact2: string;
  client_email2: string;
  client_phone2: string;
  client_fax2: string;
}

export interface ClientModel {
  clientBasicDetails: ClientBasicDetailsModel;
  clientAddress:ClientAddressModel;
}

const initialClientBasicDetails : ClientBasicDetailsModel = {
  client_name: "",
  termination_date: "",
  effective_date: "",
  tax_id: "",
  contact_number:'',
  email:'',
  client_id:'',
  first_name:'',
  last_name:''
}

export const initialClientAddress : ClientAddressModel = {
  physical_address_line1: "",
  physical_address_line2: "",
  physical_city: "",
  physical_zip_code: "",
  physical_state_id: "",
  physical_country_id: "",
  mailing_address_line1: "",
  mailing_address_line2: "",
  mailing_city: "",
  mailing_zip_code: "",
  mailing_state_id: "",
  mailing_country_id: "",
  client_contact1: "",
  client_email1: "",
  client_phone1:'',
  client_fax1: '',
  client_contact2: "",
  client_email2: "",
  client_phone2: '',
  client_fax2: '',
}

export const initialClientInfo : ClientModel = {
  clientBasicDetails: initialClientBasicDetails,
  clientAddress: initialClientAddress
}