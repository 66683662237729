import React from "react";
import ClientEmpGroupsLandingPageController, {
  Props
} from "./ClientEmpGroupsLandingPageController";
import { Button, Grid, Menu, MenuItem } from "@material-ui/core";
import GenericSearch from "../../../../components/src/GenericSearch.web";
import { searchIcon, filterIcon, deleteIcon, blackEditIcon, filterIconSelected } from "../assets";
import CustomerTable from "../../../../components/src/CustomTable.web";
import AddEditClientEmployerGroupDialog from "./AddEditClientEmployerGroupDialog.web";
import "./ClientEmpGroups.css";
import GenericSuccessDialog from "../../../../components/src/GenericSuccessDialog.web";
import CustomClientTabPanel from "../../../../components/src/CustomClientTabPanel.web";
import CustomFilterPopup from "../../../../components/src/CustomFilterPopup.web";

export default class ClientEmpGroupsLandingPage extends ClientEmpGroupsLandingPageController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <div
        className="client-emp-groups-wrapper"
        data-test-id="client-emp-groups-wrapper"
      >
        <div className="client-emp-groups-header">
          Employer: Samantha Gilbert {" > "} Builder AI Group
        </div>
        <CustomClientTabPanel
          tabList={this.groupTabMenuList}
          isDisplayAllTab
          allTabLabel="Groups"
        >
          <>
            <Grid container className="client-emp-groups-container">
              <Grid item xs={12} className="search-bar-and-add-btn">
                <GenericSearch
                  searchIcon={searchIcon}
                  placeholder="Search for Group"
                  value={this.state.groupSearchText}
                  onChange={this.onSearchGroupChange}
                  data-test-id="group-search-input"
                />
                <div className="add-grp-btn-div">
                  <div className="filter-grp-btn-container">
                    <Button
                      data-test-id="filter-btn"
                      className="filter-icon"
                      onClick={this.toggleGroupFilter}
                    >
                      <img
                        src={
                          this.state.openGroupFilter
                            ? filterIconSelected
                            : filterIcon
                        }
                        height={48}
                        alt="Filter Icon"
                      />
                    </Button>
                    {this.state.openGroupFilter && (
                      <CustomFilterPopup
                        searchIcon={searchIcon}
                        filterChildList={this.state.groupFilterChildList}
                        onChange={this.handleGroupFilterOrSearchOnChange}
                      />
                    )}
                  </div>
                  <Button
                    className="add-grp-btn"
                    data-test-id="add-group-btn"
                    onClick={() => this.toggleEditGroupDialog(true, false)}
                  >
                    + Add Group
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} className="hr mt-30" />
              <Grid item xs={12} className="grp-table-container">
                <CustomerTable
                  columns={this.groupListColumns}
                  rowData={this.state.groupRowItems}
                  onActionClick={this.handleActionClick}
                />
              </Grid>
            </Grid>
            <Menu
              id="grp-table-menu"
              anchorEl={this.state.groupTableMenuAnchorEl}
              keepMounted
              open={Boolean(this.state.groupTableMenuAnchorEl)}
              className="grp-table-menu"
              onClose={this.handleGroupsCloseTableMenu}
            >
              <MenuItem
                className="grp-table-menu-item"
                data-test-id="edit-group-menu"
                onClick={() => this.handleGroupsTableMenuClick("1")}
              >
                <span className="menu-item-img">
                  <img height={12} src={blackEditIcon} />
                </span>
                Edit
              </MenuItem>
              <MenuItem
                className="grp-table-menu-item delete-action-item"
                onClick={() => this.handleGroupsTableMenuClick("2")}
                data-test-id="del-group-menu"
              >
                <span className="menu-item-img">
                  <img height={12} src={deleteIcon} />
                </span>
                Delete
              </MenuItem>
            </Menu>
            {this.state.isOpenEditGroupsDialog && (
              <AddEditClientEmployerGroupDialog
                isOpenEditGroupDialog={this.state.isOpenEditGroupsDialog}
                isEdit={this.state.isEditGroup}
                handleClose={this.handleGroupDialogClose}
                groupDetails={this.state.groupDetails}
                data-test-id="edit-group-dialog"
                selectedEmployer={this.props.selectedEmployer}
              />
            )}
            <GenericSuccessDialog
              data-test-id="del-success-dialog"
              open={this.state.showDialogSuccess}
              handleDone={this.handleSuccessDialogClose}
              contains={"Delete Group Successfully"}
            />
          </>
          {this.groupTabMenuList.map(grpTab => (
            <div key={grpTab + " group-tab"}>{grpTab}</div>
          ))}

        </CustomClientTabPanel>
      </div>
    );
  }
}
