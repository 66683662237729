import { IAgentModel } from "../../../../components/src/GenericAddAgentComponent.web";
import { initialAgentModelData } from "../ClientEmployerDivision/ClientEmployerDivisionModel";

export interface IClassBasicDetailModel {
  class_name: string;
  class_id: string;
  client_name: string;
  employer_name: string;
  employer_id: string;
  group_id: string;
  division_id: string;
  location_id: string;
  class_effective_date: string;
  class_termination_date: string;
  deductible_date: Date | string;
  payroll_day: string;
  payroll_month: string;
  first_payroll_cycle: string;
  second_payroll_cycle: string;
  third_payroll_cycle: string;
  fourth_payroll_cycle: string;
  tax_id: string;
}

export interface IClassAddressAndContactModel {
  physical_address_line1: string;
  physical_address_line2: string;
  physical_city: string;
  physical_zip_code: string;
  physical_state_id: string;
  physical_country_id: string;
  class_check_contact1: string;
  class_check_contact2: string;
  class_check_email1: string;
  class_check_email2: string;
  class_check_phone_number1: string;
  class_check_phone_number2: string;
  class_check_fax1: string;
  class_check_fax2: string;
  mailing_address_line1: string;
  mailing_address_line2: string;
  mailing_city: string;
  mailing_zip_code: string;
  mailing_state_id: string;
  mailing_country_id: string;
  class_check_address_line1: string;
  class_check_address_line2: string;
  class_check_city: string;
  class_check_zip_code: string;
  class_check_state_id: string;
  class_check_country_id: string;
}

export interface IClassOtherDetailModel {
  agents: IAgentModel[];
  cobra_fee: string;
  hipaa_fee: string;
  agg_premium_fee: string;
  fsa_fee: string;
  cost_containment_fee: string;
  admin_fee: string;
  ppo_fee: string;
  hra_fee: string;
  med_ee_plus_sp_spec_premium: string;
  den_ee_plus_sp_spec_premium: string;
  vis_ee_plus_sp_spec_premium: string;
  hsa_fee: string;
  fee1: string;
  fee2: string;
  fee3: string;
  fee4: string;
  aggregate_deductible: string;
  surcharge_percentage: string;
  den_ee_plus_chs_spec_premium: string;
  vis_ee_plus_chs_spec_premium: string;
  med_ee_spec_premium: string;
  med_ee_plus_ch_spec_premium: string;
  den_ee_plus_ch_spec_premium: string;
  den_ee_spec_premium: string;
  vis_ee_spec_premium: string;
  med_ee_fam_spec_premium: string;
  vis_ee_plus_ch_spec_premium: string;
  med_ee_plus_chs_spec_premium: string;
  den_ee_fam_spec_premium: string;
  vis_ee_fam_spec_premium: string;
  specific_deductible: string;
  is_active: boolean;
  den_flag: boolean;
  med_flag: boolean;
  std_flag: boolean;
  vis_flag: boolean;
  life_cover_flag: boolean;
  ltd_flag: boolean;
  vol_life_flag: boolean;
  add_flag: boolean;
  fsa_flag: boolean;
  rx_flag: boolean;
  hra_flag: boolean;
  cancer_flag: boolean;
  hsa_flag: boolean;
  medical_question_flag: boolean;
}

export interface IClassDetailModel {
  classBasicDetails: IClassBasicDetailModel;
  classAddressDetails:IClassAddressAndContactModel;
  classOtherDetails: IClassOtherDetailModel
}

export interface IDropdownModel {
  id: number;
  name: string;
}

export const initialClassBasicDetailModelData: IClassBasicDetailModel = {
  class_name: "",
  class_id: "",
  client_name: "",
  employer_name: "",
  employer_id: "",
  group_id: "",
  division_id:"",
  location_id: "",
  class_effective_date: "",
  class_termination_date: "",
  deductible_date: "",
  payroll_day: "",
  payroll_month: "",
  first_payroll_cycle: "",
  second_payroll_cycle: "",
  third_payroll_cycle: "",
  fourth_payroll_cycle: "",
  tax_id: ""
};

export const initialClassAddressAndContactData: IClassAddressAndContactModel = {
  physical_address_line1: "",
  physical_address_line2: "",
  physical_city: "",
  physical_zip_code: "",
  physical_state_id: "",
  physical_country_id: "",
  class_check_contact1: "",
  class_check_contact2: "",
  class_check_email1: "",
  class_check_email2: "",
  class_check_phone_number1: "",
  class_check_phone_number2: "",
  class_check_fax1: "",
  class_check_fax2: "",
  mailing_address_line1: "",
  mailing_address_line2: "",
  mailing_city: "",
  mailing_zip_code: "",
  mailing_state_id: "",
  mailing_country_id: "",
  class_check_address_line1: "",
  class_check_address_line2: "",
  class_check_city: "",
  class_check_zip_code: "",
  class_check_state_id: "",
  class_check_country_id:""
};

export const initialClassOtherDetailData: IClassOtherDetailModel = {
 agents:[initialAgentModelData],
 cobra_fee: '',
  hipaa_fee: '',
  agg_premium_fee: '',
  fsa_fee:'',
  cost_containment_fee: '',
  admin_fee:'',
  ppo_fee: '',
  hra_fee: '',
  med_ee_plus_sp_spec_premium: '',
  den_ee_plus_sp_spec_premium: '',
  vis_ee_plus_sp_spec_premium: '',
  hsa_fee: '',
  fee1:'',
  fee2: '',
  fee3: '',
  fee4: '',
  aggregate_deductible:'',
  surcharge_percentage:'',
  den_ee_plus_chs_spec_premium: '',
  vis_ee_plus_chs_spec_premium:'',
  med_ee_spec_premium: '',
  med_ee_plus_ch_spec_premium:'',
  den_ee_plus_ch_spec_premium: '',
  den_ee_spec_premium: '',
  vis_ee_spec_premium: '',
  med_ee_fam_spec_premium: '',
  vis_ee_plus_ch_spec_premium: '',
  med_ee_plus_chs_spec_premium: '',
  den_ee_fam_spec_premium: '',
  vis_ee_fam_spec_premium: '',
  specific_deductible: '',
  is_active: false,
  den_flag: false,
  med_flag: false,
  std_flag: false,
  vis_flag: false,
  life_cover_flag: false,
  ltd_flag: false,
  vol_life_flag: false,
  add_flag: false,
  fsa_flag: false,
  rx_flag: false,
  hra_flag: false,
  cancer_flag: false,
  hsa_flag: false,
  medical_question_flag: false

};

export const initialClassDetailModelData: IClassDetailModel = {
  classBasicDetails: initialClassBasicDetailModelData,
  classAddressDetails:initialClassAddressAndContactData,
  classOtherDetails:initialClassOtherDetailData
};
