export interface IPlanInfoModel {
  plan_name: string;
  client_name: string;
  employer_name: string;
  group_name: string;
  division_name: string;
  location_name: string;
  class_name: string;
  stop_loss_carrier: string;
  plan_description: string;
  plan_documents: File | string;
  enrollment_documents: File | string;
  is_enrollment_plan: boolean;
}

export interface IMedicalModal {
  effective_date: string;
  termination_date: string;

  ee_cost: string;
  ee_spouse_cost: string;
  ee_child_cost: string;
  ee_more_than_one_child_cost: string;
  ee_family_cost: string;
  er_cost: string;
  er_spouse_cost: string;
  er_child_cost: string;
  er_more_than_one_child_cost: string;
  er_family_cost: string;
  doller_costlimit:string;
  in_network_single_deductible: string;
  in_network_family_deductible: string;
  in_network_single_out_of_pocket: string;
  in_network_family_out_of_pocket: string;
  in_network_single_coinsurance: string;
  in_network_family_coinsurance: string;
  in_network_pcp_copay: string;
  in_network_specialist_copay: string;
  in_network_urgent_care_copay: string;
  in_network_er_visit_copay: string;
  in_network_emergecy_room_copay: string;
  in_network_ded_applies_to_oop: boolean;
  in_network_copay_applies_to_oop: boolean;

  out_network_single_deductible: string;
  out_network_family_deductible: string;
  out_network_single_out_of_pocket: string;
  out_network_family_out_of_pocket: string;
  out_network_single_coinsurance: string;
  out_network_family_coinsurance: string;
  out_network_pcp_copay: string;
  out_network_specialist_copay: string;
  out_network_urgent_care_copay: string;
  out_network_er_visit_copay: string;
  out_network_in_patient_copay: string;
  out_network_lab_copay: string;
  out_network_emergecy_room_copay: string;
  out_network_ded_applies_to_oop: boolean;
  out_network_copay_applies_to_oop: boolean;
  benefit_documents:File | string;
  enrollment_document:File | string;
}

export interface IPlanDetailModel {
  planData: { planInfo: IPlanInfoModel; medical: IMedicalModal };
  id?: string;
}

export const initialplanInfoModelData: IPlanInfoModel = {
  plan_name: "",
  client_name: "",
  employer_name: "",
  group_name: "",
  division_name: "",
  location_name: "",
  class_name: "",
  stop_loss_carrier: "",
  plan_description: "",
  plan_documents: "",
  enrollment_documents: "",
  is_enrollment_plan: false
};

export const initialMedicalModelData: IMedicalModal = {
  effective_date: "",
  termination_date: "",
  ee_cost: "",
  ee_spouse_cost: "",
  ee_child_cost: "",
  ee_more_than_one_child_cost: "",
  ee_family_cost: "",
  er_cost: "",
  er_spouse_cost: "",
  er_child_cost: "",
  er_more_than_one_child_cost: "",
  er_family_cost: "",
  doller_costlimit:'',
  in_network_single_deductible: "",
  in_network_family_deductible: "",
  in_network_single_out_of_pocket: "",
  in_network_family_out_of_pocket: "",
  in_network_single_coinsurance: "",
  in_network_family_coinsurance: "",
  in_network_pcp_copay: "",
  in_network_specialist_copay: "",
  in_network_urgent_care_copay: "",
  in_network_er_visit_copay: "",
  in_network_emergecy_room_copay: "",
  in_network_ded_applies_to_oop: false,
  in_network_copay_applies_to_oop: false,

  out_network_single_deductible: "",
  out_network_family_deductible: "",
  out_network_single_out_of_pocket: "",
  out_network_family_out_of_pocket: "",
  out_network_single_coinsurance: "",
  out_network_family_coinsurance: "",
  out_network_pcp_copay: "",
  out_network_specialist_copay: "",
  out_network_urgent_care_copay: "",
  out_network_er_visit_copay: "",
  out_network_in_patient_copay: "",
  out_network_lab_copay: "",
  out_network_emergecy_room_copay: "",
  out_network_ded_applies_to_oop: false,
  out_network_copay_applies_to_oop: false,
  benefit_documents:"",
  enrollment_document:""
};
