import React from "react";
import ClientEmployerClassController, { Props } from "./ClientEmployerClassController.web"
import { Grid, Button, Box, Typography, Divider, MenuItem, Menu } from "@material-ui/core";
import { filterIcon, searchIcon } from "../../../MemberDirectory2/src/assets";
import GenericSearch from "../../../../components/src/GenericSearch.web";
import { blackEditIcon, deleteIcon, filterIconSelected } from "../assets";
import "../../assets/Styles/ClientEmployerClass.css";
import CustomerTable from "../../../../components/src/CustomTable.web";
import CustomClientTabPanel from "../../../../components/src/CustomClientTabPanel.web";
import CustomFilterPopup from "../../../../components/src/CustomFilterPopup.web";
import AddEditClientEmployerClassDialog from "./AddEditClientEmployerClassDialog.web"

export default class ClientEmployerClass extends ClientEmployerClassController {
    constructor(props: Props) {
        super(props);
    }

    render() {

        return (
            <>
                {/* header */}
                <Grid className="client-employer-class-mainheader-postion" data-test-id="class-wrapper">
                    <Grid container alignItems="center">
                        <Grid
                            container
                            item
                            xs={12}
                            className="client-employer-landing-main"
                            alignItems="center"
                        >
                            <Grid item>
                                <Box component={"div"} className="name-tag">
                                    <Box component={"div"} style={{ marginLeft: 20 }}>
                                        <Typography className="desc">
                                            "You are viewing data across all your employers"
                                        </Typography>
                                        <Typography className="desc">All Employers</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* sub heading */}
                    <CustomClientTabPanel
                        tabList={this.tabMenuList}
                        isDisplayAllTab
                        allTabLabel={"Class"}
                    >
                        {/* class landing */}
                        <div>
                            <Grid
                                container
                                className="emp-documents-container emp-divisions-container"
                            >
                                <Grid item xs={12} className="search-bar-and-add-btn client-plan-container">
                                    <GenericSearch
                                        searchIcon={searchIcon}
                                        placeholder="Search for Class"
                                        value={this.state.searchValue}
                                        onChange={this.onSearchInputChange}
                                        data-test-id="class-search-input"
                                    />
                                    <div className="add-document-btn-div">
                                        <div className="filter-divisions-btn-container">
                                            <Button onClick={this.handleClassFilterClick} data-test-id="filter-btn">
                                                <img
                                                    src={
                                                        this.state.openFilter
                                                            ? filterIconSelected
                                                            : filterIcon
                                                    }
                                                    alt="Filter Icon"
                                                    className="filter-icon"
                                                />
                                            </Button>
                                            {this.state.openFilter && (
                                                <CustomFilterPopup
                                                    searchIcon={searchIcon}
                                                    filterChildList={this.state.filterChildList}
                                                    onChange={(state) => { }
                                                    }
                                                />
                                            )}
                                        </div>
                                        <Button
                                            className="add-document-btn add-class-btn"
                                            onClick={() => this.toggleEditClassDialog(true, false)}
                                            data-test-id="add-class-btn"
                                        >
                                            + Add Class
                                        </Button>
                                    </div>
                                    {this.state.isOpenEditClassDialog && (
                                        <AddEditClientEmployerClassDialog
                                            isOpenEditClassDialog={this.state.isOpenEditClassDialog}
                                            isEdit={this.state.isEditClass}
                                            handleClose={this.handleClassDialogClose}
                                            classDetails={this.state.classDetails}
                                            data-test-id="edit-class-dialog"
                                            selectedEmployer={this.props.selectedEmployer}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Divider className="dividerStyle" />
                            <Grid item xs={12}>
                                <CustomerTable
                                    columns={this.tableColumns}
                                    rowData={this.state.rowData}
                                    onActionClick={() => { }}
                                />
                                <Menu
                                    id="division-table-menu"
                                    anchorEl={this.state.tableMenuAnchorEl}
                                    keepMounted
                                    open={Boolean(this.state.tableMenuAnchorEl)}
                                    onClose={this.handleCloseTableMenu}
                                    className="division-table-menu"
                                >
                                    <MenuItem
                                        className="division-table-menu-item"
                                    // onClick={() => this.toggleEditDivisionDialog(true, true)}
                                    >
                                        <span className="menu-item-img">
                                            <img src={blackEditIcon} />
                                        </span>
                                        Edit
                                    </MenuItem>
                                    <MenuItem
                                        className="division-table-menu-item delete-action-item"
                                        onClick={this.handleCloseTableMenu}
                                    >
                                        <span className="menu-item-img">
                                            <img src={deleteIcon} />
                                        </span>
                                        Delete
                                    </MenuItem>
                                </Menu>
                            </Grid>
                        </div>

                        {/* class plan */}
                        {this.tabMenuList.map((tab) => (
                            <>{tab}</>
                        ))}
                    </CustomClientTabPanel>
                </Grid >

            </>
        )
    }
}