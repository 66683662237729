import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import {
  IPlanDetailModel,
  initialMedicalModelData,
  initialplanInfoModelData
} from "./ClientPlanModel";
import { SelectionList } from "../ClientMember/ClientMemberModel";

export const configJSON = require("../config");

export interface Props {
  clientDetails: { client_name: string, client_id: string, accountId: string };
  employerList: SelectionList[],
  token: string,
}

interface S {
  isAddPlanEmployerDialogOpen: boolean;
  planData: IPlanDetailModel["planData"];
}

interface SS {
  id: any;
}

export default class ClientEmployerPlanPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      isAddPlanEmployerDialogOpen: false,
      planData: {
        planInfo: initialplanInfoModelData,
        medical: initialMedicalModelData
      }
    };
  }

  async componentDidMount() {
    this.setState({
      planData: {
        ...this.state.planData,
        planInfo: {
          ...this.state.planData.planInfo,
          client_name: this.props.clientDetails.client_name
        }
      }
    });
  }

  toggleAddPlanEmployerDialog = (open: boolean) => {
    this.setState({ isAddPlanEmployerDialogOpen: open });
  };

  handleSaveAndFinish = (planData: IPlanDetailModel["planData"]) => {
    this.setState({ planData: planData });
  };
}
